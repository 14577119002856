import { Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useUserStore } from '../store'

export interface IntolerancesAskCardProps {}

export function IntolerancesAskCard({}: IntolerancesAskCardProps) {
  const { tw } = useTw()
  const { t } = useTranslation()
  const { setIntolerancesAsked } = useUserStore()
  const navigator = useNavigation()

  return (
    <View style={tw`rounded-lg fillCard flex-row items-center p-md mt-sm`}>
      <View style={tw`flex-col flex-1`}>
        <Text style={tw`textMono title4`}>{t('l.doYouHaveFoodIntolerances')}</Text>
      </View>
      <View style={tw`ml-xs flex-row`}>
        <Button label={t('l.no')} variant={ButtonVariant.Accent} onPress={() => setIntolerancesAsked(true)} />
        <View style={tw`w-xs`} />
        <Button
          label={t('l.yes')}
          variant={ButtonVariant.Accent}
          onPress={() => navigator.navigate('SetIntolerancesScreen')}
        />
      </View>
    </View>
  )
}
