import { Icon, IconColor, IconSize, useTw } from '@mea-menu/components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FlatList, View } from 'react-native'
import { CategoryScrollerItem, Clickable } from '../components'
import { useDelayer } from '../utils/commonHelpers'
import { CategoryModal } from './CategoryModal'

export interface CategoryScrollerProps {
  categoryKeys: string[]
  onCategoryPress: (categoryKey: string, showAnimation: boolean) => void
  currentCategoryKey?: string
  hideHamburgerMenu?: boolean
}

export const CategoryScroller: React.FC<CategoryScrollerProps> = ({
  categoryKeys,
  onCategoryPress,
  currentCategoryKey,
  hideHamburgerMenu = false,
}) => {
  const { tw } = useTw()
  const categoriesScrollerRef = useRef<FlatList>(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [selectedCategoryKey, setSelectedCategoryKey] = useState(currentCategoryKey)
  const { addItemWithDelay } = useDelayer<string>(500, categoryKey => scrollToCategory(categoryKey, true))

  const scrollToCategory = useCallback(
    (categoryKey: string, animated?: boolean) => {
      setSelectedCategoryKey(categoryKey)
      const categoryIndex = categoryKeys.indexOf(categoryKey)
      const indexToScrollTo = Math.max(0, categoryIndex === 1 && categoryKeys[0] === 'forYou' ? 0 : categoryIndex)
      try {
        categoriesScrollerRef.current?.scrollToIndex({
          index: indexToScrollTo,
          animated: animated !== undefined ? animated : true,
        })
      } catch (e) {
        console.log('Scroll failed due to changed item list')
      }
    },
    [categoryKeys]
  )

  useEffect(() => {
    if (!currentCategoryKey) return
    addItemWithDelay(currentCategoryKey)
  }, [currentCategoryKey])

  return (
    <View style={tw`mt-sm px-md`}>
      <View style={tw`flex-row items-center`}>
        <FlatList
          ref={categoriesScrollerRef}
          style={tw`rounded-md mr-xs flex-6`}
          horizontal
          onScrollToIndexFailed={({ index }) => {
            console.error('CategoryScroller scrollToIndex failed for index ', index)
          }}
          data={categoryKeys}
          extraData={selectedCategoryKey}
          keyExtractor={item => item}
          ItemSeparatorComponent={() => <View style={tw`w-[6px]`} />}
          showsHorizontalScrollIndicator={false}
          renderItem={({ item }) => (
            <CategoryScrollerItem
              isSelected={item === selectedCategoryKey}
              categoryKey={item}
              onPress={() => {
                onCategoryPress(item, true)
                setSelectedCategoryKey(item)
              }}
            />
          )}
        />
        {!hideHamburgerMenu && (
          <Clickable
            id="goToCategoryModal"
            style={tw`h-[50px] ${
              isModalVisible ? 'bg-primary/15 strokePrimary' : 'fillCard strokeMono'
            } border-[1px] rounded-md py-xs flex-1 justify-center items-center flex-wrap`}
            onPress={() => setIsModalVisible(true)}
          >
            <Icon name="HamburgerMenu" size={IconSize.Medium} color={IconColor.mono} />
          </Clickable>
        )}
      </View>
      <CategoryModal
        selectedCategoryKey={selectedCategoryKey}
        categoryKeys={categoryKeys}
        onCategoryPress={categoryKey => {
          setIsModalVisible(false)
          onCategoryPress(categoryKey, false)
          setSelectedCategoryKey(categoryKey)
          scrollToCategory(categoryKey, false)
        }}
        onClose={() => setIsModalVisible(false)}
        visible={isModalVisible}
      />
    </View>
  )
}
