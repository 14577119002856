import { Button, ButtonSize, ButtonVariant, Card, Icon, IconColor, IconSize, useTw } from '@mea-menu/components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Easing, Image, Platform, ScrollView, Text, View } from 'react-native'
import { OrderService } from '../api/user/OrderService'
import { ActionType, IntoleranceActionPayloadAction, PublicService } from '../api/user/PublicService'
import { UserService } from '../api/user/UserService'
import { BaseModal, Clickable, IntolerancesListFragment, LoadingIndicator, Screen } from '../components'
import { AkinatorFragment } from '../components/fragments/AkinatorFragment'
import { RootStackScreenProps } from '../navigation'
import {
  useAppConfigStore,
  useCartStore,
  useMenuStore,
  useRestaurantStore,
  useSpecialDishesStore,
  useUserStore,
} from '../store'
import { ColorsType } from '../theme/palette'
import { SCREEN_AVAILABLE_HEIGHT, SCREEN_AVAILABLE_WIDTH } from '../theme/sizes'
import { AppFeature, DishOrderStatus, Menu } from '../types'
import { translateLocal } from '../utils'
import { emptyCart } from '../utils/cartHelpers'
import { cartIsExpired, fetchAndSetNewSessionData, fetchAndSetSpecialDishesData } from '../utils/commonHelpers'

export function LandingScreen({ navigation }: RootStackScreenProps<'LandingScreen'>) {
  const restaurant = useRestaurantStore()
  const menuStore = useMenuStore()
  const { appMode, addAppFeature } = useAppConfigStore()
  const {
    menuId: orderingMenuId,
    orderId,
    lastUpdateSeconds: cartLastUpdateSeconds,
    emptyOrdering,
    getCartDishesIdsInStatus,
    initCart,
    setOrderId,
    resumeCartState,
  } = useCartStore()
  const { setForYouRecipes, setFeaturedDishes, setTrendingRecipes } = useSpecialDishesStore()
  const { allergens: userAllergies, setIntolerances, intolerancesAsked, setIntolerancesAsked } = useUserStore()
  const [intolerancesSet, setIntolerancesSet] = useState<boolean>(intolerancesAsked)
  const { t, i18n } = useTranslation()
  const [useAkinatorModalMenu, setUseAkinatorModalMenu] = useState<Menu>()
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedAkinatorMenuId, setSelectedAkinatorMenuId] = useState<string>()
  const [selectedMenuIdWithAnotherPendingOrder, setSelectedMenuIdWithAnotherPendingOrder] = useState<string>()
  const [pageIndex, setPageIndex] = useState(0)
  const [akinatorIteraction, setAkinatorIteraction] = useState(0)
  const { tw } = useTw()

  const showIntoleranceAskView = useMemo<boolean>(() => appMode === 'USER' && !intolerancesAsked, [])
  const showAkinatorMenuItem =
    appMode === 'USER' && restaurant.menus.length === 1 && restaurant.menus[0].akinatorEnabled

  const menus = appMode !== 'PRICE_LIST' ? restaurant.menus : restaurant.menus.filter(menu => menu.showInPriceList)

  const fadeAnim = useRef(new Animated.Value(0)).current
  const pagesSlideAnim = useRef(
    new Animated.Value(SCREEN_AVAILABLE_WIDTH * (1.5 + (showIntoleranceAskView ? 0.5 : 0)))
  ).current

  const handleSessionDataFetch = async (menu: Partial<Menu>, fetchAndSetSpecialDishes: boolean) => {
    if (!menu._id) return
    await fetchAndSetNewSessionData(
      menu,
      menuStore,
      async () => {
        if (fetchAndSetSpecialDishes)
          await fetchAndSetSpecialDishesData(
            menu._id!,
            userAllergies,
            setForYouRecipes,
            setFeaturedDishes,
            setTrendingRecipes
          )
        if (appMode === 'USER' && menu.userCanOrder) {
          const lastUserOrder = await OrderService.getCurrentByMenuId(restaurant._id!, menu._id!)
          initCart(menu._id)
          setOrderId(lastUserOrder._id)
          resumeCartState(lastUserOrder.dishes)
          addAppFeature(AppFeature.ORDER_WITH_WAITER)
        }
      },
      () => setLoading(false)
    )
  }

  const selectMenu = async (menu: Partial<Menu>) => {
    setLoading(true)
    await handleSessionDataFetch(menu, appMode === 'USER' && (showAkinatorMenuItem! || !menu.akinatorEnabled))
    if (appMode === 'USER' && !showAkinatorMenuItem && menu.akinatorEnabled) {
      setUseAkinatorModalMenu(menu as Menu)
    } else {
      goToMenuScreen()
    }
  }

  const goToMenuScreen = async () => {
    navigation.navigate('MenuScreen')
    setTimeout(() => {
      setPageIndex(0)
      setAkinatorIteraction(iter => iter + 1)
    }, 600)
  }

  const activelyOrderingMenuId =
    orderingMenuId &&
    orderId &&
    getCartDishesIdsInStatus(DishOrderStatus.ORDERING).length > 0 &&
    !cartIsExpired(cartLastUpdateSeconds)
      ? orderingMenuId
      : undefined

  const MenuSelectionView = useCallback(() => {
    const MenuItem = ({
      item,
      name,
      description,
      onClick,
      color,
      variant,
    }: {
      item: Partial<Menu>
      name: string
      description: string
      onClick: () => void
      variant: ButtonVariant
      color?: ColorsType
    }) => (
      <Card onPress={onClick} style={tw`mx-md mb-md`}>
        {(showAkinatorMenuItem || description?.length > 0) && (
          <Text style={tw`pb-md pt-sm label textMono text-center`}>
            {description?.length > 0 ? description : t('l.openNormalMenu')}
          </Text>
        )}
        <Button
          onPress={onClick}
          variant={variant}
          label={name}
          size={ButtonSize.Small}
          icon={activelyOrderingMenuId === item._id ? 'Cloche' : undefined}
        />
      </Card>
    )

    const Separator = () => <View style={tw`h-[1px] w-[80%] bg-grey self-center`} />

    return (
      <View style={{ width: SCREEN_AVAILABLE_WIDTH }}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Image
            source={{ uri: `${restaurant.cover?.url}?height=100` }}
            resizeMode={'cover'}
            style={tw`h-[100px] w-[100px] self-center rounded-xl`}
          />
          <Text style={tw`pt-lg mb-xs text-center textMono title`}>{restaurant.name}</Text>
          <Text style={tw`px-md pt-sm label textMono`}>{translateLocal(restaurant, 'description', i18n.language)}</Text>
          <View style={tw`mt-xl`}>
            {showAkinatorMenuItem && (
              <Card style={tw`mx-md mb-md`}>
                <Text style={tw`pb-md pt-sm label textMono text-center`}>{t('l.customMenuDescription')}</Text>
                <Button
                  onPress={async () => {
                    if (intolerancesAsked) setIntolerancesSet(true)
                    setLoading(true)
                    setSelectedAkinatorMenuId(restaurant.menus[0]._id)
                    await handleSessionDataFetch(restaurant.menus[0], appMode === 'USER' && intolerancesAsked)
                    setPageIndex(page => page + 1)
                  }}
                  variant={ButtonVariant.Primary}
                  label={t('l.customMenu')}
                  size={ButtonSize.Small}
                />
              </Card>
            )}
            {menus.length === 0 ? (
              <Text style={tw`textMono title2 mt-xl ml-sm self-center`}>{t('l.noMenuAvailable')}</Text>
            ) : (
              menus.map(item => {
                const button = (
                  <Button
                    onPress={async () => {
                      if (activelyOrderingMenuId !== undefined && activelyOrderingMenuId !== item._id) {
                        setSelectedMenuIdWithAnotherPendingOrder(item._id)
                      } else {
                        selectMenu(item)
                      }
                    }}
                    variant={ButtonVariant.Secondary}
                    label={translateLocal(item, 'name', i18n.language)}
                    size={ButtonSize.Small}
                  />
                )

                if (item.descriptionIt)
                  return (
                    <Card key={item._id} style={tw`mx-md mb-md`}>
                      <Text style={tw`pb-md pt-sm label textMono text-center`}>
                        {translateLocal(item, 'description', i18n.language)}
                      </Text>
                      {button}
                    </Card>
                  )
                return (
                  <View key={item._id} style={tw`m-md`}>
                    {button}
                  </View>
                )
              })
            )}

            {!!restaurant.coverPrice && (
              <Text style={tw`textMono label p-lg`}>
                {t('l.coverPrice') + ': ' + restaurant.coverPrice.toFixed(2) + '€'}
              </Text>
            )}
          </View>
        </ScrollView>
        <View style={tw`h-lg`} />
        <View style={tw`absolute bottom-0 w-full flex-row self-center items-center justify-center fillBackground`}>
          <Icon name={'MeaMenu'} />
          <Text style={tw`ml-xs textMono caption2`}>{t('l.poweredBy1')}</Text>
          <Text style={tw`ml-2 textMono caption2`}>Mea Menu</Text>
          <Text style={tw`ml-xs textMono caption2`}>{t('l.poweredBy2')}</Text>
        </View>
      </View>
    )
  }, [restaurant, showAkinatorMenuItem, appMode, intolerancesAsked, activelyOrderingMenuId, tw])

  const IntoleranceAskView = () => (
    <View style={tw`mt-[50%] p-sm`}>
      <Text style={tw`title textMono p-sm mt-md text-center`}>{t('l.doYouHaveFoodIntolerances')}</Text>
      <View style={tw`flex-row`}>
        <View style={{ flex: 1 }}>
          <Button
            label={t('l.no')}
            onPress={async () => {
              await UserService.updateIntolerances(0)
              setIntolerances(0)
              setIntolerancesSet(true)
              await fetchAndSetSpecialDishesData(
                selectedAkinatorMenuId!,
                0,
                setForYouRecipes,
                setFeaturedDishes,
                setTrendingRecipes
              )
              setPageIndex(page => page + 2)
              PublicService.logAction(ActionType.Intolerance, {
                action: IntoleranceActionPayloadAction.NoIntolerances,
              })
            }}
            style={tw`mr-xs`}
          />
        </View>
        <View style={{ flex: 1 }}>
          <Button
            label={t('l.yes')}
            variant={ButtonVariant.Primary}
            style={tw`ml-xs`}
            onPress={() => setPageIndex(page => page + 1)}
          />
        </View>
      </View>
    </View>
  )

  const UseAkinatorModal = () => (
    <BaseModal
      title={t('l.customMenuModalTitle')}
      visible={!!useAkinatorModalMenu}
      onClose={() => setUseAkinatorModalMenu(undefined)}
    >
      <View>
        <Text style={tw`mt-md textMono caption`}>{t('l.customMenuModalCaption')}</Text>
        <Button
          label={t('l.customMenuModalGoAkinator')}
          variant={ButtonVariant.Primary}
          style={tw`mt-md`}
          onPress={async () => {
            if (useAkinatorModalMenu) {
              if (intolerancesAsked) setIntolerancesSet(true)
              setSelectedAkinatorMenuId(useAkinatorModalMenu?._id)
              await fetchAndSetSpecialDishesData(
                useAkinatorModalMenu._id,
                userAllergies,
                setForYouRecipes,
                setFeaturedDishes,
                setTrendingRecipes
              )
              setUseAkinatorModalMenu(undefined)
              setPageIndex(page => page + 1)
            }
          }}
        />

        <Button
          label={t('l.customMenuModalGoClassic')}
          style={tw`mt-md`}
          onPress={async () => {
            if (useAkinatorModalMenu) {
              setUseAkinatorModalMenu(undefined)
              await fetchAndSetSpecialDishesData(
                useAkinatorModalMenu._id,
                userAllergies,
                setForYouRecipes,
                setFeaturedDishes,
                setTrendingRecipes
              )
              goToMenuScreen()
            }
          }}
        />
      </View>
    </BaseModal>
  )

  const OtherMenuOrderPendingModal = () => (
    <BaseModal
      title={t('l.menu')}
      visible={!!selectedMenuIdWithAnotherPendingOrder}
      onClose={() => setSelectedMenuIdWithAnotherPendingOrder(undefined)}
    >
      <View>
        <Text style={tw`mt-md textMono caption`}>
          {t('l.otherMenuOrderPendingDialogMsg', {
            menuName: translateLocal(
              restaurant.menus.find(menu => menu._id === orderingMenuId),
              'name',
              i18n.language
            ),
          })}
        </Text>
        <Button
          label={t('l.yes')}
          style={tw`mt-md`}
          onPress={async () => {
            if (!orderingMenuId || !orderId) return
            const newlySelectedMenu = restaurant.menus.find(menu => menu._id === selectedMenuIdWithAnotherPendingOrder)
            if (!newlySelectedMenu) return
            emptyCart(orderId, emptyOrdering, setLoading)
            selectMenu(newlySelectedMenu)
            setSelectedMenuIdWithAnotherPendingOrder(undefined)
          }}
        />
        <Button
          label={t('l.no')}
          variant={ButtonVariant.Primary}
          style={tw`mt-md`}
          onPress={async () => setSelectedMenuIdWithAnotherPendingOrder(undefined)}
        />
      </View>
    </BaseModal>
  )

  useEffect(() => {
    Animated.timing(fadeAnim, {
      toValue: 1,
      duration: 600,
      useNativeDriver: Platform.OS !== 'web',
    }).start()
  }, [])

  useEffect(() => {
    Animated.timing(pagesSlideAnim, {
      toValue: -(
        pageIndex * SCREEN_AVAILABLE_WIDTH -
        SCREEN_AVAILABLE_WIDTH * (1 + (showIntoleranceAskView ? 0.5 : -0.5))
      ),
      duration: 500,
      easing: Easing.bezier(0.4, 0, 0.2, 1),
      useNativeDriver: Platform.OS !== 'web',
    }).start()
  }, [pageIndex])

  return (
    <Screen
      noRestaurantName
      topBarChildren={
        pageIndex > 0 ? (
          <View style={tw`grow items-start`}>
            <Clickable
              style={tw`flex-1`}
              onPress={() => {
                setPageIndex(page => page - 1)
                if (pageIndex === (showIntoleranceAskView ? 3 : 1)) {
                  setTimeout(() => {
                    setIntolerancesSet(false)
                  }, 500)
                }
              }}
            >
              <View style={tw`self-start flex-row items-center`}>
                <Icon name="Back" size={IconSize.Medium} color={IconColor.mono} />
                <Text style={tw`ml-xs title3 textMono`}>{t('l.back')}</Text>
              </View>
            </Clickable>
          </View>
        ) : null
      }
    >
      <LoadingIndicator visible={loading} />
      <Animated.View
        style={[
          { opacity: fadeAnim },
          { width: SCREEN_AVAILABLE_WIDTH, height: SCREEN_AVAILABLE_HEIGHT },
          tw`flex flex-row justify-center overflow-hidden`,
        ]}
      >
        <Animated.View style={[tw`flex flex-row`, { transform: [{ translateX: pagesSlideAnim }] }]}>
          <MenuSelectionView />
          {showIntoleranceAskView && (
            <>
              <View style={{ width: SCREEN_AVAILABLE_WIDTH }}>
                <IntoleranceAskView />
              </View>
              <View style={{ height: SCREEN_AVAILABLE_HEIGHT, width: SCREEN_AVAILABLE_WIDTH }}>
                <IntolerancesListFragment
                  key={userAllergies}
                  hideUnsuitableToggle
                  onIntoleranceSetSuccess={async _ => {
                    setIntolerancesSet(true)
                    setPageIndex(page => page + 1)
                  }}
                  onCancel={() => {
                    setPageIndex(page => page - 1)
                  }}
                />
              </View>
            </>
          )}
          <View style={[tw`px-lg`, { width: SCREEN_AVAILABLE_WIDTH }]}>
            {selectedAkinatorMenuId && intolerancesSet && (
              <AkinatorFragment
                key={akinatorIteraction}
                menuId={selectedAkinatorMenuId}
                onResultsVisibleChildren={
                  <View style={tw`flex-row mt-xl`}>
                    <Button
                      label={t('l.restart')}
                      variant={ButtonVariant.Secondary}
                      style={tw`flex-1`}
                      onPress={() => setAkinatorIteraction(iter => iter + 1)}
                    />
                    <Button
                      label={t('l.goToMenu')}
                      style={tw`ml-sm flex-1`}
                      variant={ButtonVariant.Primary}
                      onPress={() => {
                        setIntolerancesAsked(true)
                        goToMenuScreen()
                      }}
                    />
                  </View>
                }
              />
            )}
          </View>
        </Animated.View>
      </Animated.View>
      <UseAkinatorModal />
      <OtherMenuOrderPendingModal />
    </Screen>
  )
}
