import { t } from 'i18next'
import { AkinatorSessionStep, MeaError } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export class AkinatorService extends BaseUserService() {
  static entityPath: string = 'akinator'

  static async init(menuId: string): Promise<AkinatorSessionStep> {
    const response = await getBackendClient().post<AkinatorSessionStep>(`${this.getPath()}/init/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('initAkinatorError', t('errors.initAkinatorError'))
    }
    return response.data
  }

  static async answer(sessionId: string, answerIndex: number): Promise<AkinatorSessionStep> {
    const response = await getBackendClient().post<AkinatorSessionStep>(`${this.getPath()}/answer/${sessionId}`, {
      answerIndex,
    })
    if (noResponse(response)) {
      throw new MeaError('akinatorAnswerError', t('errors.akinatorAnswerError'))
    }
    return response.data
  }
}
