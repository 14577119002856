import { useTw } from '@mea-menu/components'
import { DefaultTheme, LinkingOptions, NavigationContainer } from '@react-navigation/native'
import { createStackNavigator } from '@react-navigation/stack'
import { useMemo } from 'react'
import { View } from 'react-native'
import { DishDetailScreen } from '../screens/DishDetailScreen'
import { LandingScreen } from '../screens/LandingScreen'
import { MenuScreen } from '../screens/MenuScreen'
import { OrderScreen } from '../screens/OrderScreen'
import { RecapScreen } from '../screens/RecapScreen'
import { SetIntolerancesScreen } from '../screens/SetIntolerancesScreen'
import { useAppConfigStore, useMenuStore } from '../store'
import { RootStackParamList } from './SCREENS'

const Stack = createStackNavigator<RootStackParamList>()

export const AppNavigator = () => {
  const menu = useMenuStore()
  const { appFeatures } = useAppConfigStore()
  const { tw } = useTw()

  const initialScreen: keyof RootStackParamList = useMemo(() => {
    return 'LandingScreen'
  }, [appFeatures, menu])

  const linking: LinkingOptions<ReactNavigation.RootParamList> = {
    config: {
      screens: {
        LandingScreen: 'landing',
        DishDetailScreen: 'detail/:dishDetailId/:filter?',
        MenuScreen: 'menu',
        OrderScreen: 'order',
        RecapScreen: 'recap',
        SetIntolerancesScreen: 'setIntolerances',
      },
    },
    prefixes: [],
  }

  return (
    <View style={tw`absolute bottom-0 w-full h-full fillBackground`}>
      <NavigationContainer
        documentTitle={{
          formatter: (options, route) => `Mea Menu`,
        }}
        linking={linking}
        theme={{
          ...DefaultTheme,
          colors: {
            ...DefaultTheme.colors,
            background: 'transparent',
          },
        }}
      >
        <Stack.Navigator
          detachInactiveScreens={true}
          initialRouteName={initialScreen}
          screenOptions={{
            headerShown: false,
            gestureEnabled: false,
          }}
        >
          <Stack.Screen name="LandingScreen" component={LandingScreen} />
          <Stack.Screen name="DishDetailScreen" component={DishDetailScreen} />
          <Stack.Screen name="MenuScreen" component={MenuScreen} />
          <Stack.Screen name="OrderScreen" component={OrderScreen} />
          <Stack.Screen name="RecapScreen" component={RecapScreen} />
          <Stack.Screen name="SetIntolerancesScreen" component={SetIntolerancesScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  )
}
