import { ButtonVariant, Card, CardVariant, Icon, IconButton, IconColor, IconSize, useTw } from '@mea-menu/components'
import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ScrollView, Text, TouchableOpacity, View } from 'react-native'
import Toast from 'react-native-root-toast'
import { BaseModal, Clickable } from '../'
import { useDish } from '../../hooks/useDishHook'
import { useAppConfigStore, useMenuStore } from '../../store'
import { SCREEN_AVAILABLE_HEIGHT, SCREEN_AVAILABLE_WIDTH } from '../../theme/sizes'
import { AppFeature, Dish } from '../../types'
import { appFeatureIsAvailable, showToast, translateLocal } from '../../utils'
import { getDishesFromDishesIds } from '../../utils/dishHelpers'
import { SmartImage } from '../core/SmartImage'

export interface DishDetailPageProps {
  dish: Dish
  onClose?: () => void
}

export function DishDetailPage({ dish, onClose }: DishDetailPageProps) {
  const { tw } = useTw()
  const {
    dishCaption,
    dishName,
    recipeIsLiked,
    dishQty,
    dishVariants,
    recipeLikesAmount,
    dishPrice,
    dishIsAvailable,
    dishDescription,
    decreaseQuantity,
    increaseQuantity,
    toggleDishlike,
    userIsAllergicToThisDish,
    ingredientNames,
    imageUrl,
  } = useDish(dish)
  const { t, i18n } = useTranslation()
  const { dishes: menuDishes } = useMenuStore()
  const { appMode, appFeatures } = useAppConfigStore()
  const [expandedDescription, setExpandedDescription] = useState<boolean>(false)

  const SetQtyButtons = useCallback(() => {
    return dishIsAvailable ? (
      <View style={tw`flex-row justify-end items-center rounded-lg mt-xs`}>
        {dishQty > 0 && (
          <>
            <IconButton
              testID={'decrement_dish_' + dish.dishId}
              onPress={decreaseQuantity}
              icon="Minus"
              variant={ButtonVariant.Default}
            />
            <Text style={tw`title2 textMono text-center w-10`}>{dishQty}</Text>
          </>
        )}
        <IconButton
          testID={'increment_dish_' + dish.dishId}
          onPress={increaseQuantity}
          icon="Plus"
          variant={!userIsAllergicToThisDish ? ButtonVariant.Secondary : ButtonVariant.Warning}
        />
      </View>
    ) : (
      <Text
        style={tw`textMono items-center justify-center`}
        onPress={() => showToast(t('errors.dishNotAvailable'), 'ERROR', 3000, Toast.positions.CENTER)}
      >
        {t('l.unavailable')}
      </Text>
    )
  }, [dishIsAvailable, appMode, appFeatures, dishQty, userIsAllergicToThisDish])

  const dishPriceLabel = useMemo(() => {
    if (!dishPrice) return undefined
    if (dishCaption) return ' • ' + dishPrice
    return dishPrice
  }, [dishCaption, dishPrice])

  const dishDescriptionToRender = useMemo(() => {
    if (dishVariants.length === 0) {
      if (!dishCaption) return dishPriceLabel
      return `${dishCaption}${dishPriceLabel}`
    }
    return (
      [dish, ...getDishesFromDishesIds(dishVariants, menuDishes ?? [])]
        .map(dish => `${translateLocal(dish, 'caption', i18n.language)}: ${(dish.price ?? 0).toFixed(2) + '€'}`)
        .join(', ') + '\n'
    )
  }, [dishCaption, dishPrice])

  return (
    <>
      <Card
        style={tw`w-[${SCREEN_AVAILABLE_WIDTH - 76}px] p-0 rounded-lg overflow-hidden h-[${
          SCREEN_AVAILABLE_HEIGHT / 1.25
        }px] opacity-${dishIsAvailable ? '100' : '50'}`}
      >
        <View style={tw`w-full h-[${SCREEN_AVAILABLE_HEIGHT / 2.5}px] fillCard`}>
          <SmartImage style={tw`w-full h-full`} source={{ uri: imageUrl }} resizeMode="cover" forceHeight={300} />
        </View>
        <Card
          activeOpacity={1}
          variant={CardVariant.Default}
          style={tw`fillBackground rounded-b-lg rounded-t-0 strokeMono h-full`}
        >
          <Text style={tw`title2 textMono mr-xl`}>{dishName}</Text>
          <Text style={tw`mt-xs textMono mr-xl`}>{dishDescriptionToRender}</Text>
          <Text style={tw`mt-sm textMono label`}>
            {ingredientNames?.map((ingredient, index) => (
              <Text key={index} style={tw`label textMono ${ingredient.allergens ? 'font-bold' : ''}`}>{`${
                ingredient.name
              }${index !== ingredientNames.length - 1 ? ', ' : ''}`}</Text>
            ))}
          </Text>
          <View style={tw`flex-row flex-1 mb-xs`}>
            {!!dishDescription && dishDescription.length > 0 && (
              <TouchableOpacity style={tw`flex-1`} onPress={() => setExpandedDescription(true)}>
                <Text style={tw`textMono label mt-sm min-w-0`} numberOfLines={5}>
                  {dishDescription}
                </Text>
              </TouchableOpacity>
            )}
          </View>
          {appMode === 'USER' && appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && <SetQtyButtons />}
          {appFeatureIsAvailable(AppFeature.LIKE_RECIPE, appFeatures) ? (
            <>
              <Clickable
                id={`${recipeIsLiked ? 'dis' : ''}like_recipe_${dish.recipeId}`}
                onPress={toggleDishlike}
                style={tw`absolute items-center flex-col flex-1 top-[0px] right-[2px] w-[48px] p-sm`}
              >
                <Icon name={recipeIsLiked ? 'Heart' : 'HeartOutline'} size={IconSize.Large} color={IconColor.primary} />
                {recipeLikesAmount > 0 && <Text style={tw`textWhite title3 textMono`}>{recipeLikesAmount}</Text>}
              </Clickable>
            </>
          ) : undefined}
        </Card>
      </Card>
      <BaseModal title={dishName} visible={expandedDescription} onClose={() => setExpandedDescription(false)}>
        <View style={tw`pt-md`}>
          <ScrollView showsVerticalScrollIndicator={false} style={tw`max-h-[${SCREEN_AVAILABLE_HEIGHT - 100}px]`}>
            <Text style={tw`textMono title4`}>
              {dishDescription && dishDescription.length > 0 ? dishDescription : ''}
            </Text>
          </ScrollView>
        </View>
      </BaseModal>
    </>
  )
}
