import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { User } from '../types'

export interface UserStore extends User {
  setUser: (user: User) => void
  setIntolerances: (intolerances: number) => void
  likeRecipe: (recipeId: string) => void
  dislikeRecipe: (recipeId: string) => void
  setShowUnsuitableDishes: (show: boolean) => void
  setIntolerancesAsked: (asked: boolean) => void
  addAcceptedNotification: (notificationId: string) => void
  initUser: () => void
}

const initialState: User = { allergens: 0, likedRecipesId: [], intolerancesAsked: false, showUnsuitableDishes: false }

export const useUserStore = create<UserStore>()(
  persist(
    immer<UserStore>((set, _) => ({
      ...initialState,
      setUser: user =>
        set(store => {
          const acceptedNotifications = store.acceptedNotifications
          store = { ...store, ...user }
          store.acceptedNotifications = acceptedNotifications
        }),
      setIntolerances: intolerances =>
        set(store => {
          store.allergens = intolerances
        }),
      likeRecipe: recipeId =>
        set(store => {
          store.likedRecipesId.push(recipeId)
        }),
      dislikeRecipe: recipeId =>
        set(store => {
          store.likedRecipesId = store.likedRecipesId.filter(itm => itm !== recipeId)
        }),
      setShowUnsuitableDishes: show =>
        set(store => {
          store.showUnsuitableDishes = show
        }),
      setIntolerancesAsked: asked =>
        set(store => {
          store.intolerancesAsked = asked
        }),
      addAcceptedNotification: notificationId =>
        set(store => {
          if (!store.acceptedNotifications) store.acceptedNotifications = []
          store.acceptedNotifications.push(notificationId)
        }),
      initUser: () =>
        set(
          store => ({
            ...initialState,
            setUser: store.setUser,
            setIntolerances: store.setIntolerances,
            likeRecipe: store.likeRecipe,
            dislikeRecipe: store.dislikeRecipe,
            setShowUnsuitableDishes: store.setShowUnsuitableDishes,
            addAcceptedNotification: store.addAcceptedNotification,
            initUser: store.initUser,
          }),
          true
        ),
    })),
    {
      name: 'meamenu.user',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
