import { t } from 'i18next'
import { DishCategoryMap, MeaError } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export class DishCategoryService extends BaseUserService() {
  static entityPath: string = 'dishcategory'

  static async getByMenuId(menuId: string): Promise<DishCategoryMap> {
    const response = await getBackendClient().get<DishCategoryMap>(`${this.getPath()}/getbymenu/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetMenuCategories', t('errors.cannotGetMenuCategories'))
    }
    return response.data
  }
}
