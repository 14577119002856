import { Icon, IconColor, useTw } from '@mea-menu/components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { Clickable } from '.'
import { useMenuStore } from '../store'
import { translateLocal } from '../utils'

export interface CategoryItemProps {
  categoryKey: string
  expandedView?: boolean
  isSelected?: boolean
  onPress: (categoryKey: string) => void
}

export function CategoryScrollerItem({ categoryKey, expandedView, isSelected, onPress }: CategoryItemProps) {
  const { tw } = useTw()
  const { t, i18n } = useTranslation()
  const { dishCategories } = useMenuStore()

  if (!dishCategories) return null
  const forYou = categoryKey === 'forYou'
  const categoryKeysSplit = categoryKey.split('.')
  const categoryLabel = forYou
    ? t('l.foryou')
    : translateLocal(dishCategories[categoryKeysSplit[0]], 'name', i18n.language)
  const subCategoryLabel =
    categoryKeysSplit.length === 2 ? translateLocal(dishCategories[categoryKey], 'name', i18n.language) : undefined

  const bgColor = useMemo(() => {
    if (forYou) return !isSelected ? 'bg-primary/15 strokePrimary' : 'fillPrimary strokePrimary'
    return isSelected ? 'fillCardSelected strokeTransparent' : 'fillCard strokeMono'
  }, [isSelected])

  const textColor = useMemo(() => {
    if (forYou) return !isSelected ? 'textPrimary' : 'textWhite'
    return isSelected ? 'textMonoInverted' : 'textMono'
  }, [isSelected, forYou])

  const mainCategory = subCategoryLabel ?? categoryLabel

  return (
    <Clickable
      style={tw`${bgColor} border-[1px] h-[50px] rounded-md ${
        expandedView ? 'm-xxs' : 'py-xs'
      } flex-1 px-md justify-center items-center flex-wrap  ${forYou ? 'flex-row' : 'flex-col'} `}
      onPress={() => onPress(categoryKey)}
    >
      {forYou && (
        <View style={tw`mr-xs`}>
          <Icon color={isSelected ? IconColor.white : IconColor.primary} name="Stars" />
        </View>
      )}
      <Text
        style={tw`title4 ${expandedView ? 'leading-5' : 'leading-3'} ${textColor} ${
          !subCategoryLabel ? 'text-center' : ''
        } `}
        numberOfLines={expandedView ? 2 : undefined}
      >
        {mainCategory}
      </Text>
      {subCategoryLabel && !expandedView && <Text style={tw`label leading-4 ${textColor}`}>{`${categoryLabel}`}</Text>}
    </Clickable>
  )
}
