import { useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { useMenuStore } from '../store'
import { specialDishesKeys } from '../types'
import { translateLocal } from '../utils'

export function CategoryName({
  categoryKey,
  catSubcatSeparator,
}: {
  categoryKey: string
  catSubcatSeparator: 'dash' | 'newLine'
}) {
  const { tw } = useTw()
  const { dishCategories } = useMenuStore()
  const { t, i18n } = useTranslation()

  const MainText = ({ string }: { string: string }) => <Text style={tw`textMono title3`}>{string}</Text>
  const SecondaryText = ({ string }: { string: string }) => <Text style={tw`textMono label pt-[4px]`}>{string}</Text>
  const layout =
    catSubcatSeparator === 'dash'
      ? 'flex-row items-baseline'
      : catSubcatSeparator === 'newLine'
      ? 'flex-col items-center'
      : ''

  if (!dishCategories || !categoryKey) return null
  if (categoryKey.endsWith('.others')) {
    return (
      <View style={tw`${layout}`}>
        <MainText string={`${translateLocal(dishCategories[categoryKey.split('.')[0]], 'name', i18n.language)}`} />
        <SecondaryText string={`${catSubcatSeparator === 'dash' ? ' - ' : ''}${t('l.otherDishes')}`} />
      </View>
    )
  } else if (specialDishesKeys.includes(categoryKey)) {
    return <MainText string={i18n.t(`l.${categoryKey}`)} />
  } else if (categoryKey.includes('.')) {
    const parentKey = categoryKey.split('.')[0]
    return (
      <View style={tw`${layout}`}>
        <MainText string={`${translateLocal(dishCategories[categoryKey], 'name', i18n.language)}`} />
        <SecondaryText
          string={`${catSubcatSeparator === 'dash' ? ' - ' : ''}${translateLocal(
            dishCategories[parentKey],
            'name',
            i18n.language
          )}`}
        />
      </View>
    )
  } else {
    return <MainText string={`${translateLocal(dishCategories[categoryKey], 'name', i18n.language)}`} />
  }
}
