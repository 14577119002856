import { t } from 'i18next'
import { MeaError, NotAuthorizedCode } from '../../types'
import { BaseService } from '../BaseService'
import { getAuthClient, isAxiosErrorFromDomainWithInternalCode, noResponse } from '../HTTPClient'

interface GetDraftUserTokenResponse {
  draftToken: string
}

export interface AuthResponse {
  userId: string
  accessToken: string
}

export class UserAuthService extends BaseService() {
  static entityPath: string = 'userauth'

  static async getDraftUserToken(webAppUuid: string): Promise<GetDraftUserTokenResponse> {
    try {
      const response = await getAuthClient().post<GetDraftUserTokenResponse>(`${this.entityPath}/getDraftTokenUuid`, {
        webAppUuid,
      })
      if (noResponse(response) || !response.data.draftToken) {
        throw new MeaError('cannotGetDraftToken', t('errors.cannotGetDraftToken'), true)
      }
      return response.data
    } catch (error) {
      throw new MeaError('cannotGetDraftToken', t('errors.cannotGetDraftToken'), true)
    }
  }

  static async loginWithUuid(webAppUuid: string): Promise<AuthResponse> {
    try {
      const response = await getAuthClient().post<AuthResponse>(`${this.entityPath}/uuidlogin`, {
        webAppUuid,
      })
      if (noResponse(response) || !response.data.accessToken || !response.data.userId) {
        throw new MeaError('errorGettingUser', t('errors.errorGettingUser'), true)
      }
      return response.data
    } catch (error) {
      if (isAxiosErrorFromDomainWithInternalCode(error, NotAuthorizedCode.INVALID_CREDENTIALS)) {
        throw new MeaError('invalidUserUUID', t('errors.invalidUserUUID'), true)
      }
      throw new MeaError('errorGettingUser', t('errors.errorGettingUser'), true)
    }
  }
}
