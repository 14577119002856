import { Button, ButtonVariant, Card, CardVariant, colors, useTw } from '@mea-menu/components'
import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, Switch, Text, View } from 'react-native'
import { ActionType, IntoleranceActionPayloadAction, PublicService } from '../../api/user/PublicService'
import { UserService } from '../../api/user/UserService'
import { useMenuStore, useSpecialDishesStore, useUserStore } from '../../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../../theme/sizes'
import { translateLocal } from '../../utils'
import { fetchAndSetSpecialDishesData } from '../../utils/commonHelpers'
import { INTOLERANCES } from '../../utils/dishHelpers'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'

export interface IntolerancesListFragmentProps {
  onIntoleranceSetSuccess?: (intoleranceValue: number) => void
  onCancel?: () => void
  hideUnsuitableToggle?: boolean
  heightReduction?: number
}

export function IntolerancesListFragment({
  onIntoleranceSetSuccess,
  onCancel,
  hideUnsuitableToggle = false,
  heightReduction = 0,
}: IntolerancesListFragmentProps) {
  const { tw } = useTw()

  const {
    allergens: userAllergies,
    showUnsuitableDishes,
    setShowUnsuitableDishes,
    setIntolerances,
    setIntolerancesAsked,
  } = useUserStore()
  const { setForYouRecipes, setFeaturedDishes, setTrendingRecipes } = useSpecialDishesStore()
  const { _id: menuId } = useMenuStore()
  const [tempIntolerancesValue, setTempIntolerancesValue] = useState<number>(userAllergies ?? 0)
  const { t, i18n } = useTranslation()

  const userIsIntolerant = useCallback(
    (allergenValue: number) => (tempIntolerancesValue & allergenValue) === allergenValue,
    [tempIntolerancesValue]
  )

  const toggleIntolerance = useCallback(
    (allergenValue: number) => {
      setTempIntolerancesValue(
        userIsIntolerant(allergenValue) ? tempIntolerancesValue - allergenValue : tempIntolerancesValue + allergenValue
      )
    },
    [tempIntolerancesValue]
  )

  const confirmUpdate = async () => {
    // Fault-intolerant request
    try {
      await UserService.updateIntolerances(tempIntolerancesValue)
      setIntolerances(tempIntolerancesValue)
      onIntoleranceSetSuccess && onIntoleranceSetSuccess(tempIntolerancesValue)
      setIntolerancesAsked(true)
      if (!!menuId) {
        fetchAndSetSpecialDishesData(
          menuId,
          tempIntolerancesValue,
          setForYouRecipes,
          setFeaturedDishes,
          setTrendingRecipes
        )
      }
    } catch (e) {
      meaErrorHandler(e, 'UPDATE')
    }
  }
  const [isEnabled, setIsEnabled] = useState(false)
  const toggleSwitch = () => setIsEnabled(previousState => !previousState)

  return (
    <View style={{ maxHeight: SCREEN_AVAILABLE_HEIGHT - heightReduction, paddingHorizontal: 10 }}>
      <View>
        {tempIntolerancesValue > 0 && !hideUnsuitableToggle && (
          <Card
            activeOpacity={0.9}
            style={tw`flex-row justify-between items-center mb-md`}
            onPress={() => setShowUnsuitableDishes(!showUnsuitableDishes)}
          >
            <Text style={tw`textMono title4 font-bold mr-lg`}>{t('l.showUnsuitableDishes')}</Text>
            <Switch
              trackColor={{ false: colors.teal[200], true: colors.teal[300] }}
              thumbColor={isEnabled ? colors.grey[200] : colors.teal[100]}
              ios_backgroundColor="#3e3e3e"
              onValueChange={setShowUnsuitableDishes}
              value={showUnsuitableDishes}
            />
          </Card>
        )}
        <Text style={tw`textMono title4 mb-md`}>{t('l.selectYourIntolerances')}</Text>
      </View>
      <FlatList
        showsVerticalScrollIndicator={false}
        data={INTOLERANCES}
        ItemSeparatorComponent={() => <View style={tw`h-sm`} />}
        keyExtractor={item => item.value.toString()}
        numColumns={2}
        renderItem={({ item, index }) => (
          <Card
            style={tw`${index % 2 === 0 ? 'mr-md' : ''} `}
            variant={userIsIntolerant(item.value) ? CardVariant.Primary : CardVariant.Default}
            onPress={() => toggleIntolerance(item.value)}
          >
            <Image source={item.imageUri} style={tw`h-[80px] h-[80px]`} resizeMode="contain" />
            <Text style={tw`text-center font-bold textMono`}>{translateLocal(item, 'name', i18n.language)}</Text>
          </Card>
        )}
        style={[tw`flex-1`]}
      />
      <View style={tw`flex-row mt-md`}>
        <Button
          style={tw`mr-xs flex-1`}
          label={t('l.cancel')}
          onPress={() => {
            PublicService.logAction(ActionType.Intolerance, {
              action: IntoleranceActionPayloadAction.Cancel,
              value: tempIntolerancesValue,
            })
            setTempIntolerancesValue(userAllergies)
            onCancel?.()
          }}
        />

        <Button
          style={tw`flex-1`}
          label={t('l.save')}
          onPress={() => {
            PublicService.logAction(ActionType.Intolerance, {
              action: IntoleranceActionPayloadAction.Confirm,
              value: tempIntolerancesValue,
            })

            confirmUpdate()
          }}
          variant={ButtonVariant.Primary}
        />
      </View>
    </View>
  )
}
