import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { Menu } from '../types'

export interface MenuStore extends Partial<Menu> {
  setMenu: (menu: Menu) => void
  initMenu: () => void
  setUnavailableRecipeIds: (recipeIds: string[]) => void
  setRecipeLikesMap: (recipeLikesMap: { [recipeId: string]: number }) => void
  increaseRecipeLikes: (recipeId: string, amount?: number) => void
  decreaseRecipeLikes: (recipeId: string, amount?: number) => void
}

const initialState: Partial<Menu> = {
  recipeLikesMap: {},
}

export const useMenuStore = create<MenuStore>()(
  persist(
    immer<MenuStore>((set, _) => ({
      ...initialState,
      setMenu: menu => set(_store => ({ ...menu })),
      initMenu: () =>
        set(
          store => ({
            ...initialState,
            setMenu: store.setMenu,
            initMenu: store.initMenu,
            setUnavailableRecipeIds: store.setUnavailableRecipeIds,
          }),
          true
        ),
      setUnavailableRecipeIds: recipeIds =>
        set(store => {
          store.unavailableRecipeIds = recipeIds
        }),
      setRecipeLikesMap: recipeLikesMap =>
        set(store => {
          store.recipeLikesMap = recipeLikesMap
        }),
      increaseRecipeLikes: (recipeId, amount = 1) =>
        set(store => {
          if (!store.recipeLikesMap) return
          if (!store.recipeLikesMap[recipeId]) store.recipeLikesMap[recipeId] = 0
          store.recipeLikesMap[recipeId] += amount
        }),
      decreaseRecipeLikes: (recipeId, amount = 1) =>
        set(store => {
          if (!store.recipeLikesMap || !store.recipeLikesMap[recipeId]) return
          if (amount >= store.recipeLikesMap[recipeId])
            store.recipeLikesMap = Object.fromEntries(
              Object.entries(store.recipeLikesMap).filter(entry => entry[0] !== recipeId)
            )
          store.recipeLikesMap[recipeId] -= amount
        }),
    })),
    {
      name: 'meamenu.menu',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
