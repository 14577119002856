import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { RecipeSuggestion } from '../types'

interface SpecialDishesState {
  forYouRecipes: RecipeSuggestion[]
  featuredDishesId: string[]
  trendingRecipesId: string[]
  akinatorDishesId: string[]
}

interface SpecialDishesStore extends SpecialDishesState {
  setForYouRecipes: (recipeSuggestions: RecipeSuggestion[]) => void
  setFeaturedDishes: (dishesId: string[]) => void
  setTrendingRecipes: (recipesIds: string[]) => void
  setAkinatorDishes: (dishesIds: string[]) => void
}

const initialState: SpecialDishesState = {
  forYouRecipes: [],
  featuredDishesId: [],
  trendingRecipesId: [],
  akinatorDishesId: [],
}

export const useSpecialDishesStore = create<SpecialDishesStore>()(
  persist(
    immer<SpecialDishesStore>((set, get) => ({
      ...initialState,
      setForYouRecipes: recipeSuggestions =>
        set(store => {
          store.forYouRecipes = recipeSuggestions
        }),
      setFeaturedDishes: dishesIds =>
        set(store => {
          store.featuredDishesId = dishesIds
        }),
      setTrendingRecipes: recipesIds =>
        set(store => {
          store.trendingRecipesId = recipesIds
        }),
      setAkinatorDishes: dishesIds =>
        set(store => {
          store.akinatorDishesId = dishesIds
        }),
    })),
    {
      name: 'meamenu.specialDishes',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
