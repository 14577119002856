import { Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { BaseModal } from '../'
import { useMenuStore } from '../../store'
import { AkinatorFragment } from '../fragments/AkinatorFragment'

export interface AkinatorModalProps {
  visible: boolean
  onDishPressed?: (dishId: string) => void
  onClose: () => void
}

export function AkinatorModal({ visible, onDishPressed, onClose }: AkinatorModalProps) {
  const { _id: menuId, ingredients, dishes } = useMenuStore()
  const [akinatorIteration, setAkinatorIteration] = useState(0)
  const { t } = useTranslation()
  const { tw } = useTw()
  if (!menuId || !ingredients || !dishes) return null

  return (
    <BaseModal
      title={''}
      visible={visible}
      fullScreen
      onClose={() => {
        onClose && onClose()
      }}
    >
      <AkinatorFragment
        key={akinatorIteration}
        menuId={menuId}
        onDishPressed={onDishPressed}
        onResultsVisibleChildren={
          <View style={tw`flex-row mt-xl`}>
            <Button label={t('l.restart')} style={tw`flex-1`} onPress={() => setAkinatorIteration(iter => iter + 1)} />
            <Button
              label={t('l.goToMenu')}
              style={tw`ml-sm flex-1`}
              variant={ButtonVariant.Secondary}
              onPress={() => onClose && onClose()}
            />
          </View>
        }
      />
    </BaseModal>
  )
}
