export const CommonPalette = {
  meaOrange: '#F66323',
  meaOrangeOpaque: '#F6632330',
  meaGreen: '#167C7C',
  meaGreenAccent: '#08ce9f',
  yellow: '#f6d524',
  red: '#f93f3e',
  unsuitableDishButtonBg: '#b58c00',
  white: '#ffffff',
  white10: '#FFFFFF10',
  white50: '#FFFFFF50',
  white80: '#FFFFFF80',
  white90: '#FFFFFF90',
  grey: '#AAAAAA',
  black: '#000000',
  black10: '#00000010',
  black50: '#00000050',
  black80: '#00000080',
  black90: '#00000090',
  transparent: '#fff0',
}

export const LightPalette = {
  ...CommonPalette,
  background: '#fff',
  bottomBar: '#fff',
  navBar: '#fff',
  cardBg: '#0000000b',
  primaryCardBg: '#F6632320',
  categoryScrollerItem: '#0000000b',
  categoryScrollerItemHighlight: '#000000',
  modalBg: '#fff',
  modalBorderBg: '#AAAAAA80',
  menuButtonBg: '#000',
  buttonBg: '#f5f5f5',
  buttonAltBg: '#f0f0f0',
  text: '#000',
  textOpaque: '#000000a0',
  border: '#ccc',
}

export const DarkPalette = {
  ...CommonPalette,
  background: '#010d0a',
  bottomBar: '#010d0a',
  navBar: '#102722',
  cardBg: '#FFFFFF10',
  primaryCardBg: '#aaaaaa20',
  categoryScrollerItem: '#FFFFFF10',
  categoryScrollerItemHighlight: '#ffffff',
  modalBg: '#010d0a',
  modalBorderBg: '#AAAAAA80',
  menuButtonBg: '#ffffff60',
  buttonBg: '#031a15',
  buttonAltBg: '#FFFFFF10',
  text: '#fff',
  textOpaque: '#ffffffa0',
  border: '#09362C',
}

export const Palettes = {
  light: LightPalette,
  dark: DarkPalette,
}

export type ColorsType = keyof typeof LightPalette
