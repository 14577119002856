import { Theme } from '@mea-menu/components'
import { Appearance } from 'react-native'
import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'

interface ThemeState {
  colorMode: Theme
}

interface ThemeStore extends ThemeState {
  setColorMode: (colorMode: Theme) => void
}

const initialState: ThemeState = { colorMode: Appearance.getColorScheme() === 'dark' ? Theme.DARK : Theme.LIGHT }

export const useThemeStore = create<ThemeStore>()(
  persist(
    immer<ThemeStore>((set, _) => ({
      ...initialState,
      setColorMode: colorMode =>
        set(store => {
          store.colorMode = colorMode
        }),
    })),
    {
      name: 'meamenu.theme',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
