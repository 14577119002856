import { useTw } from '@mea-menu/components'
import { BlurView } from 'expo-blur'
import { ActivityIndicator } from 'react-native'
import { Palettes } from '../theme/palette'

export const LoadingIndicator = ({ visible, rounded }: { visible: boolean; rounded?: boolean }) => {
  const { tw } = useTw()

  if (!visible) return null
  return (
    <BlurView
      style={tw`absolute top-0 bottom-0 right-0 left-0 h-full justify-center items-center z-10 ${
        rounded ? 'rounded-lg' : ''
      }`}
      intensity={20}
    >
      <ActivityIndicator size={'large'} color={Palettes['dark'].meaOrange} />
    </BlurView>
  )
}
