import { Button, ButtonState, ButtonVariant, Icon, IconColor, IconSize, useTw } from '@mea-menu/components'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Linking, Text, TextInput, TouchableOpacity, View } from 'react-native'
import { Images } from '../../assets'
import { FeedbackService } from '../api/user/FeedbackService'
import { Screen } from '../components'
import { useRestaurantStore } from '../store'
import { meaErrorHandler } from '../utils/MeaErrorHandler'

export interface RecapProps {
  onNewOrder: () => void
}

export function RecapScreen() {
  const { tw } = useTw()
  const { t } = useTranslation()
  const { _id: restaurantId } = useRestaurantStore()
  const [canInteractWithReview, setCanInteractWithReview] = useState<boolean>(true)
  const [stars, setStars] = useState<number>(0)
  const [feedbackMessages, setFeedbackMessages] = useState<string[]>([])
  const [reviewInputVisible, setReviewInputVisible] = useState<boolean>(false)
  const [reviewInputValue, setReviewInputValue] = useState<string>('')
  const [feedbackDone, setFeedbackDone] = useState<boolean>(false)

  const INSTAGRAM_LINK = 'https://www.instagram.com/mea.menu'
  enum FeedbackMessageType {
    hardToUse = 'hardToUse',
    longLoadingTimes = 'longLoadingTimes',
    unclearInfos = 'unclearInfos',
  }

  const MessagesLabels: { [key: string]: string } = {
    [FeedbackMessageType.hardToUse]: t('l.helpUsImproveHardToUse'),
    [FeedbackMessageType.longLoadingTimes]: t('l.helpUsImproveLongLoadingTimes'),
    [FeedbackMessageType.unclearInfos]: t('l.helpUsImproveUnclearInfos'),
  }

  const openExternalLink = async (url: string) => {
    try {
      const supported = await Linking.canOpenURL(url)
      if (supported) {
        await Linking.openURL(url)
      }
    } catch (error) {
      meaErrorHandler(error, 'FETCH')
    }
  }

  const sendFeedback = async () => {
    if (!restaurantId) return
    setCanInteractWithReview(false)
    try {
      let userMessage = reviewInputValue
      Object.values(MessagesLabels).forEach(
        label => (userMessage = userMessage.replaceAll(`${label}, `, '').replaceAll(label, ''))
      )
      const messagesToSend = [...feedbackMessages]
      if (userMessage.trim().length > 0) {
        messagesToSend.push(userMessage)
      }
      await FeedbackService.send(restaurantId, stars, messagesToSend)
      setFeedbackDone(true)
    } catch (error) {
      meaErrorHandler(error, 'UPDATE')
    }
  }

  const toggleMessage = (message: string) => {
    if (feedbackMessages.includes(message)) {
      setFeedbackMessages(feedbackMessages.filter(fm => fm !== message))
    } else {
      setFeedbackMessages([...feedbackMessages, message])
    }
  }

  const MessageButton = ({ messageType }: { messageType: FeedbackMessageType }) => {
    const isSelected = feedbackMessages.includes(messageType)
    return (
      <Button
        label={MessagesLabels[messageType]}
        style={tw`mr-sm mt-sm`}
        state={isSelected ? ButtonState.Success : ButtonState.Default}
        onPress={() => toggleMessage(messageType)}
      />
    )
  }

  return (
    <Screen padded noTopbar>
      <View style={tw`justify-center items-center ${stars === 0 ? 'mt-[50%]' : 'mt-[15%]'} p-lg`}>
        {!feedbackDone && (
          <>
            {stars === 0 && (
              <View>
                <Text style={tw`textMono title2 text-center`}>{t('l.thankYouForUsingMeaMenu')}</Text>
                <Text style={tw`mt-lg textMono text-center`}>{t('l.feedbackCallToAction')}</Text>
              </View>
            )}
            {stars > 0 && <Text style={tw`mt-lg textMono text-center`}>{t('l.yourReview')}</Text>}
            <View style={tw`mt-lg w-full px-[10%] flex-row justify-between`}>
              {[...Array(5)].map((_, i) => (
                <TouchableOpacity
                  key={i}
                  onPress={() => {
                    if (!canInteractWithReview) return
                    setReviewInputVisible(false)
                    setReviewInputValue('')
                    setStars(i + 1)
                  }}
                >
                  <Icon name={i < stars ? 'Star' : 'StarOutline'} size={IconSize.Large} color={IconColor.primary} />
                </TouchableOpacity>
              ))}
            </View>
            <View style={tw`mt-sm flex-row flex-1 w-full justify-between`}>
              <Text style={tw`flex-1 textMono text-center`}>{t('l.feedbackBad')}</Text>
              <Text style={tw`flex-1 textMono text-center`}>{t('l.feedbackOk')}</Text>
              <Text style={tw`flex-1 textMono text-center`}>{t('l.feedbackGood')}</Text>
            </View>
            {stars > 0 && stars <= 3 && (
              <View style={tw`mt-xl`}>
                <Text style={tw`mb-md textMono title2 text-center`}>{t('l.helpUsImproveTitle')}</Text>
                {stars < 3 && <Text style={tw`textMono text-center`}>{t('l.helpUsImproveBad')}</Text>}
                {stars === 3 && <Text style={tw`textMono text-center`}>{t('l.helpUsImproveOk')}</Text>}
                {!reviewInputVisible && (
                  <View style={tw`flex-row flex-wrap mt-sm`}>
                    <MessageButton messageType={FeedbackMessageType.hardToUse} />
                    <MessageButton messageType={FeedbackMessageType.longLoadingTimes} />
                    <MessageButton messageType={FeedbackMessageType.unclearInfos} />
                    <Button
                      label={t('l.helpUsImproveOther')}
                      style={tw`mr-sm mt-sm`}
                      onPress={() => {
                        setReviewInputVisible(true)
                        let newInputvalue = ''
                        if (feedbackMessages.length > 0) {
                          newInputvalue = `${feedbackMessages.map(fm => MessagesLabels[fm]).join(', ')}, `
                          setReviewInputValue(newInputvalue)
                        }
                      }}
                    />
                  </View>
                )}
                {reviewInputVisible && (
                  <TextInput
                    style={tw`h-[80px] mt-md mb-xl border-[2px] border-grey rounded-md text-lg p-md textMono`}
                    placeholder={t('l.helpUsImproveInputPlaceholder')}
                    multiline
                    autoFocus
                    selection={{ start: reviewInputValue.length, end: reviewInputValue.length }}
                    value={reviewInputValue}
                    onChangeText={setReviewInputValue}
                  />
                )}
              </View>
            )}
            {stars > 3 && (
              <View style={tw`mt-xl items-center`}>
                <Text style={tw`mb-lg textMono title2 text-center`}>{t('l.feedbackGoodTitle')}</Text>
                <Text style={tw`textMono text-center`}>{t('l.feedbackGoodCaption')}</Text>
              </View>
            )}
            {stars > 0 && (
              <View>
                <Button
                  label={t('l.helpUsImproveSendReview')}
                  style={tw`mt-xl`}
                  variant={ButtonVariant.Primary}
                  onPress={() => {
                    if (!canInteractWithReview) return
                    sendFeedback()
                  }}
                />
                {stars > 3 && (
                  <TouchableOpacity style={tw`mt-lg items-center`} onPress={() => openExternalLink(INSTAGRAM_LINK)}>
                    <Text style={tw`underline text-center textMono`}>{t('l.followUsOnInstagram')}</Text>
                    <Image source={Images.instagram_logo} style={tw`mt-sm w-[60px] h-[60px]`} />
                  </TouchableOpacity>
                )}
              </View>
            )}
          </>
        )}
        {feedbackDone && (
          <View style={tw`justify-center items-center flex-1`}>
            <Icon name="MeaMenu" size={IconSize.Large} />
            <Text style={tw`mt-xl textMono title2 text-center`}>{t('l.thanksFourYourFeedback')}</Text>
          </View>
        )}
        <Text style={tw`mt-[80px] textMono text-center`}>{t('l.recapScreenScanToOrderAgain')}</Text>
      </View>
    </Screen>
  )
}
