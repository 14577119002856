import { ButtonSize, ButtonVariant, IconButton, useTw } from '@mea-menu/components'
import { animated, useSpring } from '@react-spring/web'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { CategoryName, Screen } from '../components'
import { DishDetailCarousel } from '../components/DishDetailCarousel'
import { useMenu } from '../hooks/useMenuHook'
import { RootStackScreenProps } from '../navigation'
import { useMenuStore } from '../store'
import { GroupedDishes, SpecialDishesSectionData } from '../types'

export function DishDetailScreen({ navigation, route }: RootStackScreenProps<'DishDetailScreen'>) {
  const { tw } = useTw()
  const { t } = useTranslation()
  const { mode, dishDetailId, filter } = route.params
  const { filteredDishes, groupedSpecialDishes, akinatorDishes } = useMenu(filter)
  const [closeRequested, setCloseRequested] = useState<boolean>(false)
  const { ingredients, dishCategories, dishes } = useMenuStore()
  const [currentCategory, setCurrentCategory] = useState('')

  if (!dishes || !dishCategories || !ingredients) return null

  const [fadeSprings, fadeApi] = useSpring(() => ({
    from: { opacity: 0 },
    config: { duration: 200 },
  }))

  useEffect(() => {
    fadeApi.start({
      from: {
        opacity: closeRequested ? 1 : 0,
      },
      to: {
        opacity: closeRequested ? 0 : 1,
      },
      onResolve: () => {
        if (!closeRequested) return
        if (navigation.canGoBack()) {
          navigation.goBack()
        } else {
          navigation.navigate('MenuScreen')
        }
      },
    })
  }, [closeRequested])

  const mapSpecialDishInDishGroup = (specialDish: SpecialDishesSectionData[]) => {
    const result: GroupedDishes = {}
    specialDish.forEach(sd => {
      result[sd.title] = sd.data
    })
    return result
  }

  const carouselDishes = useMemo(() => {
    switch (mode) {
      case 'menu':
        return filteredDishes
      case 'forYou':
        return mapSpecialDishInDishGroup(groupedSpecialDishes)
      case 'akinator':
        return akinatorDishes
      default:
        return {}
    }
  }, [])

  return (
    <Screen
      noSettings
      noRestaurantName
      topBarChildren={
        <View style={tw`h-[80px] grow items-center flex-row`}>
          <View style={tw`absolute top-sm z-10`}>
            <IconButton icon="Back" variant={ButtonVariant.Primary} onPress={() => setCloseRequested(true)} />
          </View>
          <View style={tw`w-full items-center`}>
            <CategoryName categoryKey={currentCategory} catSubcatSeparator="newLine" />
          </View>
        </View>
      }
    >
      <animated.div style={{ ...tw`flex flex-1`, ...fadeSprings }}>
        <DishDetailCarousel
          groupedDishes={carouselDishes}
          onCategoryKeyChange={setCurrentCategory}
          onClose={() => setCloseRequested(true)}
          initialDishId={dishDetailId!}
        />
      </animated.div>
    </Screen>
  )
}
