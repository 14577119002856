import Constants from 'expo-constants'

interface Config {
  environment: string
  version: string
}

export const config: Config = {
  environment: Constants.expoConfig!.extra!.environment,
  version: Constants.expoConfig!.extra!.version,
}

export const environmentConfig: { [key: string]: { apiUrl: string } } = {
  local: { apiUrl: 'https://devapp.meamenu.com' },
  staging: { apiUrl: 'https://devapp.meamenu.com' },
  prod: { apiUrl: 'https://app.meamenu.com' },
}
