import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { Cart, DishOrder, DishOrderStatus } from '../types'
import { getCartDishesIdsInStatus } from '../utils/dishHelpers'

export interface CartState {
  menuId?: string
  orderId?: string
  currentCart: Cart
  lastUpdateSeconds: number
}

export interface CartStore extends CartState {
  initCart: (menuId: string | undefined) => void
  getCartDishesIdsInStatus: (status: DishOrderStatus) => string[]
  setOrderId: (orderId: string | undefined) => void
  resumeCartState: (content: { dishId: string; quantity: number }[]) => void
  increaseItemOrderingQuantity: (dishId: string) => void
  decreaseItemOrderingQuantity: (dishId: string) => void
  resetItemOrderingQuantity: (dishId: string) => void
  emptyOrdering: () => void
  moveOrderingInWaiting: () => void
  moveCartInHistory: () => void
  changeDishOrderStatus: (content: { order: DishOrder; status: DishOrderStatus }) => void
}

const initialState: CartState = {
  currentCart: {},
  lastUpdateSeconds: Math.trunc(new Date().getTime() / 1000),
}

function updateItemOrderingQuantity(cartStore: CartStore, dishId: string, increment: number) {
  let newCart = cartStore.currentCart
  const hasDishInCart = Object.keys(newCart).includes(dishId)
  if (hasDishInCart) {
    let dishOrdering = newCart[dishId].find(itm => itm.state === DishOrderStatus.ORDERING)
    if (!dishOrdering) {
      dishOrdering = { quantity: 0, state: DishOrderStatus.ORDERING, dishId }
      newCart[dishId].push(dishOrdering)
    }

    dishOrdering.quantity += increment
    if (dishOrdering.quantity <= 0) delete newCart[dishId]
  } else if (increment > 0) {
    newCart[dishId] = [{ quantity: increment, state: DishOrderStatus.ORDERING, dishId }]
  }
  cartStore.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
}

export const useCartStore = create<CartStore>()(
  persist(
    immer<CartStore>((set, get) => ({
      ...initialState,
      initCart: menuId =>
        set(store => {
          store.currentCart = {}
          store.orderId = undefined
          store.menuId = menuId
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
      getCartDishesIdsInStatus: (status: DishOrderStatus) => {
        return getCartDishesIdsInStatus(get().currentCart, status)
      },
      setOrderId: orderId =>
        set(store => {
          store.orderId = orderId
        }),
      resumeCartState: content =>
        set(store => {
          const currentCart: Cart = {}
          content.forEach(
            orderItem =>
              (currentCart[orderItem.dishId] = [
                { dishId: orderItem.dishId, quantity: orderItem.quantity, state: DishOrderStatus.ORDERING },
              ])
          )
          store.currentCart = currentCart
        }),
      increaseItemOrderingQuantity: dishId =>
        set(store => {
          updateItemOrderingQuantity(store, dishId, 1)
        }),
      decreaseItemOrderingQuantity: dishId =>
        set(store => {
          updateItemOrderingQuantity(store, dishId, -1)
        }),
      resetItemOrderingQuantity: dishId =>
        set(store => {
          const cartItem = Object.entries(store.currentCart).find(cartItem => cartItem[0] === dishId)
          if (!cartItem || !cartItem[1].some(dishOrderItem => dishOrderItem.state === DishOrderStatus.ORDERING)) return
          store.currentCart[dishId] = store.currentCart[dishId].filter(itm => itm.state !== DishOrderStatus.ORDERING)
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
      emptyOrdering: () =>
        set(store => {
          const clearedEntries = Object.entries(store.currentCart).map(itm => {
            return { id: itm[0], list: itm[1].filter(orders => orders.state !== DishOrderStatus.ORDERING) }
          })
          const newCart: Cart = {}
          clearedEntries.forEach(itm => {
            if (itm.list.length > 0) {
              newCart[itm.id] = itm.list
            }
          })
          store.currentCart = newCart
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
      moveOrderingInWaiting: () =>
        set(store => {
          Object.entries(store.currentCart).forEach(itm => {
            itm[1].forEach(order => {
              if (order.state === DishOrderStatus.ORDERING) {
                order.state = DishOrderStatus.WAITING
              }
            })
          })
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
      moveCartInHistory: () =>
        set(store => {
          Object.entries(store.currentCart).forEach(itm => {
            itm[1].forEach(order => {
              if (order.state === DishOrderStatus.ARRIVED || order.state === DishOrderStatus.WAITING) {
                order.state = DishOrderStatus.HISTORY
              }
            })
          })
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
      changeDishOrderStatus: content =>
        set(store => {
          const { order, status } = content
          const orderToUpdate = store.currentCart[content.order.dishId].find(itm => itm.state === order.state)
          if (orderToUpdate) orderToUpdate.state = status
          store.lastUpdateSeconds = Math.trunc(new Date().getTime() / 1000)
        }),
    })),
    {
      name: 'meamenu.cart',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
