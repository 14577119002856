import { ButtonVariant, Card, IconButton, useTw } from '@mea-menu/components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { StyleProp, Text, View, ViewStyle } from 'react-native'
import Toast from 'react-native-root-toast'
import { useDish } from '../../hooks/useDishHook'
import { useAppConfigStore, useNotificationsStore } from '../../store'
import { AppFeature, Dish, MeaNotificationType } from '../../types'
import { appFeatureIsAvailable, showToast } from '../../utils'
import { SmartImage } from '../core/SmartImage'

export interface DishOrderCardProps {
  dish: Dish
  quantity: number
  onPress?: () => void
  arrived?: boolean
  style?: StyleProp<ViewStyle>
  horizontal?: boolean
  canEditQuantity: boolean
}
export function DishOrderItem({ dish, quantity, onPress, style, arrived, canEditQuantity }: DishOrderCardProps) {
  const {
    dishName,
    dishCaption,
    dishPrice,
    dishIsAvailable,
    userIsAllergicToThisDish,
    decreaseQuantity,
    increaseQuantity,
    imageUrl,
  } = useDish(dish)
  const { tw } = useTw()
  const { t } = useTranslation()
  const { setNotification } = useNotificationsStore()
  const { appFeatures } = useAppConfigStore()

  const SetQtyButtons = () => {
    return dishIsAvailable ? (
      <View style={tw`flex-row items-center justify-between rounded-lg mt-xs`}>
        <IconButton
          variant={ButtonVariant.Accent}
          testID={'decrement_dish_' + dish.dishId}
          onPress={() => {
            if (quantity > 1) {
              decreaseQuantity()
            } else {
              setNotification({
                type: MeaNotificationType.CONFIRM,
                title: t('l.warning'),
                description: t('l.removeSelectedDishFromCurrent'),
                cancelText: t('l.no'),
                confirmText: t('l.yes'),
                onConfirm: () => decreaseQuantity(),
              })
            }
          }}
          icon="Minus"
        />
        <Text style={tw`title3 textMono w-[52px] text-center`}>{quantity}</Text>
        <IconButton
          testID={'increment_dish_' + dish.dishId}
          onPress={increaseQuantity}
          icon="Plus"
          variant={!userIsAllergicToThisDish ? ButtonVariant.Secondary : ButtonVariant.Accent}
        />
      </View>
    ) : (
      <Text
        style={tw`textMono items-center justify-center`}
        onPress={() => showToast(t('errors.dishNotAvailable'), 'ERROR', 3000, Toast.positions.CENTER)}
      >
        {t('l.unavailable')}
      </Text>
    )
  }

  const dishPriceLabel = useMemo(() => {
    if (!dishPrice) return undefined
    if (dishCaption) return ' - ' + dishPrice
    return dishPrice
  }, [dishCaption, dishPrice])

  return (
    <Card
      onPress={onPress}
      id={(arrived ? 'arrived_' : 'waiting_') + 'order_dish_' + dish.dishId}
      key={dish.dishId}
      style={[
        tw`rounded-lg p-xs flex-row grow mb-sm items-center ${
          arrived ? 'strokeSecondary' : 'strokeTransparent'
        } border-2`,
        style,
      ]}
    >
      <SmartImage
        style={tw`w-[80px] h-[80px] rounded-sm`}
        source={{ uri: imageUrl }}
        resizeMode="contain"
        forceHeight={120}
      />
      <View style={tw`flex-col flex-1 ml-md`}>
        <Text style={tw`flex-1 font-bold textMono`}>{dishName}</Text>
        <Text style={tw`mt-[3px] textMono`}>
          {dishCaption}
          {dishPriceLabel}
          {!canEditQuantity && <Text style={tw`mr-md textMono font-bold`}>{' x ' + quantity}</Text>}
        </Text>

        <View style={tw`items-end`}>
          {canEditQuantity && appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && <SetQtyButtons />}
        </View>
      </View>
    </Card>
  )
}
