import { useTw } from '@mea-menu/components'
import Lottie from 'lottie-react-native'
import { useState } from 'react'
import { View } from 'react-native'

export const SplashPage = () => {
  const { tw } = useTw()
  const [animationFinished, setAnimationFinished] = useState<boolean>(false)

  return (
    <View style={tw`absolute top-0 bottom-0 right-0 left-0 h-full justify-center items-center fillBackground`}>
      {!animationFinished && (
        <Lottie
          onAnimationFinish={() => setAnimationFinished(true)}
          style={tw`w-[80%]`}
          source={require('../../assets/animations/loading.json')}
          loop={false}
          autoPlay
        />
      )}
    </View>
  )
}
