import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'

interface SessionState {
  menuScreenIsActive: boolean
}

interface SessionStore extends SessionState {
  setMenuScreenActive: (active: boolean) => void
}

const initialState: SessionState = {
  menuScreenIsActive: false,
}

export const useSessionStore = create<SessionStore>()(
  immer<SessionStore>((set, _) => ({
    ...initialState,
    setMenuScreenActive: active =>
      set(store => {
        store.menuScreenIsActive = active
      }),
  }))
)
