import { Icon, IconSize, useTw } from '@mea-menu/components'
import React, { useState } from 'react'
import { Image, ImageStyle, Modal, StyleProp, TouchableOpacity, View } from 'react-native'

export interface ZoomableImageProps {
  uri?: string
  style?: StyleProp<ImageStyle>
}

export const ZoomableImage: React.FC<ZoomableImageProps> = ({ uri, style }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { tw } = useTw()

  return (
    <View>
      <TouchableOpacity
        activeOpacity={0.6}
        style={tw`mb-md mx-md p-lg bg-buttonBg rounded-b-sm rounded-sm shadow-lg`}
        onPress={() => {
          if (uri && uri?.length > 0) {
            setIsModalVisible(!isModalVisible)
          }
        }}
      >
        <Image
          style={[tw`w-full h-60`, style]}
          source={{
            uri,
          }}
          resizeMode={'contain'}
        />
      </TouchableOpacity>

      <Modal visible={isModalVisible} animationType="fade" style={tw`fillBackground`}>
        <TouchableOpacity
          style={tw`absolute top-sm right-sm z-10 bg-buttonBg rounded-xl p-md shadow-md`}
          onPress={() => {
            setIsModalVisible(false)
          }}
        >
          <Icon name="Close" size={IconSize.Large} />
        </TouchableOpacity>
        <Image source={{ uri }} style={[tw`w-full h-full fillBackground`]} resizeMode={'contain'} />
      </Modal>
    </View>
  )
}
