import { Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { Image, Text, View } from 'react-native'
import { Images } from '../../assets'

export interface AkinatorCardProps {
  onButtonPressed: () => void
}

export function AkinatorCard({ onButtonPressed }: AkinatorCardProps) {
  const { tw } = useTw()
  const { t } = useTranslation()

  return (
    <View style={tw`mt-sm`}>
      <Image source={Images.foryoubg} style={tw`w-full h-full rounded-lg absolute`} />

      <View style={tw`flex-row items-center flex-1 mx-md py-md`}>
        <View style={tw`flex-1 mr-md`}>
          <Text style={tw`textWhite title2`}>{t('l.stillUndecided')}</Text>
          <Text style={tw`textWhite title4`}>{t('l.stillUndecidedCaption')}</Text>
        </View>
        <View>
          <Button variant={ButtonVariant.Primary} label={t('l.letsgo')} onPress={onButtonPressed} />
        </View>
      </View>
    </View>
  )
}
