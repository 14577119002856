export class MeaError extends Error {
  constructor(message: string, localizedMessage?: string, fatal: boolean = false) {
    super(message)
    this.name = 'MeaError'
    // this.stack = message
    this.localizedMessage = localizedMessage
    this.fatal = fatal
  }
  localizedMessage?: string
  fatal: boolean
}

export class DomainError extends Error {
  httpStatus!: number
  internalCode!: number
  systemMessage?: string
  clientMessage?: string
  object?: any

  constructor(
    message: string,
    httpStatus: number,
    internalCode: number,
    systemMessage?: string,
    clientMessage?: string,
    object?: any
  ) {
    super(message)
    this.name = this.constructor.name
    this.httpStatus = httpStatus
    this.internalCode = internalCode
    this.systemMessage = systemMessage
    this.clientMessage = clientMessage
    this.object = object
    Error.captureStackTrace(this, this.constructor)
  }
}

export enum NotAllowedCode {
  DISH_NOT_AVAILABLE = 4221,
  EXPIRED_ORDER = 4222,
  SUBMIT_NOT_DRAFT_ORDER = 4223,
}

export enum NotAuthorizedCode {
  INVALID_CREDENTIALS = 4101,
  INVALID_TOKEN = 4102,
  REFRESH_TOKEN_FAIL = 4106,
  SUDO_ONLY = 4108,
}

export enum AppFeature {
  LIKE_RECIPE = 1,
  EDIT_INTOLERANCES = 2,
  ORDER_WITH_WAITER = 4,
}

export type UserLogin = {
  userUuid?: string
  userId: string
  accessToken?: string
}

export interface TranslatableName {
  nameEn: string
  nameIt: string
  nameEs: string
  nameFr: string
  nameDe: string
  nameRu: string
  nameZh: string
  nameJa: string
}

export interface TranslatableDescription {
  descriptionEn: string
  descriptionIt: string
  descriptionEs: string
  descriptionFr: string
  descriptionDe: string
  descriptionRu: string
  descriptionZh: string
  descriptionJa: string
}

export interface TranslatableCaption {
  captionEn: string
  captionIt: string
  captionEs: string
  captionFr: string
  captionDe: string
  captionRu: string
  captionZh: string
  captionJa: string
}

export type Summary = {
  menuId?: string
  lastActionDateSeconds?: number
  ordering: boolean
  ordered: { [dishId: string]: number }
  waiting: { [dishId: string]: number }
  history: { [dishId: string]: number }
}

export type User = {
  _id?: string
  firstname?: string
  lastname?: string
  birthdate?: Date
  email?: string
  allergens: number
  likedRecipesId: string[]
  acceptedNotifications?: string[]
  intolerancesAsked: boolean
  showUnsuitableDishes: boolean
}

export enum MeaNotificationType {
  CONFIRM,
  ALERT,
  NO_BUTTONS,
  DISH_VARIANTS,
}

export interface MeaBaseNotification {
  type: MeaNotificationType
  title: string
  description?: string
  imageSource?: any
  confirmText?: string
  cancelText?: string
  onConfirm?: () => void
  onCancel?: () => void
}

export interface MeaNotification extends MeaBaseNotification {
  type: MeaNotificationType.ALERT | MeaNotificationType.CONFIRM | MeaNotificationType.NO_BUTTONS
}

export interface MeaDishVariantsNotification extends MeaBaseNotification {
  type: MeaNotificationType.DISH_VARIANTS
  variantsDishIds: string[]
  onDishSelected: (dishId: string) => void
}

export type SpecialDishes = {
  forYouDishesId: string[]
  featuredDishesId: string[]
  trendingDishesId: string[]
}

export type RestaurantCheckInInfo = {
  restaurant: Restaurant
  table?: Table
}

export type Table = {
  _id: string
  restaurantId: string
  name: string
  seats: number
  status: TableStatuses
}

export enum TableStatuses {
  ACTIVE = 'active',
  IDLE = 'idle',
  BUSY = 'busy',
}

export type Restaurant = Partial<TranslatableDescription> & {
  _id?: string
  name?: string
  coverPrice?: number
  priceCurrency: string
  cover?: Media
  menus: MenuPreview[]
  lat?: number
  lng?: number
  address?: string
  //email: string;
  //phone: string;
  //rating?: number;
  //avgprice?: number;
  //status?: RestaurantStatuses;
  //baseRecipes: [string];
  //baseIngredients: [string];
  //allergens: string[];
  //imageUrl: string;
}

export type Recipe = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    coverId?: string
    ingredientsId: string[]
    allergens: number
    system?: boolean
    restaurantId?: string
    parentId?: string
    likes?: number
    labels?: string[]
    // transient
    parent?: Recipe
    ingredients?: Ingredient[]
    cover?: Media
    /**
     * A key value object which maps an ingredient id to its (optional) brand.
     * Each key must be an id included in the ingredientsId array.
     */
    ingredientBrandMapping?: { [key: string]: string }
    available?: boolean
  }

export enum RestaurantStatuses {
  EMAIL_PENDING = 'emailpending',
  DRAFT = 'draft',
  ACTIVE = 'active',
  BANNED = 'banned',
}

export const specialDishesKeys = ['favouriteDishes', 'featuredDishes', 'mustTryDishes', 'trendingDishes']

export type DishListItemType = 'dish' | 'header' | 'footer' | 'categoryDescription'

export interface TextItem {
  type: 'title' | 'description'
  text: string
  imageUrl?: string
}

export enum DishOrderStatus {
  ORDERING = 'ORDERING',
  WAITING = 'WAITING',
  ARRIVED = 'ARRIVED',
  HISTORY = 'HISTORY',
}

export type DishOrder = {
  quantity: number
  state: DishOrderStatus
  dishId: string // non togliere questa ridondanza perchè serve
  // order: number
}

export type Cart = { [dishId: string]: DishOrder[] }

export type DishCategoryMap = { [dishCatKey: string]: DishCategory }
export type CategoryNodeMap = { [dishCatKey: string]: GroupedDishes }

export type IngredientsMap = { [ingredientId: string]: Ingredient }

export interface GroupedDishes {
  [categoryKey: string]: Dish[]
}

export interface SpecialDishesSectionData {
  title: string
  data: Dish[]
}

export interface SectionHeader {
  categoryKey: string
  hasDescription: boolean
  descriptionImageUrl?: string
}

export type MenuPreview = Partial<Menu>

export type Menu = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    restaurantId: string
    showInPriceList: boolean
    userCanOrder: boolean
    allYouCanEat?: boolean
    highlight?: boolean
    price?: number
    allDay: boolean
    timeSlices?: TimeSlice[]
    status?: MenuStatuses
    dishes: Dish[]
    akinatorEnabled: boolean
    dishCategories: DishCategoryMap
    ingredients: { [ingredientId: string]: Ingredient }
    unavailableRecipeIds: string[]
    recipeLikesMap: { [recipeId: string]: number }
  }

export enum MenuStatuses {
  DRAFT = 'draft',
  ACTIVE = 'active',
  IDLE = 'idle',
}

export type TimeSlice = {
  fromHour: number
  toHour: number
}

export type Intolerance = TranslatableName & {
  value: number
  imageUri: any
}

export type Dish = Recipe &
  Partial<TranslatableCaption> & {
    //restaurantId?: string;
    dishId: string
    recipeId: string
    price?: number
    availability: number
    maxPerUser?: number
    defaultSort: number
    dishCategory: string //key
    dishSubCategory?: string //key
    number?: string
  }

export type RecipeSuggestion = {
  recipe: {
    recipeId: string
    allergens: number
  }
  weight: number
}

export type DishCategory = Partial<TranslatableName> &
  Partial<TranslatableDescription> & {
    _id: string
    restaurantId?: string // mandatory if "system === false"
    key: string
    imageUrl?: string
    system: boolean
  }

export type Ingredient = Partial<TranslatableName> & {
  _id: string
  cover: Media
  allergens: number
}

export type Category = Partial<TranslatableName> & {
  _id: string
}

export type Media = {
  _id: string
  url: string
  mediakey?: string
  name?: string
  mimetype?: string
  entity?: string
  entityId?: string
}

export class Currency {
  price: number
  priceCurrency: string
  constructor(price: number, priceCurrency: string) {
    this.price = price
    this.priceCurrency = priceCurrency
  }
}

export type ViewSize = {
  width: number
  height: number
}

export interface GetMenuIngredientsResponse {
  [ingredientId: string]: Ingredient
}

export enum ApplicationMode {
  checkInRestaurant = 'checkInRestaurant',
  checkInPriceList = 'checkInPriceList',
}

export interface RefreshTokenResponse {
  accessToken: string
  refreshToken: string
}
export interface DishWithIngredients {
  ingredients: { name: string; id: string }[]
  id: string
}

export interface AkinatorDish {
  _id: string
  ingredients: { _id: string }[]
}

export interface Blacklist {
  [ingredientId: string]: boolean
}

export interface IngredientWithCount {
  count: number
  _id: string
}

export interface IngredientObject {
  [ingredientId: string]: IngredientWithCount
}

export type AppMode = 'PRICE_LIST' | 'USER' | 'RECAP'

export interface AkinatorSessionStep {
  _id: string
  question?: AkinatorQuestion
  recipeIds?: string[]
  status: AkinatorSessionStatus
}

export enum AkinatorSessionStatus {
  Progress = 'progress',
  Completed = 'completed',
  Aborted = 'aborted',
  Failed = 'failed',
}

export interface AkinatorQuestion {
  _id: string
  questionIt: string
  questionEn: string
  answers: AkinatorAnswer[]
  type: 'label' | 'ingredient'
}

export interface AkinatorAnswer {
  _id: string
  answerIt: string
  answerEn: string
}
