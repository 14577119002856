import i18next from 'i18next'
import { useEffect, useState } from 'react'
import { hasError } from '../api/HTTPClient'
import { DishCategoryService } from '../api/user/DishCategoryService'
import { LinkService } from '../api/user/LinkService'
import { MenuService } from '../api/user/MenuService'
import { RestaurantService } from '../api/user/RestaurantService'
import { CART_EXPIRED_INTERVAL } from '../components/core/AppLoader'
import { MenuStore } from '../store/menuStore'
import { AppFeature, ApplicationMode, AppMode, MeaError, Menu, RecipeSuggestion } from '../types'
import { meaErrorHandler } from './MeaErrorHandler'

export const getCodeFromUrl = (url: string | null): string | undefined => {
  if (!url || !url.includes('/code/')) return undefined
  return url.substring(url.indexOf('/code/') + 6) ?? ''
}

async function decodeQRCode(code: string): Promise<{ checkInMode: string; restaurantId: string }> {
  const linkMatcher = new RegExp('^https://app.meamenu.com/(checkIn.+)/([0-9a-fA-F]{24})/?$')
  const { url } = await LinkService.getRedirectLink(code)
  const checkInMode = linkMatcher.exec(url)?.[1]
  const restaurantId = linkMatcher.exec(url)?.[2]

  if (!checkInMode || !restaurantId) throw new MeaError('invalidRedirectLink', i18next.t('errors.invalidRedirectLink'))
  return { checkInMode, restaurantId }
}

export const setupAppFromCode: (code: string) => Promise<{
  appMode: AppMode
  appFeatures: number
  restaurantId: string
}> = async code => {
  let appMode: AppMode = 'PRICE_LIST' // lowest default user permissions
  let appFeaturesList: AppFeature[] = [] // lowest default user permissions
  const { checkInMode, restaurantId } = await decodeQRCode(code)

  switch (checkInMode) {
    case ApplicationMode.checkInRestaurant:
      appMode = 'USER'
      appFeaturesList = [AppFeature.LIKE_RECIPE, AppFeature.EDIT_INTOLERANCES]
      break
    case ApplicationMode.checkInPriceList:
      appFeaturesList = []
      break
  }

  return { appMode, appFeatures: appFeaturesList.reduce((acc, curr) => acc + curr, 0), restaurantId }
}

export const fetchAndSetNewSessionData = async (
  menu: Partial<Menu>,
  menuStore: Partial<MenuStore>,
  onBeforeEnd?: () => void,
  onAfterEnd?: () => void
) => {
  const menuId = menu._id!
  try {
    const [dishes, dishCategories, ingredients] = await Promise.all([
      await MenuService.getMenuDishes(menuId),
      await DishCategoryService.getByMenuId(menuId),
      await MenuService.getMenuIngredients(menuId),
    ])
    menuStore.setMenu?.({ ...(menu as Menu), dishes, dishCategories, ingredients })

    const menuRecipes = await MenuService.getMenuRecipes(menuId)
    const unavailableRecipeIds = menuRecipes.filter(recipe => !recipe.available).map(recipe => recipe.id)
    menuStore.setUnavailableRecipeIds?.(unavailableRecipeIds)
    menuStore.setRecipeLikesMap?.(
      menuRecipes.reduce((acc: { [recipeId: string]: number }, current) => {
        acc[current.id] = current.likes
        return acc
      }, {})
    )
    onBeforeEnd && onBeforeEnd()
  } catch (e) {
    meaErrorHandler(e, 'FETCH')
  } finally {
    onAfterEnd && onAfterEnd()
  }
}

export const fetchAndSetSpecialDishesData = async (
  menuId: string,
  allergiesValue: number,
  setForYouRecipes: (recipeSuggestions: RecipeSuggestion[]) => void,
  setFeaturedDishes: (dishesIds: string[]) => void,
  setTrendingRecipes: (recipesIds: string[]) => void
): Promise<void> => {
  // Fault-tolerant requests
  const forYouRequests = [
    MenuService.getForYouRecipes(menuId, allergiesValue),
    MenuService.getFeaturedDishes(menuId, allergiesValue),
    RestaurantService.getTrendingRecipes(menuId!, allergiesValue),
  ]
  const forYouSetters = [
    (forYouRecipesSuggestions: RecipeSuggestion[]) => {
      setForYouRecipes(forYouRecipesSuggestions)
    },
    (featuredDishesId: string[]) => {
      setFeaturedDishes(featuredDishesId)
    },
    (trendingRecipesId: string[]) => {
      setTrendingRecipes(trendingRecipesId)
    },
  ]
  const forYouResults = await Promise.all(forYouRequests.map(p => p.catch(e => e)))
  for (let i = 0; i < forYouResults.length; i++) {
    const setterProp = !hasError(forYouResults[i]) ? forYouResults[i] : []
    forYouSetters[i](setterProp)
  }
}

export const cartIsExpired = (cartLastUpdateSeconds: number) =>
  Math.trunc(new Date().getTime() / 1000) - cartLastUpdateSeconds >= CART_EXPIRED_INTERVAL

export function useDelayer<T>(delay: number, onItemMatured: (item: T) => void) {
  const [lastItem, setLastItem] = useState<T>()
  const [dispatcherTimeout, setDispatcherTimeout] = useState<NodeJS.Timer>()

  useEffect(() => {
    if (!lastItem) return
    if (dispatcherTimeout) clearInterval(dispatcherTimeout)

    // this will propagate the last item after the delay
    const interval = setTimeout(() => {
      onItemMatured(lastItem)
      setLastItem(undefined)
    }, delay)
    setDispatcherTimeout(interval)
    return () => {
      if (dispatcherTimeout) clearTimeout(dispatcherTimeout)
    }
  }, [lastItem])

  return {
    addItemWithDelay: (item: T) => {
      setLastItem(item)
      clearInterval(dispatcherTimeout)
    },
  }
}

export const filterOutSubcategories = (allCategories: string[]) => {
  const result: string[] = []
  allCategories.forEach(cat => {
    const root = cat.split('.')[0]
    if (!result.includes(root)) {
      result.push(root)
    }
  })
  return result
}

export const retrieveSubcategories = (allCategories: string[], categoryKey: string) => {
  const result: string[] = []
  allCategories.forEach(cat => {
    const root = cat.split('.')[0]
    if (root === categoryKey) {
      result.push(cat)
    }
  })
  return result
}
