import { t } from 'i18next'
import { MeaError, User } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

interface CreateUserResponse {
  userId: string
  accessToken: string
}

interface UpdateIntolerancesResponse {
  allergens: number
}

export class UserService extends BaseUserService() {
  static entityPath: string = 'user'

  static async createFromUuid(webAppUuid: string, userToken: string): Promise<CreateUserResponse> {
    try {
      const response = await getBackendClient().post<CreateUserResponse>(`${this.entityPath}/createUserFromUuid`, {
        webAppUuid,
        userToken,
      })
      if (noResponse(response) || !response.data.accessToken || !response.data.userId) {
        throw new MeaError('cannotCreateUser', t('errors.cannotCreateUser'), true)
      }
      return response.data
    } catch (error) {
      throw new MeaError('cannotCreateUser', t('errors.cannotCreateUser'), true)
    }
  }

  static async getCurrent(): Promise<User> {
    try {
      const response = await getBackendClient().get<User>(this.getPath())
      if (noResponse(response)) {
        throw new MeaError('cannotFindUser', t('errors.cannotFindUser'), true)
      }
      return response.data
    } catch (error) {
      throw new MeaError('cannotFindUser', t('errors.cannotFindUser'), true)
    }
  }

  static async likeRecipe(recipeId: string, userId: string): Promise<void> {
    const response = await getBackendClient().post(`${this.getPath()}/recipe/like/${recipeId}`, {})
    if (noResponse(response)) {
      throw new MeaError('cannotSetLikeToRecipe', t('errors.cannotSetLikeToRecipe'))
    }
    return response.data
  }

  static async dislikeRecipe(recipeId: string, userId: string): Promise<void> {
    const response = await getBackendClient().post(`${this.getPath()}/recipe/unlike/${recipeId}`, {
      data: {
        recipeId,
        userId,
      },
    })
    if (noResponse(response)) {
      throw new MeaError('cannotSetDislikeToRecipe', t('errors.cannotSetDislikeToRecipe'))
    }
    return response.data
  }

  static async updateIntolerances(allergenValue: number): Promise<UpdateIntolerancesResponse> {
    const response = await getBackendClient().post<UpdateIntolerancesResponse>(`${this.getPath()}/intolerances`, {
      allergenValue,
    })
    if (noResponse(response)) {
      throw new MeaError('errorSettingIntolerances', t('errors.errorSettingIntolerances'))
    }
    return response.data
  }
}
