import { useTw } from '@mea-menu/components'
import { StatusBar, StyleProp, View, ViewStyle } from 'react-native'
import { SCREEN_AVAILABLE_HEIGHT } from '../../theme/sizes'
import { TopBar } from '../TopBar'

export interface ScreenProps {
  statusBackgroundColor?: string
  noTopbar?: boolean
  topBarChildren?: React.ReactNode
  noRestaurantName?: boolean
  padded?: boolean
  children: React.ReactNode
  noSettings?: boolean
  style?: StyleProp<ViewStyle>
  topBarStyle?: StyleProp<ViewStyle>
}

export function Screen({
  children,
  statusBackgroundColor,
  style,
  noTopbar,
  noRestaurantName,
  padded,
  topBarChildren,
  topBarStyle,
  noSettings,
}: ScreenProps) {
  const statusBg = statusBackgroundColor ? statusBackgroundColor : 'transparent'
  const { tw } = useTw()

  return (
    <View style={[tw`flex-1 fillBackground`, { height: SCREEN_AVAILABLE_HEIGHT }, style]}>
      <StatusBar backgroundColor={statusBg} barStyle={'dark-content'} translucent animated />
      <View style={[tw`flex-1 ${padded ? 'px-xs' : ''}`]}>
        {!noTopbar && (
          <TopBar
            style={topBarStyle}
            noRestaurantName={noRestaurantName}
            children={topBarChildren}
            noSettings={noSettings}
          />
        )}
        {children}
      </View>
    </View>
  )
}
