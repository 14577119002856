import { Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useIsFocused } from '@react-navigation/native'
import { animated, useSpring } from '@react-spring/web'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Image, SectionList, Text, TouchableOpacity, View } from 'react-native'
import BigList from 'react-native-big-list'
import { Images } from '../../assets'
import { ActionType, OldAkinatorActionPayloadAction, PublicService } from '../api/user/PublicService'
import { AkinatorCard, CategoryName, IntolerancesAskCard, MenuDishItem, Screen, SearchBar } from '../components'
import { CategoryScroller } from '../components/CategoryScroller'
import { ZoomableImage } from '../components/core/ZoomableImage'
import { MENU_DISH_ITEM_HEIGHT } from '../components/dish/MenuDishItem'
import { AkinatorModal } from '../components/modals/AkinatorModal'
import { useMenu } from '../hooks/useMenuHook'
import { RootStackScreenProps } from '../navigation'
import { useAppConfigStore, useMenuStore, useNotificationsStore, useSessionStore, useUserStore } from '../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'
import { MeaNotificationType } from '../types'
import { translateLocal } from '../utils'
import { filterOutSubcategories } from '../utils/commonHelpers'

export const DISH_MARGIN_BOTTOM = 16

const CategoryDescription = ({ categoryKey, imageUrl }: { categoryKey: string | undefined; imageUrl?: string }) => {
  const { i18n, t } = useTranslation()
  const { tw } = useTw()
  const { dishCategories } = useMenuStore()
  const { setNotification } = useNotificationsStore()

  if (imageUrl) return <ZoomableImage uri={imageUrl} />
  if (!categoryKey || !dishCategories) return null
  return (
    <TouchableOpacity
      style={tw`flex-row items-baseline`}
      onPress={() => {
        setNotification({
          type: MeaNotificationType.NO_BUTTONS,
          title: translateLocal(dishCategories[categoryKey], 'name', i18n.language),
          description: translateLocal(dishCategories[categoryKey], 'description', i18n.language),
        })
      }}
    >
      <Text style={tw`label textMono max-w-[150px] mr-xs`} numberOfLines={1}>
        {t('l.infos')}
      </Text>
      <Text style={tw`title2`}>ℹ️</Text>
    </TouchableOpacity>
  )
}

export function MenuScreen({ navigation }: RootStackScreenProps<'MenuScreen'>) {
  const { tw } = useTw()
  const isFocused = useIsFocused()
  const { t, i18n } = useTranslation()
  const [filter, setFilter] = useState('')
  const { groupedDishes, filteredDishes, groupedSpecialDishes, sectionHeadersData, sectionSpecialDishesHeadersData } =
    useMenu(filter)
  const [requestedDetailScreen, setRequestedDetailScreen] = useState<string>()
  const [akinatorModalIsOpen, setAkinatorModalOpen] = useState<boolean>(false)
  const [forYouModeOpen, setForYouModeOpen] = useState<boolean>(false)
  const { appMode } = useAppConfigStore()
  const { akinatorEnabled, ingredients, dishCategories, dishes } = useMenuStore()
  const { intolerancesAsked, setIntolerancesAsked } = useUserStore()
  const { setMenuScreenActive } = useSessionStore()

  // local state to restore scroll position and category after navigating to a dish detail screen
  const [offsetToRestore, setOffsetToRestore] = useState<number>()
  const [categoryKeyToRestore, setcategoryKeyToRestore] = useState<string>()
  const [hasRestoreToApply, setHasRestoreToApply] = useState<boolean>(false)

  const dishList = useRef<BigList>(null)
  if (!dishes || !dishCategories || !ingredients) return null

  const categoryKeys = useMemo(
    () =>
      filterOutSubcategories(
        appMode === 'USER'
          ? ['forYou', ...Object.keys(forYouModeOpen ? groupedDishes : filteredDishes)]
          : Object.keys(filteredDishes)
      ),
    [filteredDishes, forYouModeOpen, appMode]
  )

  const categoryAndSubcategoryKeys = useMemo(
    () =>
      appMode === 'USER'
        ? ['forYou', ...Object.keys(forYouModeOpen ? groupedDishes : filteredDishes)]
        : Object.keys(filteredDishes),
    [filteredDishes, forYouModeOpen, appMode]
  )

  const [selectedCategoryKey, setSelectedCategoryKey] = useState<string>(
    appMode === 'USER' ? categoryKeys[1] : categoryKeys[0]
  )

  const [fadeSprings, fadeApi] = useSpring(() => ({
    from: { opacity: 0 },
  }))

  const navigateToDetailScreen = (dishId: string) => {
    setcategoryKeyToRestore(selectedCategoryKey)
    setHasRestoreToApply(true)
    setOffsetToRestore((dishList.current as any)?.scrollTop ?? 0)
    navigation.push('DishDetailScreen', {
      mode: forYouModeOpen ? 'forYou' : 'menu',
      dishDetailId: dishId,
      filter: filter,
    })
  }

  useEffect(() => {
    if (!isFocused || !dishList || !hasRestoreToApply || !categoryKeyToRestore || !offsetToRestore) return
    setTimeout(() => {
      dishList.current?.scrollToOffset({ offset: offsetToRestore, animated: false })
      setSelectedCategoryKey(categoryKeyToRestore)
      setHasRestoreToApply(false)
    }, 200)
  }, [isFocused, dishList, selectedCategoryKey, hasRestoreToApply, selectedCategoryKey, offsetToRestore])

  useEffect(() => {
    fadeApi.start({
      from: {
        opacity: !!requestedDetailScreen ? 1 : 0,
      },
      to: {
        opacity: !!requestedDetailScreen ? 0 : 1,
      },
      delay: 200,
      config: { duration: !!requestedDetailScreen ? 200 : 600 },
      onResolve: () => {
        if (!requestedDetailScreen) return

        navigateToDetailScreen(requestedDetailScreen)
      },
    })
  }, [requestedDetailScreen])

  useEffect(() => {
    setMenuScreenActive(isFocused)
    if (!isFocused) return
    setRequestedDetailScreen(undefined)
    return () => {
      setMenuScreenActive(false)
    }
  }, [isFocused])

  const bigListSections = useMemo(() => Object.values(filteredDishes), [filteredDishes])
  return (
    <Screen>
      <animated.div style={fadeSprings}>
        <View style={tw`absolute fillBackground z-1000 w-full pb-sm`}>
          <SearchBar
            visible={forYouModeOpen}
            value={filter}
            onTextChange={setFilter}
            onSumbit={() => dishList.current?.scrollTo({ y: 2, animated: false })}
          />
          <CategoryScroller
            hideHamburgerMenu={!!filter}
            currentCategoryKey={selectedCategoryKey}
            categoryKeys={categoryAndSubcategoryKeys}
            onCategoryPress={(categoryKey, showAnimation) => {
              if (categoryKey === 'forYou') {
                setForYouModeOpen(true)
                setFilter('')
              } else {
                setForYouModeOpen(false)
                const categoryIndex = categoryAndSubcategoryKeys.indexOf(categoryKey)
                dishList.current?.scrollToSection({
                  section: categoryIndex - (appMode === 'USER' ? 1 : 0),
                  animated: showAnimation,
                })
              }
            }}
          />
        </View>
        <View style={[tw`${forYouModeOpen ? '' : 'mt-md'} mx-sm h-[${SCREEN_AVAILABLE_HEIGHT - 12}px]`]}>
          {/* FOR YOU PAGE */}
          {forYouModeOpen && (
            <SectionList
              style={tw`mt-[60px]`}
              showsVerticalScrollIndicator={false}
              sections={groupedSpecialDishes}
              data={Object.values(groupedSpecialDishes)}
              keyExtractor={item => item.dishId}
              renderItem={({ item: dish }) => (
                <MenuDishItem dish={dish} onPress={() => setRequestedDetailScreen(dish.dishId)} />
              )}
              renderSectionHeader={({ section }) => {
                return (
                  <View style={tw`h-[50px] flex-row justify-between items-end fillBackground pl-xs pt-md`}>
                    <CategoryName categoryKey={section.title} catSubcatSeparator="dash" />
                  </View>
                )
              }}
              ListFooterComponent={
                <View style={tw`flex-row mb-[40px] mt-md flex-1 justify-center`}>
                  {forYouModeOpen && (
                    <Button
                      label={t('l.backToMenu')}
                      variant={ButtonVariant.Primary}
                      onPress={() => {
                        setFilter('')
                        setForYouModeOpen(false)
                        setSelectedCategoryKey(categoryKeys[1])
                        dishList.current?.scrollToTop({ animated: false })
                      }}
                    />
                  )}
                </View>
              }
              ListEmptyComponent={() => (
                <View style={tw`my-md`}>
                  <Image
                    source={Images.illustration_waiter}
                    resizeMode={'contain'}
                    style={tw`w-full h-[200px] my-md`}
                  />
                  <Text style={tw`textMono text-center`}>
                    {forYouModeOpen ? t('l.theresNothingHereForYou') : t('l.theresNothingHere')}
                  </Text>
                </View>
              )}
              ListHeaderComponent={
                <View>
                  {appMode === 'USER' && !intolerancesAsked && <IntolerancesAskCard />}
                  {akinatorEnabled && (
                    <AkinatorCard
                      onButtonPressed={() => {
                        PublicService.logAction(ActionType.OldAkinator, {
                          action: OldAkinatorActionPayloadAction.Start,
                        })
                        setAkinatorModalOpen(true)
                      }}
                    />
                  )}
                </View>
              }
            />
          )}

          {/* NORMAL MENU PAGE */}
          {!forYouModeOpen && (
            <BigList
              style={tw`mt-[90px]`}
              ref={dishList}
              sections={bigListSections}
              keyExtractor={item => item.dishId}
              renderItem={({ item: dish }) => (
                <MenuDishItem dish={dish} onPress={() => setRequestedDetailScreen(dish.dishId)} />
              )}
              stickySectionHeadersEnabled={false}
              sectionHeaderHeight={50}
              renderSectionHeader={section => {
                if (section >= sectionHeadersData.length) return null
                return (
                  <View style={tw`h-[50px] w-full flex-row justify-between items-end`}>
                    <CategoryName categoryKey={sectionHeadersData[section].categoryKey} catSubcatSeparator="dash" />
                    {sectionHeadersData[section].hasDescription && (
                      <CategoryDescription
                        categoryKey={sectionHeadersData[section].categoryKey}
                        imageUrl={sectionHeadersData[section].descriptionImageUrl}
                      />
                    )}
                  </View>
                )
              }}
              renderHeader={() => <View />}
              headerHeight={0}
              renderFooter={() => <View />}
              footerHeight={50}
              showsVerticalScrollIndicator={false}
              itemHeight={MENU_DISH_ITEM_HEIGHT}
              onViewableItemsChanged={({ viewableItems }) => {
                if (viewableItems.length === 0 || !isFocused) return
                const newCurrentFocusedCategory =
                  categoryAndSubcategoryKeys[viewableItems[0].section + (appMode === 'USER' ? 1 : 0)]
                if (selectedCategoryKey === newCurrentFocusedCategory) return
                setSelectedCategoryKey(newCurrentFocusedCategory)
              }}
              onEndReached={() => {
                if (forYouModeOpen) return
                setSelectedCategoryKey(categoryKeys[categoryKeys.length - 1])
              }}
            />
          )}
        </View>

        <AkinatorModal
          visible={akinatorModalIsOpen}
          onDishPressed={() => setAkinatorModalOpen(false)}
          onClose={() => {
            PublicService.logAction(ActionType.OldAkinator, { action: OldAkinatorActionPayloadAction.Cancel })
            setAkinatorModalOpen(false)
          }}
        />
      </animated.div>
    </Screen>
  )
}
