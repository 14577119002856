import { Icon, useTw } from '@mea-menu/components'
import { useNavigation, useRoute } from '@react-navigation/native'
import { useMemo, useState } from 'react'
import { Platform, SafeAreaView, StatusBar, StyleProp, Text, TouchableOpacity, View, ViewStyle } from 'react-native'
import { useAppConfigStore, useCartStore, useRestaurantStore } from '../store'
import { AppFeature, DishOrderStatus } from '../types'
import { appFeatureIsAvailable } from '../utils'
import { Clickable, SettingsModal } from './'
const STATUSBAR_HEIGHT = StatusBar.currentHeight

const MyStatusBar = ({ backgroundColor, hidden, ...props }: { backgroundColor: string; hidden: boolean }) => {
  if (hidden) {
    return <StatusBar hidden />
  }
  return (
    <View style={[{ backgroundColor, height: STATUSBAR_HEIGHT }]}>
      <SafeAreaView>
        <StatusBar translucent backgroundColor={backgroundColor} {...props} />
      </SafeAreaView>
    </View>
  )
}

const OrderingBadge = () => {
  const { tw } = useTw()
  const { currentCart } = useCartStore()
  const orderingQty = useMemo(() => {
    let n = 0
    Object.values(currentCart)
      .flatMap(o => o)
      .filter(o => o.state === DishOrderStatus.ORDERING)
      .forEach(o => (n += o.quantity))
    return n
  }, [currentCart])

  if (orderingQty === 0) return null
  return (
    <View
      style={tw`absolute p-[2px] min-w-[18px] h-[18px] items-center justify-center -top-[10px] -right-[10px] rounded-lg fillPrimary`}
    >
      <Text style={tw`textWhite font-bold`}>{orderingQty}</Text>
    </View>
  )
}

export interface TopBarProps {
  title?: string
  style?: StyleProp<ViewStyle>
  noRestaurantName?: boolean
  noSettings?: boolean
  children?: React.ReactNode
}

export function TopBar({ noRestaurantName, noSettings, children, style, title }: TopBarProps) {
  let hasNotch = false
  {
    /*DeviceInfo.hasNotch()*/
  }
  if (Platform.OS === 'android') {
    hasNotch = (StatusBar?.currentHeight ?? 0) > 24
  }

  const { appFeatures } = useAppConfigStore()
  const route = useRoute()
  const { tw } = useTw()

  const { name: restaurantName } = useRestaurantStore()
  const { navigate } = useNavigation()
  const [settingsModalVisible, setsettingsModalVisible] = useState(false)
  const _bg = 'bg-whiteBg'

  return (
    <View style={style}>
      <MyStatusBar backgroundColor={_bg} hidden={false} />
      <View style={tw`items-center flex-row justify-end px-md py-xs`}>
        {!noRestaurantName && (
          <Text style={tw`flex-1 textMono title`} numberOfLines={2}>
            {restaurantName}
          </Text>
        )}
        {children}

        {!noSettings && (
          <Clickable
            id="open_settings"
            style={tw`py-xs flex-row rounded-md`}
            onPress={() => setsettingsModalVisible(true)}
          >
            <Icon name="Settings" />
          </Clickable>
        )}

        {route.name === 'MenuScreen' && appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && (
          <TouchableOpacity style={tw`mt-[10px] mb-[6px] pl-sm items-center`} onPress={() => navigate('OrderScreen')}>
            <Icon name="Cloche" />
            <OrderingBadge />
          </TouchableOpacity>
        )}

        <SettingsModal onClose={() => setsettingsModalVisible(false)} visible={settingsModalVisible} />
      </View>
    </View>
  )
}
