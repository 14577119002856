import { ButtonSize, ButtonState, ButtonVariant, IconButton, useTw } from '@mea-menu/components'
import { BlurView } from 'expo-blur'
import { Modal, Text, View } from 'react-native'
import { Clickable } from '..'
import { SCREEN_AVAILABLE_HEIGHT, SCREEN_AVAILABLE_WIDTH } from '../../theme/sizes'

export interface BaseModalProps {
  visible: boolean
  fullScreen?: boolean
  onClose: () => void
  title: string
  animationType?: 'none' | 'fade' | 'slide'
  fromTop?: boolean
  children?: React.ReactNode
}
export function BaseModal({ visible, fullScreen, onClose, children, title, animationType }: BaseModalProps) {
  const { tw } = useTw()

  return (
    <Modal animationType={animationType ?? 'slide'} transparent={true} visible={visible}>
      <View style={tw`flex-1 flex-row justify-center`}>
        <View style={tw`flex-1 max-w-[${SCREEN_AVAILABLE_WIDTH}px]`}>
          {!fullScreen && (
            <Clickable id="close_modal" onPress={onClose} activeOpacity={1} style={tw`flex-1`}>
              <BlurView intensity={50} style={tw`w-full flex-1`} />
            </Clickable>
          )}
          <View style={tw`px-lg pt-lg pb-xxl fillBackground border-t-[1px] rounded-t-md`}>
            <View style={tw`flex-row justify-between items-center`}>
              <Text style={tw`title3 textMono`}>{title}</Text>
              <IconButton
                size={ButtonSize.Medium}
                icon="Close"
                variant={ButtonVariant.Default}
                state={ButtonState.Error}
                onPress={onClose}
              />
            </View>
            <View style={tw`${fullScreen ? `h-[${SCREEN_AVAILABLE_HEIGHT - 64}px]` : ''}`}>{children}</View>
          </View>
        </View>
      </View>
    </Modal>
  )
}
