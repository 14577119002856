import { useTw } from '@mea-menu/components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, View } from 'react-native'
import { BaseModal, CategoryScrollerItem } from '.'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'
import { filterOutSubcategories, retrieveSubcategories } from '../utils/commonHelpers'

export interface CategoryModalProps {
  categoryKeys: string[]
  visible: boolean
  selectedCategoryKey?: string
  onClose: () => void
  onCategoryPress: (categoryKey: string) => void
}

export const CategoryModal: React.FC<CategoryModalProps> = ({
  categoryKeys,
  visible,
  selectedCategoryKey,
  onClose,
  onCategoryPress,
}) => {
  const { tw } = useTw()
  const { t } = useTranslation()
  const [displayedCategories, setDisplayedCategories] = useState(filterOutSubcategories(categoryKeys))

  return (
    <BaseModal title={t('l.categories')} visible={visible} onClose={onClose}>
      <View style={tw`flex-row flex-wrap flex-1`}>
        <FlatList
          style={tw`mt-sm flex-1 max-h-[${SCREEN_AVAILABLE_HEIGHT - 100}px]`}
          data={displayedCategories}
          showsVerticalScrollIndicator={false}
          numColumns={2}
          renderItem={({ item }) => (
            <CategoryScrollerItem
              isSelected={item === selectedCategoryKey}
              key={item}
              categoryKey={item}
              expandedView
              onPress={key => {
                const subCategories = retrieveSubcategories(categoryKeys, item)
                if (
                  subCategories.length <= 1 ||
                  (!key.includes('.') && displayedCategories.some(cat => cat.includes('.')))
                ) {
                  onCategoryPress(item)
                  setTimeout(() => {
                    setDisplayedCategories(filterOutSubcategories(categoryKeys))
                  }, 300)
                } else {
                  setDisplayedCategories(subCategories)
                }
              }}
            />
          )}
        />
      </View>
    </BaseModal>
  )
}
