import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import uuid from 'react-native-uuid'
import { Browser as Sentry } from 'sentry-expo'
import { useUserLoginStore } from '../store'
import { UserLoginStore } from '../store/userLoginStore'
import { DomainError, MeaError, NotAuthorizedCode } from '../types'
import { parseJwtToken } from '../utils/jwt'
import { apiConfig } from './apiConfig'
import { UserAuthService } from './user/UserAuthService'
import { UserService } from './user/UserService'

let _backendClient: AxiosInstance
let _authClient: AxiosInstance

const AUTH_EXCLUDED_APIS: string[] = ['user/createUserFromUuid', 'link/redirect']
const TOKEN_RENEWAL_MARGIN_SEC = 300

export const userTokenIsValid = (accessToken: string): boolean => {
  if (!accessToken) return false
  const token = parseJwtToken(accessToken)
  if (!token.exp) return false
  return Math.trunc(new Date().getTime() / 1000) < token.exp - TOKEN_RENEWAL_MARGIN_SEC
}

export const setBackendToken = (token: string) => {
  if (!_backendClient) return
  _backendClient.defaults.headers.common['Authorization'] = token
}

export const getBackendClient = () => {
  if (!_backendClient) {
    _backendClient = axios.create({
      baseURL: apiConfig.backEndUrl,
    })

    _backendClient.interceptors.request.use(async request => {
      if (AUTH_EXCLUDED_APIS.some(url => request.url!.includes(url))) return request
      if (!useUserLoginStore.getState().userUuid) {
        return Promise.reject(`Cannot request ${request.url}. Missing user uuid.`)
      }
      const accessToken = useUserLoginStore.getState().accessToken
      if (!_backendClient.defaults.headers.common['Authorization'] && accessToken) {
        setBackendToken(accessToken)
        request.headers!['Authorization'] = accessToken
      }
      return request
    })

    _backendClient.interceptors.response.use(undefined, async error => {
      if (isAxiosErrorFromDomainWithInternalCode(error, NotAuthorizedCode.INVALID_TOKEN)) {
        // token is not valid - renew token and retry request
        try {
          const { userUuid, setUserLoginData } = useUserLoginStore.getState()
          if (!userUuid) return Promise.reject(`Cannot renew token. Missing user uuid.`)
          const loginInfo = await renewUserToken(userUuid)
          setUserLoginData(loginInfo!)
          setBackendToken(loginInfo!.accessToken)
          error.config.headers!['Authorization'] = loginInfo!.accessToken
          return axios.request(error.config)
        } catch (e) {
          return Promise.reject(`Cannot renew token`)
        }
      }
      return Promise.reject(error)
    })

    axiosRetry(_backendClient, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
  }
  return _backendClient
}

//#region user

export const loginUser = async (userLoginStore: UserLoginStore) => {
  let tokenToUse
  let userIdToUse
  if (userLoginStore.userUuid) {
    //has already an uuid
    if (!userLoginStore.userId || !userLoginStore.accessToken || !userTokenIsValid(userLoginStore.accessToken)) {
      const loginInfo = await UserAuthService.loginWithUuid(userLoginStore.userUuid)
      tokenToUse = loginInfo.accessToken
      userIdToUse = loginInfo.userId
      userLoginStore.setUserLoginData(loginInfo)
    } else {
      // token is valid
      tokenToUse = userLoginStore.accessToken
      userIdToUse = userLoginStore.userId
    }
  } else {
    //create uuid
    const newUserUuid = uuid.v4() as string
    userLoginStore.setUuid(newUserUuid)
    const draftTokenResponse = await UserAuthService.getDraftUserToken(newUserUuid)
    const loginInfo = await UserService.createFromUuid(newUserUuid, draftTokenResponse.draftToken)
    tokenToUse = loginInfo.accessToken
    userIdToUse = loginInfo.userId
    userLoginStore.setUserLoginData(loginInfo)
  }
  Sentry.setUser({ id: userIdToUse })
  setBackendToken(tokenToUse)
}

const renewUserToken = async (userUuid: string) => {
  if (userUuid && userUuid.length === 36) {
    const loginInfo = await UserAuthService.loginWithUuid(userUuid)
    return loginInfo
  }
}

//#endregion user

//#region auth

export const getAuthClient = () => {
  if (!_authClient) {
    _authClient = axios.create({
      baseURL: apiConfig.authUrl,
    })

    axiosRetry(_authClient, { retries: 3, retryDelay: axiosRetry.exponentialDelay })
  }
  return _authClient
}

//#endregion

export const isAxiosErrorFromDomainWithInternalCode = (error: any, internalCode: number): boolean => {
  return (
    axios.isAxiosError(error) &&
    !!error.response &&
    !!(error.response.data as DomainError) &&
    (error.response.data as DomainError).internalCode === internalCode
  )
}

export const noResponse = (response: AxiosResponse) => {
  return response === undefined || response.data === undefined
}

export const hasError = (result: any) =>
  result instanceof Error || result instanceof AxiosError || result instanceof DomainError || result instanceof MeaError
