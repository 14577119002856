import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { MeaDishVariantsNotification, MeaNotification } from '../types'

interface NotificationsState {
  notification?: MeaNotification | MeaDishVariantsNotification
}

interface NotificationsStore extends NotificationsState {
  setNotification: (notification: MeaNotification | MeaDishVariantsNotification) => void
  consumeNotification: () => void
}

const initialState: NotificationsState = {}

export const useNotificationsStore = create<NotificationsStore>()(
  immer<NotificationsStore>((set, _) => ({
    ...initialState,
    setNotification: (notification: MeaNotification | MeaDishVariantsNotification) =>
      set(store => {
        store.notification = notification
      }),
    consumeNotification: () =>
      set(
        store => ({
          ...initialState,
          setNotification: store.setNotification,
          consumeNotification: store.consumeNotification,
        }),
        true
      ),
  }))
)
