import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAppConfigStore,
  useCartStore,
  useMenuStore,
  useNotificationsStore,
  useRestaurantStore,
  useSpecialDishesStore,
  useUserLoginStore,
  useUserStore,
} from '../store'
import { AppFeature, Dish, DishOrderStatus, MeaDishVariantsNotification, MeaNotificationType } from '../types'
import { appFeatureIsAvailable, translateLocal } from '../utils'
import { updateRemoteDishOrderQuantity, updateRemoteRecipeLike } from '../utils/cartHelpers'
import { getDishesFromRecipesId, getDishesFromSuggestions, INTOLERANCES, userIsAllergicTo } from '../utils/dishHelpers'
import { useMenu } from './useMenuHook'

type useDishExp = {
  dishName: string
  dishDescription?: string
  dishCaption?: string
  dishVariants: string[]
  recipeLikesAmount: number
  ingredientNames?: { name: string; allergens: boolean }[]
  dishPrice?: string
  intolerancesNames: { [value: number]: string }
  dishQty: number
  dishIsForYou: boolean
  dishIsFeatured: boolean
  dishIsTrending: boolean
  dishIsAvailable: boolean
  recipeIsLiked: boolean
  userIsAllergicToThisDish: boolean
  toggleDishlike: () => void
  decreaseQuantity: () => void
  increaseQuantity: () => void
  imageUrl?: string
}

export const useDish = (dish: Dish, insideVariantsModal?: boolean): useDishExp => {
  const { _id: restaurantId } = useRestaurantStore()
  const { forYouRecipes, featuredDishesId, trendingRecipesId } = useSpecialDishesStore()
  const user = useUserStore()
  const {
    ingredients,
    _id: menuId,
    dishes: menuDishes,
    unavailableRecipeIds,
    setUnavailableRecipeIds,
    recipeLikesMap,
    increaseRecipeLikes,
    decreaseRecipeLikes,
  } = useMenuStore()
  const { userId } = useUserLoginStore()
  const { appFeatures } = useAppConfigStore()
  const cartStore = useCartStore()
  const { setNotification } = useNotificationsStore()
  const { t, i18n } = useTranslation()

  const { getSuitableDishes } = useMenu()
  const recipeIsLiked = useMemo(() => user.likedRecipesId.includes(dish.recipeId), [user.likedRecipesId, dish])

  const dishName = useMemo(() => translateLocal(dish, 'name', i18n.language), [dish, i18n.language])
  const dishDescription = useMemo(() => translateLocal(dish, 'description', i18n.language), [dish, i18n.language])
  const dishCaption = useMemo(() => translateLocal(dish, 'caption', i18n.language), [dish, i18n.language])
  const recipeLikesAmount = useMemo(() => recipeLikesMap?.[dish.recipeId] ?? 0, [recipeLikesMap])

  const dishPrice = !!dish.price ? dish.price.toFixed(2) + '€' : undefined

  const dishVariants = useMemo(() => {
    return getSuitableDishes(menuDishes ?? []).reduce<string[]>((acc, menuDish) => {
      if (menuDish.dishId === dish.dishId) return acc
      if (menuDish.recipeId !== dish.recipeId) return acc
      if (menuDish.dishCategory !== dish.dishCategory) return acc
      if (menuDish.dishSubCategory !== dish.dishSubCategory) return acc
      acc.push(menuDish.dishId)
      return acc
    }, [])
  }, [dish])

  const dishQty = useMemo(() => {
    const specificDishQty =
      cartStore.currentCart[dish.dishId]?.find(itm => itm.state === DishOrderStatus.ORDERING)?.quantity ?? 0
    if (dishVariants.length === 0 || insideVariantsModal) {
      return specificDishQty
    } else {
      let variantQtySum = 0
      dishVariants.forEach(variantDishId => {
        variantQtySum +=
          cartStore.currentCart[variantDishId]?.find(itm => itm.state === DishOrderStatus.ORDERING)?.quantity ?? 0
      })
      return variantQtySum + specificDishQty
    }
  }, [cartStore.currentCart, dish, dishVariants])

  const userIsInOrderingPhase =
    Object.keys(cartStore.currentCart).length === 0 ||
    Object.values(cartStore.currentCart)
      .flatMap(i => i)
      .some(j => j.state === DishOrderStatus.ORDERING)

  const userIsWaitingForSomeDishes = Object.values(cartStore.currentCart)
    .flatMap(i => i)
    .some(j => j.state === DishOrderStatus.WAITING)

  const dishIsForYou = getDishesFromSuggestions(forYouRecipes, menuDishes ?? [], i18n.language)
    .map(dish => dish.dishId)
    .includes(dish.dishId)

  const dishIsFeatured = featuredDishesId.includes(dish.dishId)

  const dishIsTrending = getDishesFromRecipesId(trendingRecipesId, menuDishes ?? [])
    .map(dish => dish.dishId)
    .includes(dish.dishId)

  const userIsAllergicToThisDish = userIsAllergicTo(user.allergens, dish.allergens)

  /**
   * Returns an array of translated dish ingredients, each one with a flag
   * indicating if that ingredient has any allergens (to
   * show in bold to comply with labeling legislation)
   */
  const ingredientNames: { name: string; allergens: boolean }[] = useMemo(() => {
    if (!ingredients) return []
    return dish.ingredientsId.map(ingrId => {
      const ingredient = ingredients[ingrId]
      if (!ingredient) return { name: '', allergens: false }
      let name = translateLocal(ingredient, 'name', i18n.language)
      const brandName = dish.ingredientBrandMapping?.[ingrId]
      if (brandName) {
        if (brandName.match(new RegExp(name, 'i'))) {
          name = brandName
        }
        name = `${name} ${brandName}`
      }
      return { name: name, allergens: ingredient.allergens > 0 }
    })
  }, [dish, i18n.language])

  const intolerancesNames = INTOLERANCES.reduce<string[]>((acc, intolerance) => {
    const dishHasAllergens = ((dish.allergens ?? 0) & intolerance.value) !== 0
    const userIsAllergic = userIsAllergicTo(user.allergens, intolerance.value)
    if (dishHasAllergens && userIsAllergic) acc.push(translateLocal(intolerance, 'name', i18n.language))
    return acc
  }, [])

  {
    /*ordering phases*/
  }

  {
    /*1*/
  }
  const AddDish_CheckIfOrderingPending = () => {
    if (!userIsInOrderingPhase && userIsWaitingForSomeDishes) {
      setNotification({
        title: t('l.warning'),
        description: t('l.newOrderButSomeDishesWaitingConfirm'),
        type: MeaNotificationType.CONFIRM,
        confirmText: t('l.yes'),
        cancelText: t('l.no'),
        onConfirm: () => {
          cartStore.moveCartInHistory()
          AddDish_CheckIfReachedMaxPerUser()
        },
      })
    } else {
      AddDish_CheckIfReachedMaxPerUser()
    }
  }

  {
    /* 2*/
  }
  const AddDish_CheckIfReachedMaxPerUser = () => {
    if (dish.maxPerUser && Object.keys(cartStore.currentCart).includes(dish.dishId)) {
      let dishOrderedQty = 0
      cartStore.currentCart[dish.dishId].forEach(order => {
        dishOrderedQty += order.quantity
      })
      if (dishOrderedQty >= dish.maxPerUser) {
        setNotification({
          title: t('l.warning'),
          description: t('l.reachedMaxPerUser'),
          type: MeaNotificationType.ALERT,
        })
      } else {
        AddDish_CheckIfAllergic()
      }
    } else {
      AddDish_CheckIfAllergic()
    }
  }

  {
    /*3*/
  }
  const AddDish_CheckIfAllergic = () => {
    if (dishQty === 0 && userIsAllergicToThisDish) {
      setNotification({
        title: t('l.warning'),
        description: `${t('l.thisDishContains')} ${intolerancesNames.join(', ')}.\n${t('l.sureToAddAlergicDishQstn')}`,
        type: MeaNotificationType.CONFIRM,
        confirmText: t('l.yes'),
        cancelText: t('l.no'),
        onConfirm: () => AddDish_CheckVariants(),
      })
    } else {
      AddDish_CheckVariants()
    }
  }

  {
    /*4*/
  }
  const AddDish_CheckVariants = () => {
    if (appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && dishVariants.length > 0) {
      setNotification({
        type: MeaNotificationType.DISH_VARIANTS,
        title: t('l.selectVariant'),
        variantsDishIds: [dish.dishId, ...dishVariants],
        onDishSelected: dishId => doActuallyAddDish(dishId),
      } as MeaDishVariantsNotification)
    } else {
      doActuallyAddDish()
    }
  }

  {
    /*5*/
  }
  const doActuallyAddDish = (dishId: string = dish.dishId) => {
    if (restaurantId && cartStore.orderId && menuId) {
      updateRemoteDishOrderQuantity({
        cartStore: cartStore,
        setUnavailableRecipeIds,
        dishId: dishId,
        recipeId: dish.recipeId,
        menuId: menuId,
        restaurantId: restaurantId,
        quantity: dishQty + 1,
        onFail: 'DECREASE_BY_1',
      })
      cartStore.increaseItemOrderingQuantity(dishId)
    }
  }

  const toggleDishlike = useCallback(() => {
    if (recipeIsLiked) {
      updateRemoteRecipeLike({
        action: 'DISLIKE',
        recipeId: dish.recipeId,
        userId: userId,
        userStore: user,
        increaseRecipeLikes,
        decreaseRecipeLikes,
      })
      user.dislikeRecipe(dish.recipeId)
      decreaseRecipeLikes(dish.recipeId)
    } else {
      updateRemoteRecipeLike({
        action: 'LIKE',
        recipeId: dish.recipeId,
        userId: userId,
        userStore: user,
        increaseRecipeLikes,
        decreaseRecipeLikes,
      })
      user.likeRecipe(dish.recipeId)
      increaseRecipeLikes(dish.recipeId)
    }
  }, [userId, recipeIsLiked])

  const increaseQuantity = () => {
    if (insideVariantsModal) {
      doActuallyAddDish()
    } else {
      AddDish_CheckIfOrderingPending()
    }
  }

  const decreaseQuantity = () => {
    if (dishQty === 0) return
    if (insideVariantsModal) {
      doActuallyRemoveDish()
    } else {
      if (appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && dishVariants.length > 0) {
        setNotification({
          type: MeaNotificationType.DISH_VARIANTS,
          title: t('l.selectVariant'),
          variantsDishIds: [dish.dishId, ...dishVariants],
          onDishSelected: dishId => doActuallyRemoveDish(dishId),
        })
      } else {
        doActuallyRemoveDish()
      }
    }
  }

  const doActuallyRemoveDish = (dishId: string = dish.dishId) => {
    if (restaurantId && cartStore.orderId && menuId) {
      updateRemoteDishOrderQuantity({
        cartStore: cartStore,
        setUnavailableRecipeIds,
        dishId: dishId,
        recipeId: dish.recipeId,
        menuId: menuId,
        restaurantId: restaurantId,
        quantity: dishQty - 1,
        onFail: 'INCREASE_BY_1',
      })
      cartStore.decreaseItemOrderingQuantity(dishId)
    }
  }

  const imageUrl = dish.cover?.url ?? undefined

  const dishIsAvailable = useMemo(
    () => !(unavailableRecipeIds?.includes(dish.recipeId) ?? false),
    [unavailableRecipeIds]
  )

  return {
    dishName,
    dishDescription,
    dishCaption,
    dishVariants,
    recipeLikesAmount,
    ingredientNames,
    dishPrice,
    intolerancesNames,
    dishQty,
    dishIsForYou,
    dishIsFeatured,
    dishIsTrending,
    recipeIsLiked,
    dishIsAvailable,
    userIsAllergicToThisDish,
    toggleDishlike,
    increaseQuantity,
    decreaseQuantity,
    imageUrl,
  }
}
