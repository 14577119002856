import { AxiosError } from 'axios'
import { t } from 'i18next'
import { isAxiosErrorFromDomainWithInternalCode } from '../api/HTTPClient'
import { GetUserOrderResponse, OrderService } from '../api/user/OrderService'
import { UserService } from '../api/user/UserService'
import { useMenuStore } from '../store'
import { CartStore } from '../store/cartStore'
import { UserStore } from '../store/userStore'
import { Cart, DishOrderStatus, MeaError, NotAllowedCode } from '../types'
import { unique } from '../types/utils'
import { getCartDishesIdsInStatus, getDishesFromDishesIds } from './dishHelpers'
import { meaErrorHandler } from './MeaErrorHandler'

export async function emptyCart(orderId: string, emptyOrdering: () => void, setLoading?: (loading: boolean) => void) {
  try {
    setLoading && setLoading(true)
    await OrderService.empty(orderId)
    emptyOrdering()
  } catch (error) {
    meaErrorHandler(error, 'UPDATE')
  } finally {
    setLoading && setLoading(false)
  }
}

export interface SubmitOrderProps {
  orderId: string
  currentCart: Cart
  restaurantId: string
  menuId: string
  setOrderId: (orderId: string | undefined) => void
  moveOrderingInWaiting: () => void
  setUnavailableRecipeIds: (recipeIds: string[]) => void
  resumeCartState: (content: { dishId: string; quantity: number }[]) => void
}

export const sendOrder: (props: SubmitOrderProps) => Promise<void> = async ({
  orderId,
  currentCart,
  restaurantId,
  menuId,
  setOrderId,
  moveOrderingInWaiting,
  setUnavailableRecipeIds,
  resumeCartState,
}: SubmitOrderProps) => {
  let updatedOrder: GetUserOrderResponse | undefined = undefined
  try {
    updatedOrder = await OrderService.submit(orderId)
    const newUserOrder = await OrderService.getCurrentByMenuId(restaurantId, menuId)
    setOrderId(newUserOrder._id)
    moveOrderingInWaiting()
  } catch (error) {
    if (isAxiosErrorFromDomainWithInternalCode(error, NotAllowedCode.DISH_NOT_AVAILABLE)) {
      const currentOrderDishIds = getCartDishesIdsInStatus(currentCart, DishOrderStatus.ORDERING)
      updatedOrder = await OrderService.getCurrentByMenuId(restaurantId, menuId)
      const rectifiedOrderDishIds = updatedOrder.dishes.map(dish => dish.dishId)
      const { unavailableRecipeIds, dishes } = useMenuStore.getState()
      const newUnavailableRecipeIds = getDishesFromDishesIds(
        currentOrderDishIds.filter(dishId => !rectifiedOrderDishIds.includes(dishId)),
        dishes ?? []
      )
        .map(dish => dish.recipeId)
        .filter(unique)
      setUnavailableRecipeIds([...(unavailableRecipeIds ?? []), ...newUnavailableRecipeIds])
      setOrderId(updatedOrder._id)
      resumeCartState(updatedOrder.dishes)
      return meaErrorHandler(
        new MeaError('someOrderDishesAreNoMoreAvailable', t('errors.someOrderDishesAreNoMoreAvailable')),
        true
      )
    }
    meaErrorHandler(error, 'UPDATE')
  }
}

export interface UpdateRemoteQuantityProps {
  dishId: string
  recipeId: string
  quantity: number
  restaurantId: string
  menuId: string
  cartStore: CartStore
  setUnavailableRecipeIds: (recipeIds: string[]) => void
  onFail?: 'DECREASE_BY_1' | 'INCREASE_BY_1'
}

const remediateSetDishQty = ({
  onFail,
  cartStore,
  dishId,
}: {
  onFail?: 'DECREASE_BY_1' | 'INCREASE_BY_1'
  cartStore: CartStore
  dishId: string
}) => {
  if (!onFail) return
  switch (onFail) {
    case 'DECREASE_BY_1':
      cartStore.decreaseItemOrderingQuantity(dishId)
      break
    case 'INCREASE_BY_1':
      cartStore.increaseItemOrderingQuantity(dishId)
      break
  }
}

export const updateRemoteDishOrderQuantity: (props: UpdateRemoteQuantityProps) => void = ({
  dishId,
  recipeId,
  quantity,
  restaurantId,
  menuId,
  cartStore,
  setUnavailableRecipeIds,
  onFail,
}: UpdateRemoteQuantityProps) => {
  if (!cartStore.orderId) return
  OrderService.setDishQty(cartStore.orderId, dishId, quantity).catch(async error => {
    if (isAxiosErrorFromDomainWithInternalCode(error, NotAllowedCode.EXPIRED_ORDER)) {
      //order is expired. Re-init cart...
      meaErrorHandler(new MeaError('cartExpiredUsingNewCart'))
      const newUserOrder = await OrderService.getCurrentByMenuId(restaurantId, menuId)
      cartStore.initCart(menuId)
      cartStore.setOrderId(newUserOrder._id)
      cartStore.resumeCartState(newUserOrder.dishes)
      // ...and redo operation a second and last time with a brand new order
      return OrderService.setDishQty(newUserOrder._id, dishId, quantity)
    } else if (isAxiosErrorFromDomainWithInternalCode(error, NotAllowedCode.DISH_NOT_AVAILABLE)) {
      // dish is no more available
      const { unavailableRecipeIds } = useMenuStore.getState()
      setUnavailableRecipeIds([...(unavailableRecipeIds ?? []), recipeId])
      cartStore.resetItemOrderingQuantity(dishId)
      return meaErrorHandler(new MeaError('dishIsNoMoreAvailable', t('errors.dishIsNoMoreAvailable')), true)
    }
    remediateSetDishQty({ onFail, cartStore, dishId })
    meaErrorHandler(error, 'UPDATE')
  })
}

export interface UpdateRemoteRecipeLikeProps {
  action: 'LIKE' | 'DISLIKE'
  recipeId: string
  userId: string
  userStore: UserStore
  increaseRecipeLikes: (recipeId: string, amount?: number) => void
  decreaseRecipeLikes: (recipeId: string, amount?: number) => void
}

export const updateRemoteRecipeLike: (props: UpdateRemoteRecipeLikeProps) => any = ({
  action,
  recipeId,
  userId,
  userStore,
  increaseRecipeLikes,
  decreaseRecipeLikes,
}: UpdateRemoteRecipeLikeProps) => {
  ;(action === 'LIKE' ? UserService.likeRecipe(recipeId, userId) : UserService.dislikeRecipe(recipeId, userId)).catch(
    async error => {
      meaErrorHandler(error, 'UPDATE')
      switch (action) {
        case 'LIKE':
          userStore.dislikeRecipe(recipeId)
          decreaseRecipeLikes(recipeId)
          break
        case 'DISLIKE':
          userStore.likeRecipe(recipeId)
          increaseRecipeLikes(recipeId)
          break
      }
    }
  )
}
