import { Button, ButtonVariant, Icon, IconSize, useTw } from '@mea-menu/components'
import SegmentedControl from '@react-native-segmented-control/segmented-control'
import { useIsFocused } from '@react-navigation/native'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Dimensions, Image, SectionList, Text, View } from 'react-native'
import { Images } from '../../assets'
import { ActionType, OrderActionPayloadAction, PublicService } from '../api/user/PublicService'
import { Clickable, DishOrderItem, LoadingIndicator, Screen } from '../components'
import { BaseModal } from '../components/modals/BaseModal'
import { RootStackScreenProps } from '../navigation'
import {
  useAppConfigStore,
  useCartStore,
  useMenuStore,
  useNotificationsStore,
  useRestaurantStore,
  useUserStore,
} from '../store'
import { Palettes } from '../theme/palette'
import { SCREEN_AVAILABLE_HEIGHT } from '../theme/sizes'
import { DishOrder, DishOrderStatus, MeaNotificationType } from '../types'
import { emptyCart, sendOrder } from '../utils/cartHelpers'

export function OrderScreen({ navigation, route }: RootStackScreenProps<'OrderScreen'>) {
  const { tw, theme } = useTw()
  const { _id: userId, acceptedNotifications, addAcceptedNotification } = useUserStore()
  const { coverPrice, _id: restaurantId } = useRestaurantStore()
  const {
    currentCart,
    orderId,
    setOrderId,
    moveOrderingInWaiting,
    changeDishOrderStatus,
    moveCartInHistory,
    emptyOrdering,
    resumeCartState,
  } = useCartStore()
  const { setNotification } = useNotificationsStore()
  const menu = useMenuStore()
  const { setAppMode } = useAppConfigStore()
  const [showHistory, setshowHistory] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [closeSessionModalVisible, setcloseSessionModalVisible] = useState(false)
  const { i18n, t } = useTranslation()

  const isFocused = useIsFocused()

  useEffect(() => {
    if (!acceptedNotifications?.includes('NO_RTO_INTRO') && Object.keys(currentCart).length > 0 && isFocused)
      setNotification({
        type: MeaNotificationType.ALERT,
        title: t('l.notificationWelcome'),
        imageSource: Images.illustration_waiter,
        description: t('l.noRTOrderDisclaimer'),
        confirmText: t('l.understand'),
        onConfirm: () => addAcceptedNotification('NO_RTO_INTRO'),
        onCancel: () => addAcceptedNotification('NO_RTO_INTRO'),
      })
  }, [currentCart, isFocused, acceptedNotifications])

  const ListItem = useCallback(
    ({ item, section }: { item: DishOrder; section: { title: string } }) => {
      if (!menu.dishes) return null
      const dish = menu.dishes.find(itm => itm.dishId === item.dishId)
      if (!dish) return null
      return (
        <DishOrderItem
          dish={dish}
          quantity={item.quantity}
          canEditQuantity={item.state === DishOrderStatus.ORDERING}
          arrived={item.state === DishOrderStatus.ARRIVED}
          onPress={() => {
            if (item.state === DishOrderStatus.WAITING) {
              changeDishOrderStatus({ order: item, status: DishOrderStatus.ARRIVED })
            }

            if (item.state === DishOrderStatus.ARRIVED) {
              changeDishOrderStatus({ order: item, status: DishOrderStatus.WAITING })
            }
          }}
        />
      )
    },
    [menu]
  )

  const dishesGrouped = useMemo(() => {
    const result: Record<DishOrderStatus, DishOrder[]> = { ORDERING: [], WAITING: [], ARRIVED: [], HISTORY: [] }
    Object.entries(currentCart).forEach(itm => {
      const orders = itm[1]
      orders.forEach(order => {
        result[order.state].push(order as DishOrder)
      })
    })
    const historyDishIds: string[] = [...new Set(result.HISTORY.map(itm => itm.dishId))]
    const amountMap: Record<string, number> = {}
    result.HISTORY.forEach(itm => {
      if (!amountMap[itm.dishId]) {
        amountMap[itm.dishId] = itm.quantity
      } else {
        amountMap[itm.dishId] += itm.quantity
      }
    })

    result.HISTORY = historyDishIds.map(itm => {
      return { dishId: itm, quantity: amountMap[itm], state: DishOrderStatus.HISTORY }
    })

    return result
  }, [menu, currentCart])

  const dishMapPrices = useMemo(() => {
    const dishMap: Record<string, number> = {}
    if (!menu.dishes) return dishMap
    menu.dishes.forEach(dish => (dishMap[dish.dishId] = dish.price ?? 0))
    return dishMap
  }, [menu])

  const cartAmount = useMemo(() => {
    let amount = 0
    amount += dishesGrouped.ORDERING.reduce((prev, curr) => prev + curr.quantity * dishMapPrices[curr.dishId], 0)
    amount += dishesGrouped.WAITING.reduce((prev, curr) => prev + curr.quantity * dishMapPrices[curr.dishId], 0)
    amount += dishesGrouped.ARRIVED.reduce((prev, curr) => prev + curr.quantity * dishMapPrices[curr.dishId], 0)
    amount += coverPrice ?? 0
    return amount
  }, [dishesGrouped])

  const EmptyCart = () => {
    if (showHistory) return null
    return (
      <View>
        <Image source={Images.empty_cart} resizeMode={'contain'} style={tw`w-full h-[200px] mt-[10%] mb-xl`} />
        <Text style={tw`textMono title3 text-center`}>{t('l.emptyCart')}</Text>
        <Text style={tw`textMono my-md px-xl text-center`}>{t('l.emptyCartDescription')}</Text>
      </View>
    )
  }

  const Header = useCallback(() => {
    return (
      <View style={tw`flex-col justify-between px-xs`}>
        {dishesGrouped.HISTORY.length > 0 && (
          <View style={tw`h-[30px] mb-[10px]`}>
            <SegmentedControl
              style={tw`flex-1 rounded-md`}
              values={[t('l.order'), t('l.history')]}
              selectedIndex={showHistory ? 1 : 0}
              fontStyle={tw`text-lg textBlack`}
              activeFontStyle={tw`textWhite`}
              tintColor={Palettes['light'].meaOrange}
              backgroundColor={Palettes['light'].buttonBg}
              onChange={event => {
                setshowHistory(event.nativeEvent.selectedSegmentIndex === 1)
              }}
            />
          </View>
        )}
        {cartAmount > 0 && (
          <Text style={tw`flex-1 textMono title3`}>
            {showHistory ? '' : t('l.total') + ': ' + cartAmount.toFixed(2) + '€'}
          </Text>
        )}
      </View>
    )
  }, [dishesGrouped, showHistory, cartAmount, theme])

  const sections = useMemo(() => {
    const result = Object.entries(dishesGrouped).map(itm => {
      return { title: itm[0], data: itm[1] } as { title: DishOrderStatus; data: DishOrder[] }
    })
    if (showHistory) return result.filter(itm => itm.data.length > 0 && itm.title === 'HISTORY')
    return result.filter(itm => itm.data.length > 0 && itm.title !== 'HISTORY')
  }, [dishesGrouped, showHistory])

  const sectionNames = {
    ARRIVED: i18n.t('l.arrived'),
    WAITING: i18n.t('l.waiting'),
    ORDERING: i18n.t('l.order'),
    HISTORY: i18n.t('l.history'),
  }

  const notifyOrder = useCallback(async () => {
    if (!orderId || !currentCart || !menu.restaurantId || !menu._id) return
    PublicService.logAction(ActionType.Order, { order: dishesGrouped.ORDERING })
    if (!acceptedNotifications?.includes('NO_RTO_ORDER'))
      setNotification({
        type: MeaNotificationType.ALERT,
        title: t('l.itsStillMe'),
        imageSource: Images.illustration_order,
        description: t('l.youCanTrackArrivingDishes'),
        confirmText: t('l.understand'),
        onConfirm: () => addAcceptedNotification('NO_RTO_ORDER'),
      })
    sendOrder({
      orderId,
      currentCart,
      restaurantId: menu.restaurantId,
      menuId: menu._id,
      setOrderId,
      moveOrderingInWaiting,
      setUnavailableRecipeIds: menu.setUnavailableRecipeIds,
      resumeCartState,
    })
  }, [orderId, dishesGrouped, acceptedNotifications])

  return (
    <Screen
      noRestaurantName
      topBarChildren={
        <View style={tw`grow items-start`}>
          <Clickable
            style={tw`flex-1`}
            onPress={() => {
              if (navigation.canGoBack()) {
                navigation.goBack()
              } else {
                navigation.navigate('MenuScreen')
              }
            }}
          >
            <View style={tw`self-start flex-row items-center`}>
              <Icon name="Back" size={IconSize.Large} />
              <Text style={tw`ml-xs textMono title4`}>{t('l.backToMenu')}</Text>
            </View>
          </Clickable>
        </View>
      }
    >
      <LoadingIndicator visible={loading} />
      <View style={[{ height: SCREEN_AVAILABLE_HEIGHT - 6 }, tw`px-md`]}>
        <View style={tw`flex-1 mt-md`}>
          <SectionList
            style={tw`flex-1 pb-[100px]`}
            ListHeaderComponent={<Header />}
            ListEmptyComponent={<EmptyCart />}
            showsVerticalScrollIndicator={false}
            stickySectionHeadersEnabled
            sections={sections}
            renderItem={({ item, section }) => <ListItem item={item} section={section} />}
            keyExtractor={(item, index) => `${item.dishId}.${index.toString()}`}
            ListFooterComponent={
              coverPrice && !showHistory ? (
                <Text style={tw`textMono p-lg`}>{t('l.coverPrice') + ': ' + coverPrice + '€'}</Text>
              ) : null
            }
            renderSectionHeader={({ section: { title } }) => (
              <View style={tw`fillBackground px-xs py-md`}>
                <Text style={tw`textMono title3`}>{sectionNames[title]}</Text>
              </View>
            )}
          />
        </View>
      </View>
      <View style={tw`py-md absolute w-[100%] self-center bottom-0 px-[20px]`}>
        <View style={tw`flex-row`}>
          {dishesGrouped.ARRIVED.length + dishesGrouped.HISTORY.length > 0 && dishesGrouped.ORDERING.length === 0 && (
            <Button
              label={t('l.endOrderingSession')}
              style={tw`mr-xs flex-1`}
              variant={ButtonVariant.Secondary}
              onPress={() => {
                setcloseSessionModalVisible(true)
              }}
            />
          )}
          {dishesGrouped.ORDERING.length > 0 && !showHistory && (
            <>
              <Button
                label={t('l.toEmpty')}
                style={tw`mr-xs flex-1`}
                variant={ButtonVariant.Secondary}
                onPress={async () => {
                  PublicService.logAction(ActionType.Order, { action: OrderActionPayloadAction.Empty })
                  if (!orderId) return
                  emptyCart(orderId, emptyOrdering, setLoading)
                }}
              />
              <Button
                label={t('l.iveNotifiedTheOrder')}
                variant={ButtonVariant.Primary}
                style={tw`ml-xs flex-2`}
                onPress={notifyOrder}
              />
            </>
          )}
          {dishesGrouped.ORDERING.length === 0 && (
            <Button
              label={t('l.newOrder')}
              variant={ButtonVariant.Primary}
              style={tw`ml-xs flex-2`}
              onPress={() => {
                moveCartInHistory()
                navigation.navigate('MenuScreen')
              }}
            />
          )}
        </View>
      </View>
      <BaseModal
        visible={closeSessionModalVisible}
        title={t('l.warning')}
        onClose={() => setcloseSessionModalVisible(false)}
      >
        <Text style={tw`textMono mt-xl mb-md`}>{t('l.askEndOrderingSession')}</Text>
        <View style={tw`flex-row`}>
          <Button
            variant={ButtonVariant.Secondary}
            label={t('l.yes')}
            onPress={() => {
              setcloseSessionModalVisible(false)
              setAppMode('RECAP')
              PublicService.logAction(ActionType.Order, { action: OrderActionPayloadAction.Finish })
            }}
            style={tw`mr-md`}
          />
          <Button
            variant={ButtonVariant.Primary}
            label={t('l.no')}
            style={tw`ml-md`}
            onPress={() => setcloseSessionModalVisible(false)}
          />
        </View>
      </BaseModal>
    </Screen>
  )
}
