import { Card, Icon, IconName, Theme, useTw } from '@mea-menu/components'
import { useRoute } from '@react-navigation/native'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text, View } from 'react-native'
import { IntolerancesListFragment } from '..'
import { config } from '../../config'
import { useAppConfigStore, useThemeStore, useUserStore } from '../../store'
import { AppFeature } from '../../types'
import { appFeatureIsAvailable } from '../../utils'
import { SelectLanguageFragment } from '../fragments/SelectLanguageFragment'
import { BaseModal } from './BaseModal'

export interface SettingsModalProps {
  visible: boolean
  onClose: () => void
}

type Mode = 'language' | 'intolerances'

export const SettingsItem = ({
  text,
  iconName,
  onPress,
}: {
  text: string
  iconName: IconName
  onPress: () => void
}) => {
  const { tw } = useTw()
  return (
    <Card style={tw`px-md py-md rounded-md mt-md flex-row justify-between items-center`} onPress={onPress}>
      <Text style={tw`flex-10 textMono`} numberOfLines={1}>
        {text}
      </Text>
      <Icon name={iconName} />
    </Card>
  )
}

export function SettingsModal({ onClose, visible }: SettingsModalProps) {
  const { tw, theme } = useTw()
  const { t, i18n } = useTranslation()
  const { setColorMode } = useThemeStore()
  const { appMode, appFeatures } = useAppConfigStore()
  const { allergens: userAllergies, showUnsuitableDishes, setShowUnsuitableDishes } = useUserStore()
  const route = useRoute()
  const [mode, setmode] = useState<Mode>()

  const setIntolerancesExcludedScreens = ['LandingScreen']
  const toggleShowUnsuitableDishesExcludedScreens = ['OrderScreen']

  const intoleranceVisible =
    appMode === 'USER' &&
    appFeatureIsAvailable(AppFeature.EDIT_INTOLERANCES, appFeatures) &&
    !setIntolerancesExcludedScreens.includes(route.name)
  const toggleShowUnsuitableDishesVisible =
    !toggleShowUnsuitableDishesExcludedScreens.includes(route.name) && userAllergies > 0

  return (
    <BaseModal
      onClose={() => {
        onClose()
        setmode(undefined)
      }}
      title={t('l.settings')}
      visible={visible}
    >
      <View>
        {!mode && (
          <View>
            <SettingsItem onPress={() => setmode('language')} text={t('l.changeLanguage')} iconName={'Language'} />
            {intoleranceVisible && (
              <>
                <SettingsItem
                  onPress={() => setmode('intolerances')}
                  text={t('l.editIntolerances')}
                  iconName={'Food'}
                />
                {toggleShowUnsuitableDishesVisible && (
                  <SettingsItem
                    onPress={() => {
                      setShowUnsuitableDishes(!showUnsuitableDishes)
                      onClose()
                    }}
                    text={showUnsuitableDishes ? t('l.hideUnsuitableDishes') : t('l.showUnsuitableDishes')}
                    iconName={showUnsuitableDishes ? 'Cloche' : 'ClocheOutline'}
                  />
                )}
              </>
            )}
            <SettingsItem
              onPress={() => {
                setColorMode(theme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT)
                onClose()
              }}
              text={theme === Theme.DARK ? t('l.turnToLightMode') : t('l.turnToDarkMode')}
              iconName={theme === Theme.DARK ? 'Sun' : 'Moon'}
            />
          </View>
        )}

        {mode == 'intolerances' && (
          <IntolerancesListFragment
            heightReduction={100}
            onCancel={() => setmode(undefined)}
            onIntoleranceSetSuccess={() => {
              onClose()
              setmode(undefined)
            }}
          />
        )}

        {mode === 'language' && (
          <SelectLanguageFragment
            onClose={() => {
              onClose()
              setmode(undefined)
            }}
          />
        )}
      </View>
      <Text style={tw`textMono caption mt-md text-right opacity-50`}>v{config.version}</Text>
    </BaseModal>
  )
}
