import { Card, useTw } from '@mea-menu/components'
import { useNavigation } from '@react-navigation/native'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Text, TouchableOpacity, View } from 'react-native'
import { LoadingIndicator, MenuDishItem } from '..'
import { AkinatorService } from '../../api/user/AkinatorService'
import { useMenuStore, useSpecialDishesStore } from '../../store'
import { SCREEN_AVAILABLE_HEIGHT } from '../../theme/sizes'
import { AkinatorAnswer, AkinatorSessionStep } from '../../types'
import { translateLocal } from '../../utils'
import { getDishesFromDishesIds, getDishesFromRecipesId } from '../../utils/dishHelpers'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'

export interface AkinatorFragmentProps {
  menuId: string
  onDishPressed?: (dishId: string) => void
  onResultsVisibleChildren?: JSX.Element
}

export function AkinatorFragment({ menuId, onDishPressed, onResultsVisibleChildren }: AkinatorFragmentProps) {
  const { tw } = useTw()
  const navigator = useNavigation()
  const { t, i18n } = useTranslation()
  const { dishes } = useMenuStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [canShowResultingDishes, setCanShowResultingDishes] = useState<boolean>(false)
  const [akinatorSessionStep, setAkinatorSessionStep] = useState<AkinatorSessionStep>()
  const { akinatorDishesId, setAkinatorDishes } = useSpecialDishesStore()

  const startAkinator = async () => {
    if (!dishes) return
    try {
      const akinatorStep = await AkinatorService.init(menuId)
      setAkinatorSessionStep(akinatorStep)
    } catch (error) {
      meaErrorHandler(error, 'FETCH')
    } finally {
      setLoading(false)
    }
  }

  const selectAnswer = async (answerIndex: number) => {
    if (!akinatorSessionStep || !dishes) return
    try {
      setLoading(true)
      const akinatorStep = await AkinatorService.answer(akinatorSessionStep?._id, answerIndex)
      if (akinatorStep.recipeIds) {
        setAkinatorDishes(getDishesFromRecipesId(akinatorStep.recipeIds, dishes).map(dish => dish.dishId))
        setCanShowResultingDishes(true)
      } else {
        setAkinatorSessionStep(akinatorStep)
      }
    } catch (error) {
      meaErrorHandler(error, 'UPDATE')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    startAkinator()
  }, [])

  const AnswerItem = ({
    answer,
    answerIndex,
    putRightMargin,
    noFlex1,
  }: {
    answer: AkinatorAnswer
    answerIndex: number
    putRightMargin: boolean
    noFlex1: boolean
  }) => (
    <Card
      style={tw`${noFlex1 ? 'w-[48%]' : 'flex-1'} fillCard items-center justify-center rounded-md mt-md ${
        putRightMargin ? 'mr-md' : ''
      }`}
      activeOpacity={0.7}
      key={answer._id}
      onPress={() => {
        // logAction(ActionType.Akinator, {
        //   action: AkinatorActionPayloadAction.SelectIngredient,
        //   ingredientId: ingredientWithCount._id,
        // })
        selectAnswer(answerIndex)
      }}
    >
      <Text style={tw`px-sm py-lg title4 textMono text-center`}>{translateLocal(answer, 'answer', i18n.language)}</Text>
    </Card>
  )

  if (!dishes) return null
  return (
    <View style={tw`max-h-[${SCREEN_AVAILABLE_HEIGHT}px]`}>
      {(akinatorSessionStep?.question || canShowResultingDishes) && (
        <Text style={tw`mt-md mb-lg title2 textMono text-center`}>
          {canShowResultingDishes
            ? t('l.wesuggestyou')
            : translateLocal(akinatorSessionStep?.question, 'question', i18n.language)}
        </Text>
      )}

      <LoadingIndicator visible={loading} rounded />

      {canShowResultingDishes ? (
        <FlatList
          showsVerticalScrollIndicator={false}
          keyExtractor={dish => dish.dishId}
          ItemSeparatorComponent={() => <View style={tw`w-2`} />}
          data={getDishesFromDishesIds(akinatorDishesId, dishes)}
          renderItem={({ item }) => {
            return (
              <MenuDishItem
                dish={item}
                onPress={() => {
                  onDishPressed && onDishPressed(item.dishId)
                  navigator.navigate('DishDetailScreen', {
                    mode: 'akinator',
                    dishDetailId: item.dishId,
                  })
                }}
                onAddToCart={() =>
                  // logAction(ActionType.OldAkinator, {
                  //   action: OldAkinatorActionPayloadAction.AddToCart,
                  //   recipeId: item.recipeId,
                  // })
                  {}
                }
              />
            )
          }}
          ListFooterComponent={onResultsVisibleChildren}
        />
      ) : (
        <>
          {!!akinatorSessionStep?.question?.answers && (
            <View style={tw`flex-row flex-wrap`}>
              <FlatList
                numColumns={2}
                data={akinatorSessionStep.question.answers}
                renderItem={({ item, index }) => (
                  <AnswerItem
                    key={index}
                    putRightMargin={
                      index % 2 === 0 && index !== (akinatorSessionStep.question?.answers.length ?? 0) - 1
                    }
                    noFlex1={index === akinatorSessionStep?.question?.answers?.length ?? (0 - 1 && index % 2 === 0)}
                    answer={item}
                    answerIndex={index}
                  />
                )}
              />
            </View>
          )}
        </>
      )}
    </View>
  )
}
