import { useTw } from '@mea-menu/components'
import React, { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import Carousel from 'react-native-reanimated-carousel'
import { SCREEN_AVAILABLE_WIDTH } from '../theme/sizes'
import { GroupedDishes } from '../types'
import { DishDetailPage } from './dish/DishDetailPage'
import { parallaxLayout } from './Parallax'

const PAGE_WIDTH = (SCREEN_AVAILABLE_WIDTH / 5) * 4

export interface DishDetailCarouselProps {
  initialDishId: string
  groupedDishes: GroupedDishes

  onClose: () => void
  onCategoryKeyChange?: (categoryKey: string) => void
}

export const DishDetailCarousel: React.FC<DishDetailCarouselProps> = ({
  initialDishId,
  groupedDishes,
  onCategoryKeyChange,
  onClose,
}) => {
  const { tw } = useTw()
  const width = SCREEN_AVAILABLE_WIDTH

  const flatDishes = Object.values(groupedDishes).flat()
  const [index, setIndex] = useState<number>(flatDishes.findIndex(dish => dish.dishId === initialDishId))

  const categoryKey = useMemo(() => {
    return index !== -1 ? flatDishes[index].dishSubCategory ?? flatDishes[index].dishCategory : ''
  }, [index])

  useEffect(() => {
    onCategoryKeyChange && onCategoryKeyChange(categoryKey)
  }, [index])

  return (
    <View style={tw`flex-1`}>
      {!!PAGE_WIDTH && (
        <Carousel
          loop={false}
          onSnapToItem={a => setIndex(a)}
          style={{
            width,
            display: 'flex',
            justifyContent: 'center',
          }}
          width={PAGE_WIDTH}
          data={flatDishes}
          defaultIndex={index !== -1 ? index : 0}
          windowSize={3}
          renderItem={({ item, index }) => {
            return (
              <View style={tw`flex-1 justify-center`}>
                <DishDetailPage key={index} dish={item} />
              </View>
            )
          }}
          customAnimation={parallaxLayout(
            {
              size: PAGE_WIDTH,
              vertical: false,
            },
            {
              parallaxScrollingScale: 1,
              parallaxAdjacentItemScale: 0.5,
              parallaxScrollingOffset: 60,
            }
          )}
          scrollAnimationDuration={100}
        />
      )}
    </View>
  )
}
