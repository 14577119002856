import { Theme, ThemeProvider } from '@mea-menu/components'
import { ErrorBoundary } from 'react-error-boundary'
import { Platform, View } from 'react-native'
import { RootSiblingParent } from 'react-native-root-siblings'
import { Browser as SentryBrowser, init as SentryInit } from 'sentry-expo'
import 'setimmediate'
import { AppLoader, ErrorPage, NotificationManager } from './src/components'
import { config } from './src/config'
import './src/localization'
import { AppNavigator } from './src/navigation/Navigator'
import { useThemeStore } from './src/store'
import { WEB_APP_MAX_WIDTH_PX } from './src/theme/sizes'
import { MeaError } from './src/types'

// Sentry config
SentryInit({
  dsn: 'https://82df0d906fbd46f4954e886efc66b7f8@o1185289.ingest.sentry.io/4503969981726720',
  release: `mea-webapp:v${config.version}`,
  enableInExpoDevelopment: false,
  debug: __DEV__,
  tracesSampleRate: config.environment === 'prod' ? 0.8 : 0,
})

// // Prevents user from leaving the page.
// // In order for it to work, the user must have interacted
// // a minimum on the page (transient user activation).
// // Unfortunately modern browsers removed support for custom alert messages.
// if (Platform.OS === 'web') {
//   window.addEventListener('beforeunload', function (e) {
//     if (!__DEV__) {
//       e.preventDefault()
//       e.returnValue = ''
//     }
//   })
// }

const onError = async (error: Error) => {
  if (Platform.OS === 'web') {
    SentryBrowser.captureException(error)
  }
  if (error instanceof MeaError && error.fatal) {
    localStorage.clear()
  }
}

const App = () => {
  const { colorMode } = useThemeStore()
  return (
    <View style={{ flex: 1, justifyContent: 'center', flexDirection: 'row' }}>
      <View style={{ flex: 1, maxWidth: WEB_APP_MAX_WIDTH_PX }}>
        <RootSiblingParent>
          <ErrorBoundary
            onError={onError}
            FallbackComponent={({ error, resetErrorBoundary }) => {
              return <ErrorPage error={error} resetErrorBoundary={resetErrorBoundary} />
            }}
          >
            <ThemeProvider theme={colorMode}>
              <NotificationManager />
              <AppLoader>
                <AppNavigator />
              </AppLoader>
            </ThemeProvider>
          </ErrorBoundary>
        </RootSiblingParent>
      </View>
    </View>
  )
}

export default App
