import NetInfo from '@react-native-community/netinfo'
import { useFonts } from 'expo-font'
import { useEffect, useRef, useState } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { Linking, Platform } from 'react-native'
import { Browser as SentryBrowser } from 'sentry-expo'
import { loginUser } from '../../api/HTTPClient'
import { MenuService } from '../../api/user/MenuService'
import { OrderService } from '../../api/user/OrderService'
import { ActionType, PublicService } from '../../api/user/PublicService'
import { RestaurantService } from '../../api/user/RestaurantService'
import { UserService } from '../../api/user/UserService'
import { config } from '../../config'
import { RecapScreen } from '../../screens/RecapScreen'
import {
  useAppConfigStore,
  useCartStore,
  useMenuStore,
  useRestaurantStore,
  useSessionStore,
  useSpecialDishesStore,
  useUserLoginStore,
  useUserStore,
} from '../../store'
import { AppFeature, MeaError, User, UserLogin } from '../../types'
import { appFeatureIsAvailable, showToast, translateLocal } from '../../utils'
import {
  cartIsExpired,
  fetchAndSetNewSessionData,
  fetchAndSetSpecialDishesData,
  getCodeFromUrl,
  setupAppFromCode,
} from '../../utils/commonHelpers'
import { getDishesFromRecipesId } from '../../utils/dishHelpers'
import { meaErrorHandler } from '../../utils/MeaErrorHandler'
import { SplashPage } from '../SplashPage'

export const CART_EXPIRED_INTERVAL = 4 * 60 * 60
const CHECK_CART_EXPIRED_INTERVAL = 1000
const CHECK_MENU_UNAVAILABLE_INTERVAL = __DEV__ ? 10000 : 60000

export function AppLoader({ children }: { children: JSX.Element }) {
  const { t, i18n } = useTranslation()
  const rootErrorHandler = useErrorHandler()
  const [appInitialized, setappInitialized] = useState(false)
  const { setRestaurant, _id: lastRestaurantId } = useRestaurantStore()
  const {
    _id: lastMenuId,
    dishes: lastMenuDishes,
    setMenu,
    setUnavailableRecipeIds,
    setRecipeLikesMap,
    unavailableRecipeIds: storedUnavailableRecipeIds,
    dishCategories,
  } = useMenuStore()
  const userLogin = useUserLoginStore()
  const { setUser } = useUserStore()
  const { menuScreenIsActive } = useSessionStore()
  const { setFeaturedDishes, setForYouRecipes, setTrendingRecipes } = useSpecialDishesStore()
  const {
    initCart,
    setOrderId,
    resumeCartState,
    lastUpdateSeconds: cartLastUpdateSeconds,
    menuId: cartMenuId,
    currentCart,
    orderId,
  } = useCartStore()
  const {
    appMode: storeAppMode,
    appFeatures: storeAppFeatures,
    restaurantId: storeRestaurantId,
    setAppMode,
    addAppFeature,
    setAppFeatures,
    setRestaurantId,
  } = useAppConfigStore()
  const cartExpirationTimerRef = useRef<NodeJS.Timer | null>(null)
  const menuUnavailableDishesTimerRef = useRef<NodeJS.Timer | null>(null)

  const [fontsLoaded] = useFonts({
    'Circular Std Medium': require('../../../assets/fonts/CircularStd-Medium.ttf'),
    'CircularStd-Black': require('../../../assets/fonts/CircularStd-Black.otf'),
    'CircularStd-Book': require('../../../assets/fonts/CircularStd-Book.otf'),
    'CircularStd-Medium': require('../../../assets/fonts/CircularStd-Medium.otf'),
    'CircularStd-Bold': require('../../../assets/fonts/CircularStd-Bold.otf'),
    'CircularStd-Light': require('../../../assets/fonts/CircularStd-Light.otf'),
  })

  const setAppInitializedWithTimeout = (value: boolean, timeout?: number) => {
    setTimeout(() => setappInitialized(value), __DEV__ ? 500 : timeout ?? 2500)
  }

  /*
  Link codes

  const RESTAURANT_DEMO = '6345658e7f31661bb0c6475c'
  const PRICE_LIST_DEMO = '640711ce38d7fd7b2651c83a'
  const RESTAURANT_VIKTORIA = '63331284df1a9f59c30c0c56'
  const RESTAURANT_INFERNO = '63ebb380b034693ff9df7b68'
  const RESTAURANT_REVERSE = '638b34f8255a6432efb2f732'
  const RESTAURANT_POP = '6463338f146ec32a1029fb04'
  const BEER_HOUSE = '650427b82a7a4453f047a483'
  const MANGIARINO = '651310e895c787b5cb50dca4'
  */

  const checkLegacyState = async () => {
    const legacyUserLoginString = localStorage.getItem('store.meamenu.userLogin')
    if (legacyUserLoginString === null) return undefined
    let legacyUserLogin = JSON.parse(legacyUserLoginString) as UserLogin
    if (!legacyUserLogin || !legacyUserLogin.accessToken || !legacyUserLogin.userUuid || !legacyUserLogin.userId)
      return undefined
    legacyUserLogin.accessToken = legacyUserLogin.accessToken.replaceAll('"', '')
    legacyUserLogin.userId = legacyUserLogin.userId.replaceAll('"', '')
    legacyUserLogin.userUuid = legacyUserLogin.userUuid.replaceAll('"', '')
    localStorage.removeItem('store.meamenu.userLogin')
    return legacyUserLogin
  }

  enum InitType {
    'SCAN',
    'REFRESH',
  }

  const getAppConfiguration = async () => {
    const initialLink = await Linking.getInitialURL()
    console.log(`Link: "${initialLink}"`)
    const code = getCodeFromUrl(initialLink)
    if (code) {
      // just scanned qr-code
      return { ...(await setupAppFromCode(code)), initType: InitType.SCAN }
    } else {
      // page refresh
      if (!storeAppMode || !storeRestaurantId) {
        throw new MeaError(
          `missingLinkCode - Link: "${initialLink}" - storeAppMode: "${storeAppMode}" - storeAppFeatures: "${storeAppFeatures}" - storeRestaurantId: "${storeRestaurantId}"`,
          t('errors.missingLinkCode')
        )
      }
      return {
        appMode: storeAppMode,
        appFeatures: storeAppFeatures,
        restaurantId: storeRestaurantId,
        initType: InitType.REFRESH,
      }
    }
  }

  const initializeApp = async () => {
    try {
      //#region login
      const { appMode, appFeatures, restaurantId: scannedRestaurantId, initType } = await getAppConfiguration()
      setRestaurantId(scannedRestaurantId)
      setAppFeatures(appFeatures)
      if (!scannedRestaurantId) throw new MeaError('missingRestaurantId', t('errors.missingRestaurantId'))

      // configuration for Sentry
      if (Platform.OS === 'web') SentryBrowser.setContext('restaurant', { _id: scannedRestaurantId })

      let user: User

      // check if data migraton from legacy store is needed
      const legacyStoreUserLogin = await checkLegacyState()
      if (legacyStoreUserLogin) {
        userLogin.setUserLoginData({
          userId: legacyStoreUserLogin.userId,
          accessToken: legacyStoreUserLogin.accessToken!,
        })
        await loginUser({
          ...userLogin,
          accessToken: legacyStoreUserLogin.accessToken!,
          userId: legacyStoreUserLogin.userId,
          userUuid: legacyStoreUserLogin.userUuid,
        })
        PublicService.logAction(ActionType.MigratedFromLegacyStore, {
          userId: legacyStoreUserLogin.userId,
          userUuid: legacyStoreUserLogin.userUuid,
        })
        if (config.environment !== 'prod') showToast('userLogin storage migrated succesfully', 'SUCCESS')
      } else {
        await loginUser(userLogin)
      }

      user = await UserService.getCurrent()
      setUser(user)
      setAppMode(appMode)

      //#endregion login

      const hasScannedDifferentRestaurant = scannedRestaurantId !== lastRestaurantId

      const scannedRestaurantInfo = await RestaurantService.checkIn(scannedRestaurantId)
      // configuration for Sentry
      if (Platform.OS === 'web')
        SentryBrowser.setContext('restaurant', {
          _id: scannedRestaurantId,
          name: scannedRestaurantInfo.restaurant.name,
        })

      if (lastMenuId) {
        const restaurantMenuData = scannedRestaurantInfo.menus.find(menu => menu._id === lastMenuId)
        if (restaurantMenuData) {
          await fetchAndSetNewSessionData(restaurantMenuData, { setMenu, setUnavailableRecipeIds, setRecipeLikesMap })
          if (
            appMode !== 'PRICE_LIST' &&
            !hasScannedDifferentRestaurant &&
            !!cartMenuId &&
            scannedRestaurantInfo.menus.some(menu => menu._id === cartMenuId) &&
            scannedRestaurantInfo.menus.find(menu => menu._id === cartMenuId)?.userCanOrder &&
            cartMenuId === lastMenuId &&
            !!lastMenuDishes &&
            lastMenuDishes.length > 0
          ) {
            const lastUserOrder = await OrderService.getCurrentByMenuId(scannedRestaurantId, cartMenuId)
            if (lastUserOrder.dishes.length > 0) {
              // resume session
              const unavailableRecipeIds = (await MenuService.getMenuRecipes(cartMenuId))
                .filter(recipe => !recipe.available)
                .map(recipe => recipe.id)
              setUnavailableRecipeIds(unavailableRecipeIds)
              await fetchAndSetSpecialDishesData(
                cartMenuId,
                user.allergens,
                setForYouRecipes,
                setFeaturedDishes,
                setTrendingRecipes
              )
              setRestaurant({ ...scannedRestaurantInfo.restaurant, menus: scannedRestaurantInfo.menus })
              initCart(cartMenuId)
              setOrderId(lastUserOrder._id)
              resumeCartState(lastUserOrder.dishes)
              addAppFeature(AppFeature.ORDER_WITH_WAITER)
              return setAppInitializedWithTimeout(true)
            }
          }
        }
      }
      setRestaurant({ ...scannedRestaurantInfo.restaurant, menus: scannedRestaurantInfo.menus })
      if (initType === InitType.SCAN) {
        // start new session
        initCart(undefined)
        setOrderId(undefined)
      }
      return setAppInitializedWithTimeout(true)
    } catch (e) {
      rootErrorHandler(e)
      return setAppInitializedWithTimeout(true)
    }
  }

  useEffect(() => {
    // App entry point
    if (config.environment !== 'prod') {
      console.log(config.environment)
      console.log(config.version)
    }
    initializeApp()

    const unsubscribeNetInfo = NetInfo.addEventListener(netInfoState => {
      if (config.environment !== 'prod') {
        console.log('connection', netInfoState)
      }
      SentryBrowser.setContext('connection', netInfoState)
    })

    return () => {
      unsubscribeNetInfo()
    }
  }, [])

  // cart expiration check and handling (in case of browser tab left open)
  useEffect(() => {
    if (
      !appInitialized ||
      storeAppMode !== 'USER' ||
      !appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, storeAppFeatures)
    )
      return
    // when cart changes, the timer needs to be restarted
    if (cartExpirationTimerRef.current) clearInterval(cartExpirationTimerRef.current)
    if (cartMenuId === undefined) return

    cartExpirationTimerRef.current = setInterval(() => {
      if (!!cartMenuId && cartIsExpired(cartLastUpdateSeconds) && !!cartExpirationTimerRef.current) {
        clearInterval(cartExpirationTimerRef.current)
        setAppMode('RECAP')
      }
    }, CHECK_CART_EXPIRED_INTERVAL)
    return () => {
      if (cartExpirationTimerRef.current) clearInterval(cartExpirationTimerRef.current)
    }
  }, [appInitialized, storeAppMode, storeAppFeatures, cartMenuId, cartLastUpdateSeconds])

  // unavailableDishes polling
  useEffect(() => {
    if (
      !appInitialized ||
      !appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, storeAppFeatures) ||
      !cartMenuId ||
      !dishCategories ||
      !lastMenuDishes ||
      !orderId ||
      !lastRestaurantId ||
      !menuScreenIsActive
    )
      return
    menuUnavailableDishesTimerRef.current = setInterval(async () => {
      try {
        const currentUnavailableRecipeIds = [...(storedUnavailableRecipeIds ?? [])]
        const newUnavailableRecipes = (await MenuService.getMenuRecipes(cartMenuId))
          .filter(recipe => !recipe.available)
          .map(recipe => recipe.id)

        const unavailableRecipesAreEqual =
          newUnavailableRecipes.length === currentUnavailableRecipeIds.length &&
          newUnavailableRecipes.every(recipe => currentUnavailableRecipeIds.includes(recipe))
        if (unavailableRecipesAreEqual) return

        // Check for new available dishes
        const newAvailableDishes = getDishesFromRecipesId(
          currentUnavailableRecipeIds.filter(dish => !newUnavailableRecipes.includes(dish)),
          lastMenuDishes
        )
        if (newAvailableDishes.length > 0) {
          const newAvailableDishesMessage = newAvailableDishes
            .map(dish => translateLocal(dish, 'name', i18n.language))
            .join(', ')
          showToast(
            newAvailableDishes.length > 1
              ? t('l.newAvailableDishes', {
                  newAvailableDishes: newAvailableDishesMessage,
                })
              : t('l.newAvailableDish', {
                  newAvailableDishes: newAvailableDishesMessage,
                  category: translateLocal(dishCategories[newAvailableDishes[0].dishCategory], 'name', i18n.language),
                }),
            'SUCCESS'
          )
        }

        setUnavailableRecipeIds(newUnavailableRecipes)
      } catch (error) {
        meaErrorHandler(error)
      }
    }, CHECK_MENU_UNAVAILABLE_INTERVAL)
    return () => {
      if (menuUnavailableDishesTimerRef.current) clearInterval(menuUnavailableDishesTimerRef.current)
    }
  }, [
    appInitialized,
    menuScreenIsActive,
    storeAppMode,
    storeAppFeatures,
    cartMenuId,
    storedUnavailableRecipeIds,
    currentCart,
  ])

  if (!appInitialized || !fontsLoaded) {
    return <SplashPage />
  }

  return storeAppMode === 'RECAP' ? <RecapScreen /> : children
}
