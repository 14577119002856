import { BaseService } from './BaseService'

export function BaseUserService() {
  abstract class BaseUserService extends BaseService() {
    static pathPrefix: string = 'app'

    static getPath() {
      return `${this.pathPrefix}/${this.entityPath}`
    }
  }

  return BaseUserService
}
