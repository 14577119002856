import { Icon, useTw } from '@mea-menu/components'
import { useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Platform, TextInput } from 'react-native'
import { Clickable } from './'

export interface SearchBarProps {
  value: string
  visible: boolean
  deleteVisible?: boolean
  onTextChange: (text: string) => void
  onSumbit?: () => void
}

export function SearchBar({ value, onTextChange, deleteVisible, visible, onSumbit }: SearchBarProps) {
  const { tw, theme } = useTw()

  const inputRef = useRef<TextInput>(null)
  const { t } = useTranslation()
  const animatedValue = useMemo(() => new Animated.Value(1), [])

  useEffect(() => {
    if (visible) {
      // start animation to decrease height
      // on animation end setActionButton(null)
      Animated.timing(animatedValue, {
        toValue: 0,
        duration: 300,
        delay: 0,
        useNativeDriver: Platform.OS !== 'web',
      }).start()
    } else {
      // start animation to increase height
      Animated.timing(animatedValue, {
        toValue: 100,
        duration: 300,
        delay: 0,
        useNativeDriver: Platform.OS !== 'web',
      }).start()
    }
  }, [visible])

  return (
    <Animated.View
      style={[
        tw`flex-row items-center py-xxs px-sm rounded-md fillCard mx-md`,
        { height: animatedValue.interpolate({ inputRange: [0, 100], outputRange: [0, 44] }) },
        { opacity: animatedValue.interpolate({ inputRange: [0, 100], outputRange: [0, 1] }) },
        { marginTop: animatedValue.interpolate({ inputRange: [0, 100], outputRange: [-10, 0] }) },
      ]}
    >
      <Icon name="Search" />
      <TextInput
        onSubmitEditing={onSumbit}
        style={[tw`textMono px-sm py-xs w-full`, { outlineStyle: 'none' }]}
        value={value}
        ref={inputRef}
        onChangeText={newText => {
          onTextChange(newText)
        }}
        placeholder={t('l.search')}
        placeholderTextColor={theme === 'light' ? 'black' : 'white'}
      />
      <Clickable
        id="clear_search"
        onPress={() => {
          onTextChange('')
        }}
        style={tw`ml-md`}
      >
        <Icon name="Close" />
      </Clickable>
    </Animated.View>
  )
}
