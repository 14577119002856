import Toast from 'react-native-root-toast'
import { CommonPalette } from '../theme/palette'
import { AppFeature } from '../types'

export const translateLocal = (entity: any, key: string, language: string): string => {
  if (!entity) return ''
  let _lan = language[0].toUpperCase() + language[1]
  if (entity[key + _lan]) {
    return entity[key + _lan]
  }
  return entity[key + 'En'] ?? entity[key + 'It']
}

export const matchesInAnyLanguage = (
  entity: any,
  key: string,
  search: string,
  caseSensitive = false,
  fullWord = false
): boolean => {
  const languagesToMatch = ['En', 'It', 'Zh']
  for (const lang of languagesToMatch) {
    if (entity[key + lang]) {
      let val = entity[key + lang]
      if (typeof val === 'string') {
        val = caseSensitive ? val : val.toLowerCase()
        const filter = caseSensitive ? search : search.toLowerCase()
        if ((fullWord && val === filter) || (!fullWord && val.includes(filter))) return true
      }
    }
  }
  return false
}

export const appFeatureIsAvailable = (appFeature: AppFeature, appFeatures: number) => {
  return (appFeature & appFeatures) !== 0
}

export const showToast = (message: string, type?: 'ERROR' | 'SUCCESS', duration?: number, position?: number) => {
  Toast.show(message, {
    duration: duration ?? 5000,
    position: position ?? Toast.positions.BOTTOM,
    shadow: true,
    animation: true,
    hideOnPress: true,
    opacity: 1,
    backgroundColor: type === 'SUCCESS' ? CommonPalette.meaGreen : 'red',
    delay: 0,
  })
}
