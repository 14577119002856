import { useTw } from '@mea-menu/components'
import { View } from 'react-native'
import { IntolerancesListFragment } from '../components'
import { RootStackScreenProps } from '../navigation'
import { useUserStore } from '../store'

export function SetIntolerancesScreen({ navigation, route }: RootStackScreenProps<'SetIntolerancesScreen'>) {
  const { setIntolerancesAsked } = useUserStore()
  const { tw } = useTw()

  const goBack = () => {
    if (navigation.canGoBack()) {
      navigation.goBack()
    } else {
      navigation.navigate('MenuScreen')
    }
  }

  return (
    <View style={tw`m-xl`}>
      <IntolerancesListFragment
        onCancel={goBack}
        onIntoleranceSetSuccess={() => {
          setIntolerancesAsked(true)
          goBack()
        }}
      />
    </View>
  )
}
