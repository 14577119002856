import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useAppConfigStore, useMenuStore, useSpecialDishesStore, useUserStore } from '../store'
import { AppFeature, Dish, DishCategory, GroupedDishes, SectionHeader, SpecialDishesSectionData } from '../types'
import { appFeatureIsAvailable } from '../utils'
import {
  getDishesFromDishesIds,
  getDishesFromRecipesId,
  getDishesFromSuggestions,
  getFilteredDishes,
  groupDishes,
  userIsAllergicTo,
} from '../utils/dishHelpers'

type useMenuExp = {
  getSuitableDishes: (dishes: Dish[]) => Dish[]
  groupedSpecialDishes: SpecialDishesSectionData[]
  groupedDishes: GroupedDishes
  filteredDishes: GroupedDishes
  akinatorDishes: GroupedDishes
  sectionHeadersData: SectionHeader[]
  menuFavouritesDishes: Dish[]
  sectionSpecialDishesHeadersData: SectionHeader[]
}

export const useMenu = (searchFilter?: string): useMenuExp => {
  const { dishes, dishCategories, ingredients, unavailableRecipeIds } = useMenuStore()
  const { showUnsuitableDishes, allergens: userAllergies, likedRecipesId } = useUserStore()
  const { forYouRecipes, featuredDishesId, trendingRecipesId, akinatorDishesId } = useSpecialDishesStore()
  const { i18n } = useTranslation()
  const { appFeatures } = useAppConfigStore()

  const getSuitableDishes = (dishes: Dish[]) => {
    return appFeatureIsAvailable(AppFeature.EDIT_INTOLERANCES, appFeatures) &&
      !showUnsuitableDishes &&
      userAllergies > 0
      ? dishes.filter(dish => !userIsAllergicTo(userAllergies, dish.allergens))
      : dishes
  }

  const groupedDishes: GroupedDishes = useMemo(() => {
    if (!dishes) return {}
    return groupDishes(getSuitableDishes(dishes.filter(dish => !(unavailableRecipeIds ?? []).includes(dish.recipeId))))
  }, [showUnsuitableDishes, userAllergies, appFeatures, unavailableRecipeIds])

  const filteredDishes = useMemo(() => {
    if (!dishCategories || !ingredients) return {}
    return getFilteredDishes(dishCategories, ingredients, groupedDishes, searchFilter ?? '')
  }, [groupedDishes, searchFilter])

  const sectionHeadersData: SectionHeader[] = useMemo(() => {
    const result: SectionHeader[] = []
    if (!dishCategories) return result
    Object.keys(filteredDishes).forEach(categoryKey => {
      const actualCat: DishCategory = dishCategories[categoryKey]
      const hasDescription = !!actualCat.descriptionEn || !!actualCat.descriptionIt || !!actualCat.imageUrl
      result.push({
        categoryKey,
        hasDescription,
        descriptionImageUrl: hasDescription && !!actualCat.imageUrl ? actualCat.imageUrl : undefined,
      })
    })
    return result
  }, [filteredDishes])

  const menuFavouritesDishes: Dish[] = useMemo(() => {
    if (!dishes) return []
    return getDishesFromRecipesId(likedRecipesId, dishes)
  }, [likedRecipesId])

  const mustTryDishes: Dish[] = useMemo(() => {
    if (!dishes) return []
    const filtered = getDishesFromSuggestions(forYouRecipes, dishes, i18n.language).filter(
      d => !likedRecipesId.includes(d.recipeId)
    )
    if (filtered.length > 7) {
      return filtered.splice(0, 7)
    }
    return filtered
  }, [forYouRecipes, likedRecipesId])

  const groupedSpecialDishes = useMemo(() => {
    if (!dishes || !dishCategories || !ingredients) return [] as SpecialDishesSectionData[]

    const sectionListData: SpecialDishesSectionData[] = []
    const favouriteDishes = getSuitableDishes(menuFavouritesDishes)
    const featuredDishes = getSuitableDishes(getDishesFromDishesIds(featuredDishesId, dishes))
    const tryDishes = getSuitableDishes(mustTryDishes).splice(0, 5)
    const trendingDishes = getSuitableDishes(getDishesFromRecipesId(trendingRecipesId, dishes))
    if (favouriteDishes.length > 0)
      sectionListData.push({
        title: 'favouriteDishes',
        data: favouriteDishes,
      })

    if (featuredDishes.length > 0)
      sectionListData.push({
        title: 'featuredDishes',
        data: featuredDishes,
      })

    if (tryDishes.length > 0)
      sectionListData.push({
        title: 'mustTryDishes',
        data: tryDishes,
      })

    if (trendingDishes.length > 0)
      sectionListData.push({
        title: 'trendingDishes',
        data: trendingDishes,
      })
    return sectionListData
  }, [menuFavouritesDishes, featuredDishesId, mustTryDishes, trendingRecipesId])

  const sectionSpecialDishesHeadersData: SectionHeader[] = useMemo(() => {
    const result: SectionHeader[] = []
    if (!dishCategories) return result
    return Object.keys(groupedSpecialDishes).map(
      key => ({ categoryKey: key, hasDescription: false, descriptionImageUrl: undefined } as SectionHeader)
    )
  }, [groupedSpecialDishes])

  const akinatorDishes: GroupedDishes = useMemo(() => {
    if (!dishes) return {} as GroupedDishes
    return { akinator: getDishesFromDishesIds(akinatorDishesId, dishes) }
  }, [akinatorDishesId])

  return {
    getSuitableDishes,
    groupedSpecialDishes,
    groupedDishes,
    filteredDishes,
    akinatorDishes,
    sectionHeadersData,
    menuFavouritesDishes,
    sectionSpecialDishesHeadersData,
  }
}
