import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { Restaurant } from '../types'

interface RestaurantStore extends Restaurant {
  setRestaurant: (restaurant: Restaurant) => void
  initRestaurant: () => void
}

const initialState: Restaurant = {
  menus: [],
  priceCurrency: 'EUR',
  _id: undefined,
  name: undefined,
  coverPrice: undefined,
  cover: undefined,
  lat: undefined,
  lng: undefined,
  address: undefined,
  descriptionIt: undefined,
  descriptionEn: undefined,
  descriptionZh: undefined,
}

export const useRestaurantStore = create<RestaurantStore>()(
  persist(
    immer<RestaurantStore>((set, _) => ({
      ...initialState,
      setRestaurant: restaurant => set({ ...initialState, ...restaurant }),
      initRestaurant: () => {
        set(initialState)
      },
    })),
    {
      name: 'meamenu.restaurant',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
