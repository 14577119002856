import { Dimensions, PixelRatio } from 'react-native'

export const WEB_APP_MAX_WIDTH_PX = 600
export const SCREEN_AVAILABLE_WIDTH = Math.min(WEB_APP_MAX_WIDTH_PX, Dimensions.get('window').width)
export const SCREEN_AVAILABLE_HEIGHT = Dimensions.get('window').height - 64

// floor on scaling, to prevent fonts sizes becoming too small
const minScale = 0.92
// based on iphone 11s's scale
const scale = Math.max(SCREEN_AVAILABLE_WIDTH / 375, minScale)

export const normalizeFontSize = (size: number) => {
  return size
  const newSize = size * scale
  return Math.round(PixelRatio.roundToNearestPixel(newSize))
}

const XxSmall = 'xxs'
const XSmall = 'xs'
const Small = 'sm'
const Medium = 'md'
const Title = 'tt'
const MediumPlus = 'mdp'
const Large = 'lg'
const XLarge = 'xl'
const XLargePlus = 'xlp'
const XxLarge = 'xxl'
const XxLargePlus = 'xxlp'

export const fontSizes = {
  [XSmall]: normalizeFontSize(12),
  [Small]: normalizeFontSize(14),
  [Medium]: normalizeFontSize(16),
  [Title]: normalizeFontSize(18),
  [Large]: normalizeFontSize(22),
  [XLarge]: normalizeFontSize(25),
}

export type FontSizeType = keyof typeof fontSizes

export const borderRadius = {
  [XxSmall]: 4,
  [XSmall]: 6,
  [Small]: 10,
  [Medium]: 16,
  [Large]: 26,
  [XLarge]: 32,
  [XxLarge]: 40,
}

export const spacing = {
  [XxSmall]: 4,
  [XSmall]: 8,
  [Small]: 12,
  [Medium]: 16,
  [MediumPlus]: 20,
  [Large]: 24,
  [XLarge]: 32,
  [XLargePlus]: 40,
  [XxLarge]: 48,
  [XxLargePlus]: 56,
}

export type SpacingType = keyof typeof spacing

export const margin = {
  ...spacing,
}

export const padding = {
  ...spacing,
}

export const Sizes = { borderRadius, margin, padding, spacing }
