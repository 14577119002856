import { Card, CardVariant, useTw } from '@mea-menu/components'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, Text, View } from 'react-native'
import { Images } from '../../../assets'
import { ActionType, PublicService } from '../../api/user/PublicService'

export interface SelectLanguageFragmentProps {
  onClose: () => void
}

type LanguageLink = {
  langName: string
  langFlag: string
  langCodes: string[]
}

const languages: LanguageLink[] = [
  {
    langName: 'English',
    langFlag: Images.flag_en,
    langCodes: ['en', 'en-US', 'en-GB'],
  },
  {
    langName: 'Italiano',
    langFlag: Images.flag_it,
    langCodes: ['it', 'it-IT', 'it-CH'],
  },
  {
    langName: 'Español',
    langFlag: Images.flag_es,
    langCodes: ['es', 'es-ES'],
  },
  {
    langName: 'Français',
    langFlag: Images.flag_fr,
    langCodes: ['fr', 'fr-FR'],
  },
  {
    langName: 'Deutsch',
    langFlag: Images.flag_de,
    langCodes: ['de', 'de-DE', 'de-CH'],
  },
  {
    langName: 'Русский',
    langFlag: Images.flag_ru,
    langCodes: ['ru', 'ru-RU'],
  },
  {
    langName: '中国人',
    langFlag: Images.flag_zh,
    langCodes: ['zh', 'zh-CN', 'zh-HK'],
  },
  {
    langName: '日本語',
    langFlag: Images.flag_ja,
    langCodes: ['ja', 'ja-JP'],
  },
]

export function SelectLanguageFragment({ onClose }: SelectLanguageFragmentProps) {
  const { tw } = useTw()

  const LanguageItem = (item: LanguageLink, index: number) => {
    const { t, i18n } = useTranslation()

    return (
      <Card
        key={item.langName}
        variant={CardVariant.Default}
        style={tw`m-xs py-xs items-center border-2 ${index % 2 !== 0 ? 'mr-md' : ''} ${
          item.langCodes[0] === i18n.language ? 'strokePrimary' : 'strokeTransparent'
        }`}
        onPress={async () => {
          onClose()
          setTimeout(() => {
            const language = item.langCodes[0]
            i18n.changeLanguage(language)
          }, 50)
        }}
      >
        <Image style={{ width: 40, height: 40 }} source={{ uri: item.langFlag }} />
        <Text style={tw`mt-sm textMono`}>{item.langName}</Text>
      </Card>
    )
  }

  return (
    <FlatList
      data={languages}
      numColumns={2}
      renderItem={({ item }) => (
        <LanguageItem langName={item.langName} langFlag={item.langFlag} langCodes={item.langCodes} />
      )}
    />
  )
}
