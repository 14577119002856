import { ButtonSize, ButtonVariant, Card, Icon, IconButton, IconColor, useTw } from '@mea-menu/components'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Animated, Image, Text, View } from 'react-native'
import Toast from 'react-native-root-toast'
import { Clickable } from '..'
import { Images } from '../../../assets'
import { useDish } from '../../hooks/useDishHook'
import { useAppConfigStore, useMenuStore } from '../../store'
import { AppFeature, Dish } from '../../types'
import { appFeatureIsAvailable, showToast, translateLocal } from '../../utils'
import { getDishesFromDishesIds } from '../../utils/dishHelpers'
import { SmartImage } from '../core/SmartImage'

export const MENU_DISH_ITEM_HEIGHT = 100

export interface MenuDishItemProps {
  dish: Dish
  onAddToCart?: () => void
  onPress?: () => void
  insideVariantsModal?: boolean
}

export function MenuDishItem({ dish, onAddToCart, onPress, insideVariantsModal }: MenuDishItemProps) {
  const {
    dishCaption,
    dishVariants,
    dishName,
    dishPrice,
    recipeIsLiked,
    dishQty,
    dishIsAvailable,
    decreaseQuantity,
    increaseQuantity,
    toggleDishlike,
    imageUrl,
    userIsAllergicToThisDish,
  } = useDish(dish, insideVariantsModal)
  const { dishes: menuDishes } = useMenuStore()
  const { tw } = useTw()
  const { t, i18n } = useTranslation()
  const { appMode, appFeatures } = useAppConfigStore()

  const dishSubTitle = useMemo(() => {
    if (dishVariants.length > 0 && !insideVariantsModal) {
      return [dish, ...getDishesFromDishesIds(dishVariants, menuDishes ?? [])]
        .map(dish => `${(dish.price ?? 0).toFixed(2) + '€'} • ${translateLocal(dish, 'caption', i18n.language)}`)
        .join('\n')
    }
    let subtitle = dishPrice ?? ''
    if (dishCaption) subtitle += (subtitle ? ' • ' : '') + dishCaption
    return subtitle
  }, [appMode, dishCaption, dishPrice, appFeatures, dish, dishVariants, menuDishes, i18n.language])

  return (
    <View style={tw`mt-[6px] h-[${MENU_DISH_ITEM_HEIGHT - 6}px] opacity-${dishIsAvailable ? '100' : '50'}`}>
      <Card onPress={onPress} key={dish.dishId} style={tw`h-full flex-row p-xs`}>
        <View style={tw`justify-center`}>
          <View style={tw`w-[80px] h-[80px]`}>
            <SmartImage
              style={tw`w-full h-full rounded-sm`}
              source={{ uri: imageUrl }}
              resizeMode="contain"
              forceHeight={120}
            />
          </View>

          {appMode === 'USER' && appFeatureIsAvailable(AppFeature.LIKE_RECIPE, appFeatures) && (
            <Clickable
              id={`${recipeIsLiked ? 'dis' : ''}like_recipe_${dish.recipeId}`}
              onPress={toggleDishlike}
              style={tw`absolute top-0 right-0`}
            >
              <Image
                style={[tw`w-[44px] h-[44px] absolute -top-3 -right-3`]}
                source={Images.heart_bg}
                resizeMode={'contain'}
              />
              <View style={tw`absolute top-0 right-0`}>
                <Icon
                  name={recipeIsLiked ? 'Heart' : 'HeartOutline'}
                  color={recipeIsLiked ? IconColor.primary : IconColor.white}
                />
              </View>
            </Clickable>
          )}
        </View>
        <View style={[tw`ml-sm flex-1`]}>
          <Text style={tw`title4 textMono`}>{dishName}</Text>
          <Text style={tw`mr-sm textMono mt-xs mr-[90px]`} numberOfLines={3}>
            {dishSubTitle}
          </Text>
        </View>
        {appMode === 'USER' && appFeatureIsAvailable(AppFeature.ORDER_WITH_WAITER, appFeatures) && (
          <Animated.View
            style={[tw`absolute items-center justify-end w-full flex-row overflow-hidden right-xs bottom-xs`]}
          >
            {dishIsAvailable ? (
              <>
                {dishQty > 0 && (
                  <>
                    <IconButton
                      testID={'decrement_dish_' + dish.dishId}
                      icon="Minus"
                      variant={ButtonVariant.Accent}
                      size={ButtonSize.Small}
                      onPress={() => decreaseQuantity()}
                    />
                    <Text style={tw`textMono w-${dishQty > 9 ? 7 : 3} title3 mx-xs`}>{dishQty}</Text>
                  </>
                )}
                <IconButton
                  testID={'increment_dish_' + dish.dishId}
                  icon="Plus"
                  variant={!userIsAllergicToThisDish ? ButtonVariant.Secondary : ButtonVariant.Warning}
                  size={ButtonSize.Small}
                  onPress={() => increaseQuantity()}
                />
              </>
            ) : (
              <Text
                style={tw`textMono items-center justify-center`}
                onPress={() => showToast(t('errors.dishNotAvailable'), 'ERROR', 3000, Toast.positions.CENTER)}
              >
                {t('l.unavailable')}
              </Text>
            )}
          </Animated.View>
        )}
      </Card>
    </View>
  )
}
