import { t } from 'i18next'
import { MeaError } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export interface GetUserOrderResponse {
  _id: string
  dishes: { dishId: string; recipeId: string; quantity: number }[]
  restaurantId: string
  userId: string
  menuId: string
}

export class OrderService extends BaseUserService() {
  static entityPath: string = 'order'

  static async getCurrentByMenuId(restaurantId: string, menuId: string): Promise<GetUserOrderResponse> {
    const response = await getBackendClient().get<GetUserOrderResponse>(
      `${this.getPath()}/getCurrent/${restaurantId}/menu/${menuId}`
    )
    if (noResponse(response) || !response.data._id || !response.data.dishes) {
      throw new MeaError('cannotGetOrderState', t('errors.cannotGetOrderState'))
    }
    return response.data
  }

  static async setDishQty(orderId: string, dishId: string, quantity: number): Promise<GetUserOrderResponse> {
    const response = await getBackendClient().post(`${this.getPath()}/${orderId}/setDishQty`, { dishId, quantity })
    if (noResponse(response)) {
      throw new MeaError('errorSettingDishQty', t('errors.errorSettingDishQty'))
    }
    return response.data
  }

  static async submit(orderId: string): Promise<GetUserOrderResponse> {
    const response = await getBackendClient().post(`${this.getPath()}/${orderId}/submit`)
    if (noResponse(response)) {
      throw new MeaError('errorSubmitOrder', t('errors.errorSubmitOrder'))
    }
    return response.data
  }

  static async empty(orderId: string): Promise<GetUserOrderResponse> {
    const response = await getBackendClient().post(`${this.getPath()}/${orderId}/empty`)
    if (noResponse(response)) {
      throw new MeaError('errorEmptyingOrder', t('errors.errorEmptyingOrder'))
    }
    return response.data
  }
}
