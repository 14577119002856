/**
 * Use this type to require at least one of the optional properties example:
 * ```
 * type T = {
 *  a?: string
 *  b?: string
 * }
 * type T1 = RequireAtLeastOne<T>
 *
 * const t: T1 = {} // error
 * const t1: T1 = { a: 'a' } // ok
 * const t2: T1 = { b: 'b' } // ok
 * const t3: T1 = { a: 'a', b: 'b' } // ok
 * ```
 */
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export function unique(value: any, index: number, array: any[]) {
  return array.indexOf(value) === index
}
