import { config } from '../../config'
import { useAppConfigStore, useRestaurantStore, useUserLoginStore } from '../../store'
import { DishOrder, UserLogin } from '../../types'
import { RequireAtLeastOne } from '../../types/utils'
import { BaseService } from '../BaseService'
import { getBackendClient } from '../HTTPClient'

export enum ActionType {
  Click = 'click',
  Language = 'language',
  TimeInDetail = 'time_in_detail',
  Order = 'order',
  Intolerance = 'intolerance',
  OldAkinator = 'akinator',
  MigratedFromLegacyStore = 'migrated_from_legacy_store',
}

interface ClickActionPayload {
  buttonId: string
}

interface LanguageActionPayload {
  value: string
}

interface TimeInDetailActionPayload {
  ms: number
}

export enum OrderActionPayloadAction {
  Empty = 'empty',
  Finish = 'finish',
}

/**
 * One of the two fields must be set
 */
interface OrderActionPayload {
  order?: DishOrder[]
  action?: OrderActionPayloadAction
}

export enum IntoleranceActionPayloadAction {
  Cancel = 'cancel',
  NoIntolerances = 'no',
  Confirm = 'confirm',
}

interface IntoleranceActionPayload {
  value?: number
  action: IntoleranceActionPayloadAction
}

export enum OldAkinatorActionPayloadAction {
  Start = 'start',
  Cancel = 'cancel',
  SelectIngredient = 'select_ingredient',
  SelectNothing = 'select_nothing',
  ShowResults = 'show_results',
  AddToCart = 'add_to_cart',
}

interface OldAkinatorActionPayload {
  action: OldAkinatorActionPayloadAction
  ingredientId?: string
  recipeId?: string
  resultsCount?: number
}

interface ActionPayload {
  [ActionType.Click]: ClickActionPayload
  [ActionType.Language]: LanguageActionPayload
  [ActionType.TimeInDetail]: TimeInDetailActionPayload
  [ActionType.Order]: RequireAtLeastOne<OrderActionPayload>
  [ActionType.Intolerance]: IntoleranceActionPayload
  [ActionType.OldAkinator]: OldAkinatorActionPayload
  [ActionType.MigratedFromLegacyStore]: Partial<UserLogin>
}
export class PublicService extends BaseService() {
  static entityPath: string = 'public'

  static async logAction<T extends keyof ActionPayload>(type: T, payload: ActionPayload[T]): Promise<any> {
    const { appMode } = useAppConfigStore.getState()
    if (appMode !== 'USER') return
    const { userId } = useUserLoginStore.getState()
    const { _id: restaurantId } = useRestaurantStore.getState()
    if (config.environment === 'local') return console.log({ userId, type, payload, restaurantId })

    const response = await getBackendClient().post(`${this.entityPath}/userAppAction`, {
      userId,
      type,
      payload,
      restaurantId,
    })
    return response.data
  }
}
