import { t } from 'i18next'
import { AkinatorDish, Dish, GetMenuIngredientsResponse, MeaError, RecipeSuggestion } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export type GetMenuRecipesResponse = {
  id: string
  available: boolean
  likes: number
}

export class MenuService extends BaseUserService() {
  static entityPath: string = 'menu'

  static async getMenuDishes(menuId: string): Promise<Dish[]> {
    const response = await getBackendClient().get<Dish[]>(`${this.getPath()}/dishes/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetMenuDishes', t('errors.cannotGetMenuDishes'))
    }
    return response.data
  }

  static async getMenuIngredients(menuId: string): Promise<GetMenuIngredientsResponse> {
    const response = await getBackendClient().get<GetMenuIngredientsResponse>(`${this.getPath()}/ingredients/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetMenuIngredients', t('errors.cannotGetMenuIngredients'))
    }
    return response.data
  }

  static async getMenuRecipes(menuId: string): Promise<GetMenuRecipesResponse[]> {
    const response = await getBackendClient().get<GetMenuRecipesResponse[]>(`${this.getPath()}/recipes/${menuId}`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetMenuRecipes', t('errors.cannotGetMenuRecipes'))
    }
    return response.data
  }

  static async getForYouRecipes(menuId: string, userIntolerance: number): Promise<RecipeSuggestion[]> {
    const response = await getBackendClient().post<RecipeSuggestion[]>(`${this.getPath()}/gusto/${menuId}`, {
      userIntolerance,
    })
    if (noResponse(response)) {
      throw new MeaError('cannotGetForYouRecipes', t('errors.cannotGetForYouRecipes'))
    }
    return response.data
  }

  static async getFeaturedDishes(menuId: string, userIntolerance: number): Promise<string[]> {
    const response = await getBackendClient().post<string[]>(`${this.getPath()}/featured/${menuId}`, {
      userIntolerance,
    })
    if (noResponse(response)) {
      throw new MeaError('cannotGetFeaturedDishes', t('errors.cannotGetFeaturedDishes'))
    }
    return response.data
  }

  static async getAkinatorDishes(menuId: string): Promise<AkinatorDish[]> {
    const response = await getBackendClient().get<AkinatorDish[]>(`${this.getPath()}/${menuId}/dishesWithIngredients`)
    if (noResponse(response)) {
      throw new MeaError('cannotGetAkinatorDishes', t('errors.cannotGetAkinatorDishes'))
    }
    return response.data
  }
}
