import { t } from 'i18next'
import { MeaError } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export class FeedbackService extends BaseUserService() {
  static entityPath: string = 'feedback'

  static async send(restaurantId: string, stars: number, messages: string[]): Promise<any> {
    const response = await getBackendClient().post(this.getPath(), { restaurantId, stars, messages })
    if (noResponse(response)) {
      throw new MeaError('errorSendingFeedback', t('errors.errorSendingFeedback'))
    }
    return response.data
  }
}
