import { t } from 'i18next'
import { MeaError, Menu, Restaurant } from '../../types'
import { BaseUserService } from '../BaseUserService'
import { getBackendClient, noResponse } from '../HTTPClient'

export interface RestaurantCheckinResponse {
  restaurant: Restaurant
  menus: Menu[]
}
export class RestaurantService extends BaseUserService() {
  static entityPath: string = 'restaurant'

  static async checkIn(restaurantId: string): Promise<RestaurantCheckinResponse> {
    const response = await getBackendClient().get<RestaurantCheckinResponse>(
      `${this.getPath()}/checkin/${restaurantId}`
    )
    if (noResponse(response) || !response.data.restaurant || !response.data.menus) {
      throw new MeaError('missingRestaurantInfo', t('errors.missingRestaurantInfo'))
    }
    return response.data
  }

  static async getTrendingRecipes(restaurantId: string, userIntolerance: number): Promise<string[]> {
    const response = await getBackendClient().post<string[]>(`${this.getPath()}/trend/${restaurantId}`, {
      userIntolerance,
    })
    if (noResponse(response)) {
      throw new MeaError('cannotGetTrendingRecipes', t('errors.cannotGetTrendingRecipes'))
    }
    return response.data
  }
}
