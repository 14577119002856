import { Button, ButtonVariant, useTw } from '@mea-menu/components'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { FlatList, Image, Text, View } from 'react-native'
import { MenuDishItem } from '..'
import { BaseModal } from '../'
import { useMenuStore, useNotificationsStore } from '../../store'
import { MeaDishVariantsNotification, MeaNotificationType } from '../../types'

export const NotificationManager = () => {
  const { notification, consumeNotification } = useNotificationsStore()
  const { tw } = useTw()

  const { dishes } = useMenuStore()
  const { t } = useTranslation()

  const onClose = useCallback(() => {
    if (!notification) return
    consumeNotification()
    notification.onCancel && notification.onCancel()
  }, [notification])

  const onConfirm = useCallback(() => {
    if (!notification) return
    consumeNotification()
    notification.onConfirm && notification.onConfirm()
  }, [notification])

  if (!notification) return null
  const alertMode = notification.type === MeaNotificationType.ALERT

  return (
    <BaseModal visible={true} onClose={onClose} title={notification.title}>
      {notification.imageSource && (
        <Image source={notification.imageSource} resizeMode={'contain'} style={tw`w-full h-[200px] mt-md `} />
      )}

      {notification.description && <Text style={tw`mt-xl textMono`}>{notification.description}</Text>}

      {![MeaNotificationType.NO_BUTTONS, MeaNotificationType.DISH_VARIANTS].includes(notification.type) && (
        <View style={tw`flex-row`}>
          <View style={{ flex: 1 }}>
            <Button
              label={alertMode ? notification.confirmText ?? t('l.ok') : notification.cancelText ?? t('l.cancel')}
              style={tw`mt-md mr-xs`}
              variant={alertMode ? ButtonVariant.Primary : ButtonVariant.Default}
              onPress={alertMode ? onConfirm : onClose}
            />
          </View>

          {notification.type === MeaNotificationType.CONFIRM && (
            <View style={{ flex: 1 }}>
              <Button
                label={notification.confirmText ?? t('l.confirm')}
                style={tw`mt-md ml-xs`}
                variant={ButtonVariant.Primary}
                onPress={onConfirm}
              />
            </View>
          )}
        </View>
      )}
      {notification.type === MeaNotificationType.DISH_VARIANTS && (
        <FlatList
          data={(notification as MeaDishVariantsNotification).variantsDishIds}
          renderItem={({ item: dishId }) => {
            const dish = dishes?.find(dish => dish.dishId === dishId)
            if (!dish) return null
            return <MenuDishItem dish={dish} insideVariantsModal />
          }}
        />
      )}
    </BaseModal>
  )
}
