import { t } from 'i18next'
import { MeaError } from '../../types'
import { BaseService } from '../BaseService'
import { getBackendClient, noResponse } from '../HTTPClient'

interface GetRedirectLinkResponse {
  url: string
}

export class LinkService extends BaseService() {
  static entityPath: string = 'link'

  static async getRedirectLink(redirectId: string): Promise<GetRedirectLinkResponse> {
    const response = await getBackendClient().get<GetRedirectLinkResponse>(`${this.entityPath}/redirect/${redirectId}`)
    if (noResponse(response) || !response.data.url) {
      throw new MeaError('missingRedirectLink', t('errors.missingRedirectLink'))
    }
    return response.data
  }
}
