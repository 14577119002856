import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { AppFeature, AppMode } from '../types'
import { appFeatureIsAvailable } from '../utils'

interface AppConfigState {
  appMode: AppMode
  appFeatures: number
  restaurantId?: string
}

interface AppConfigStore extends AppConfigState {
  setAppMode: (appMode: AppMode) => void
  setRestaurantId: (restaurantId: string) => void
  addAppFeature: (appFeature: AppFeature) => void
  addAppFeatures: (appFeatures: AppFeature[]) => void
  setAppFeatures: (appFeatures: number) => void
  removeAppFeature: (appFeature: AppFeature) => void
}

const initialState: AppConfigState = {
  appMode: 'PRICE_LIST',
  appFeatures: 0,
}

export const useAppConfigStore = create<AppConfigStore>()(
  persist(
    immer<AppConfigStore>((set, _) => ({
      ...initialState,
      setAppMode: appMode =>
        set(store => {
          store.appMode = appMode
        }),
      setRestaurantId: restaurantId =>
        set(store => {
          store.restaurantId = restaurantId
        }),
      addAppFeature: appFeature =>
        set(store => {
          if (appFeatureIsAvailable(appFeature, store.appFeatures)) return
          store.appFeatures += appFeature
        }),
      addAppFeatures: appFeatures =>
        set(store => {
          let featuresValue = 0
          appFeatures.forEach(feature => {
            if (appFeatureIsAvailable(feature, store.appFeatures)) return
            featuresValue += feature
          })
          store.appFeatures += featuresValue
        }),
      setAppFeatures: appFeatures =>
        set(store => {
          store.appFeatures = appFeatures
        }),
      removeAppFeature: appFeature =>
        set(store => {
          if (!appFeatureIsAvailable(appFeature, store.appFeatures)) return
          store.appFeatures -= appFeature
        }),
    })),
    {
      name: 'meamenu.appConfig',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
