import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'
import { immer } from 'zustand/middleware/immer'
import { AuthResponse } from '../api/user/UserAuthService'
import { UserLogin } from '../types'
import { parseJwtToken } from '../utils/jwt'

export interface UserLoginStore extends UserLogin {
  setUuid: (uuid: string) => void
  setUserLoginData: (userAuth: AuthResponse) => void
  initUserLoginData: () => void
}

const initialState: UserLogin = { userId: 'unset' }

export const useUserLoginStore = create<UserLoginStore>()(
  persist(
    immer<UserLoginStore>((set, _) => ({
      ...initialState,
      setUuid: (uuid: string) =>
        set(store => {
          store.userUuid = uuid
        }),
      setUserLoginData: userAuth =>
        set(store => {
          store.userId = userAuth.userId
          store.userUuid = parseJwtToken(userAuth.accessToken).uuid
          store.accessToken = userAuth.accessToken
        }),
      initUserLoginData: () =>
        set(
          store => ({
            ...initialState,
            setUuid: store.setUuid,
            setUserLoginData: store.setUserLoginData,
            initUserLoginData: store.initUserLoginData,
          }),
          true
        ),
    })),
    {
      name: 'meamenu.userLogin',
      storage: createJSONStorage(() => localStorage),
    }
  )
)
